import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDisableControl]',
})
export class DisableControlDirective {
  @Input('appDisableControl') public set disableControl(condition: boolean) {
    setTimeout(() => {
      if (this.ngControl.control) {
        condition ? this.ngControl.control.disable() : this.ngControl.control.enable();
      }
    });
  }

  constructor(private ngControl: NgControl) {}
}
