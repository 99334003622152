import { Directive, TemplateRef } from '@angular/core';
import { ValidationError } from '@ui/error/validation-error/validation-error.types';

export interface SelectErrorDefContext {
  $implicit: ValidationError;
}

@Directive({
  selector: '[appSelectError]',
})
export class SelectErrorDefDirective {
  constructor(public template: TemplateRef<SelectErrorDefContext>) {}

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  static ngTemplateContextGuard(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dir: SelectErrorDefDirective,
    ctx: unknown,
  ): ctx is SelectErrorDefContext {
    return true;
  }
}
