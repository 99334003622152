/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PrivateTransactionLinkDTO {
  accrued?: number;
  bankAccountId: string;
  commission?: number;
  price?: number;
  qty?: number;
  subType?: PrivateTransactionLinkDTO.SubTypeEnum;
  transactionId: string;
  type: PrivateTransactionLinkDTO.TypeEnum;
}
export namespace PrivateTransactionLinkDTO {
  export type SubTypeEnum =
    | 'ADMINISTRATION'
    | 'ADR_FEE'
    | 'AMENDMENT_FEE'
    | 'ASSIMILATION'
    | 'AUCTION_TENDER_OFFER'
    | 'BLOCKING'
    | 'BLOCKING_OF_CERTIFICATE'
    | 'BOND_TAX'
    | 'BUY'
    | 'BUY_ISSUE_IPO'
    | 'CALLED'
    | 'CAPITAL_CALL'
    | 'CAPITAL_INCREASE'
    | 'CASH_TRANSFER_FEE'
    | 'COMMISSION'
    | 'COMPENSATION'
    | 'CONSENT'
    | 'CORP_ACTION'
    | 'COUPON'
    | 'CREDIT'
    | 'CUSTODY'
    | 'DEBIT'
    | 'DEPOSIT'
    | 'DIVIDEND'
    | 'DIVIDEND_TAX'
    | 'DVP'
    | 'EXCHANGE_FEE'
    | 'EXCHANGE_OF_SECURITES'
    | 'EXECUTION'
    | 'EXPIRY_OF_SECURITY'
    | 'EXPOSURE_FEE'
    | 'FRACTIONAL_SHARE'
    | 'FROM_SPIN_OFF'
    | 'FULL'
    | 'HANDLE_FEE'
    | 'IN'
    | 'INCENTIVE'
    | 'INFO'
    | 'INTEREST_CALCULATION'
    | 'INTERNAL'
    | 'INVOICE'
    | 'ISIN_CHANGE'
    | 'LIQUIDATION'
    | 'LOAN'
    | 'MANAGEMENT'
    | 'MERGE'
    | 'OPERATION'
    | 'OPTIONAL_DIVIDEND'
    | 'ORDER'
    | 'OTHER'
    | 'OTHER_TAX'
    | 'OUT'
    | 'OVERNIGHT_POSITION_FEE'
    | 'PARTIAL'
    | 'PAYMENT'
    | 'PRODUCT_SUBSCRIPTION'
    | 'PRODUCT_UNSUBSCRIBE'
    | 'PUBLIC_OFFER'
    | 'PURCHASE_OFFER'
    | 'REIMBURSEMENT'
    | 'REPO'
    | 'RETRO'
    | 'RETROCESSION'
    | 'REVENUE'
    | 'REVERSE_SPLIT'
    | 'SALES_TAX'
    | 'SECURITIES_LANDING'
    | 'SECURITY_EXCHANGE'
    | 'SELL'
    | 'SERVICE_CHARGE_CALCULATION'
    | 'SETTLEMENT_FEE'
    | 'SPIN_OFF'
    | 'SPLIT'
    | 'SPLIT_ISIN_CHANGE'
    | 'STOCK_TRANSFER_FEE'
    | 'SUBSCRIPTION'
    | 'SUCCESS'
    | 'TAX_CORRECTION'
    | 'TECHNICAL_CORRECTION'
    | 'TRADING'
    | 'UNBLOCKING'
    | 'UNBLOCKING_BLOCKING'
    | 'VARIATION_MARGIN'
    | 'WITHDRAWAL_FEE'
    | 'WITHHOLDING_TAX';
  export const SubTypeEnum = {
    Administration: 'ADMINISTRATION' as SubTypeEnum,
    AdrFee: 'ADR_FEE' as SubTypeEnum,
    AmendmentFee: 'AMENDMENT_FEE' as SubTypeEnum,
    Assimilation: 'ASSIMILATION' as SubTypeEnum,
    AuctionTenderOffer: 'AUCTION_TENDER_OFFER' as SubTypeEnum,
    Blocking: 'BLOCKING' as SubTypeEnum,
    BlockingOfCertificate: 'BLOCKING_OF_CERTIFICATE' as SubTypeEnum,
    BondTax: 'BOND_TAX' as SubTypeEnum,
    Buy: 'BUY' as SubTypeEnum,
    BuyIssueIpo: 'BUY_ISSUE_IPO' as SubTypeEnum,
    Called: 'CALLED' as SubTypeEnum,
    CapitalCall: 'CAPITAL_CALL' as SubTypeEnum,
    CapitalIncrease: 'CAPITAL_INCREASE' as SubTypeEnum,
    CashTransferFee: 'CASH_TRANSFER_FEE' as SubTypeEnum,
    Commission: 'COMMISSION' as SubTypeEnum,
    Compensation: 'COMPENSATION' as SubTypeEnum,
    Consent: 'CONSENT' as SubTypeEnum,
    CorpAction: 'CORP_ACTION' as SubTypeEnum,
    Coupon: 'COUPON' as SubTypeEnum,
    Credit: 'CREDIT' as SubTypeEnum,
    Custody: 'CUSTODY' as SubTypeEnum,
    Debit: 'DEBIT' as SubTypeEnum,
    Deposit: 'DEPOSIT' as SubTypeEnum,
    Dividend: 'DIVIDEND' as SubTypeEnum,
    DividendTax: 'DIVIDEND_TAX' as SubTypeEnum,
    Dvp: 'DVP' as SubTypeEnum,
    ExchangeFee: 'EXCHANGE_FEE' as SubTypeEnum,
    ExchangeOfSecurites: 'EXCHANGE_OF_SECURITES' as SubTypeEnum,
    Execution: 'EXECUTION' as SubTypeEnum,
    ExpiryOfSecurity: 'EXPIRY_OF_SECURITY' as SubTypeEnum,
    ExposureFee: 'EXPOSURE_FEE' as SubTypeEnum,
    FractionalShare: 'FRACTIONAL_SHARE' as SubTypeEnum,
    FromSpinOff: 'FROM_SPIN_OFF' as SubTypeEnum,
    Full: 'FULL' as SubTypeEnum,
    HandleFee: 'HANDLE_FEE' as SubTypeEnum,
    In: 'IN' as SubTypeEnum,
    Incentive: 'INCENTIVE' as SubTypeEnum,
    Info: 'INFO' as SubTypeEnum,
    InterestCalculation: 'INTEREST_CALCULATION' as SubTypeEnum,
    Internal: 'INTERNAL' as SubTypeEnum,
    Invoice: 'INVOICE' as SubTypeEnum,
    IsinChange: 'ISIN_CHANGE' as SubTypeEnum,
    Liquidation: 'LIQUIDATION' as SubTypeEnum,
    Loan: 'LOAN' as SubTypeEnum,
    Management: 'MANAGEMENT' as SubTypeEnum,
    Merge: 'MERGE' as SubTypeEnum,
    Operation: 'OPERATION' as SubTypeEnum,
    OptionalDividend: 'OPTIONAL_DIVIDEND' as SubTypeEnum,
    Order: 'ORDER' as SubTypeEnum,
    Other: 'OTHER' as SubTypeEnum,
    OtherTax: 'OTHER_TAX' as SubTypeEnum,
    Out: 'OUT' as SubTypeEnum,
    OvernightPositionFee: 'OVERNIGHT_POSITION_FEE' as SubTypeEnum,
    Partial: 'PARTIAL' as SubTypeEnum,
    Payment: 'PAYMENT' as SubTypeEnum,
    ProductSubscription: 'PRODUCT_SUBSCRIPTION' as SubTypeEnum,
    ProductUnsubscribe: 'PRODUCT_UNSUBSCRIBE' as SubTypeEnum,
    PublicOffer: 'PUBLIC_OFFER' as SubTypeEnum,
    PurchaseOffer: 'PURCHASE_OFFER' as SubTypeEnum,
    Reimbursement: 'REIMBURSEMENT' as SubTypeEnum,
    Repo: 'REPO' as SubTypeEnum,
    Retro: 'RETRO' as SubTypeEnum,
    Retrocession: 'RETROCESSION' as SubTypeEnum,
    Revenue: 'REVENUE' as SubTypeEnum,
    ReverseSplit: 'REVERSE_SPLIT' as SubTypeEnum,
    SalesTax: 'SALES_TAX' as SubTypeEnum,
    SecuritiesLanding: 'SECURITIES_LANDING' as SubTypeEnum,
    SecurityExchange: 'SECURITY_EXCHANGE' as SubTypeEnum,
    Sell: 'SELL' as SubTypeEnum,
    ServiceChargeCalculation: 'SERVICE_CHARGE_CALCULATION' as SubTypeEnum,
    SettlementFee: 'SETTLEMENT_FEE' as SubTypeEnum,
    SpinOff: 'SPIN_OFF' as SubTypeEnum,
    Split: 'SPLIT' as SubTypeEnum,
    SplitIsinChange: 'SPLIT_ISIN_CHANGE' as SubTypeEnum,
    StockTransferFee: 'STOCK_TRANSFER_FEE' as SubTypeEnum,
    Subscription: 'SUBSCRIPTION' as SubTypeEnum,
    Success: 'SUCCESS' as SubTypeEnum,
    TaxCorrection: 'TAX_CORRECTION' as SubTypeEnum,
    TechnicalCorrection: 'TECHNICAL_CORRECTION' as SubTypeEnum,
    Trading: 'TRADING' as SubTypeEnum,
    Unblocking: 'UNBLOCKING' as SubTypeEnum,
    UnblockingBlocking: 'UNBLOCKING_BLOCKING' as SubTypeEnum,
    VariationMargin: 'VARIATION_MARGIN' as SubTypeEnum,
    WithdrawalFee: 'WITHDRAWAL_FEE' as SubTypeEnum,
    WithholdingTax: 'WITHHOLDING_TAX' as SubTypeEnum,
  };
  export type TypeEnum =
    | 'ADJUSTMENT'
    | 'BLOCK'
    | 'BOND_REDEMPTION'
    | 'BOND_REDEMPTION_PARTIAL'
    | 'BUY'
    | 'CASH_TRANSFER'
    | 'CORPORATE_ACTION'
    | 'EXERCISE_OPTION'
    | 'EXPENSE'
    | 'EXPIRE_OPTION'
    | 'FEE'
    | 'FX'
    | 'INCOME'
    | 'INFO'
    | 'INTEREST'
    | 'INTERNAL_TRANSFER'
    | 'INVESTMENT'
    | 'MARGIN'
    | 'PAYMENT'
    | 'PRODUCT_SUBSCRIPTION'
    | 'PRODUCT_UNSUBSCRIPTION'
    | 'REPO'
    | 'REVENUE'
    | 'REVERSAL'
    | 'SECURITY_TRANSFER'
    | 'SELL'
    | 'TAX'
    | 'TRANSFER'
    | 'UNKNOWN'
    | 'VIRTUAL_SECURITY_TRANSFER'
    | 'WITHDRAWAL';
  export const TypeEnum = {
    Adjustment: 'ADJUSTMENT' as TypeEnum,
    Block: 'BLOCK' as TypeEnum,
    BondRedemption: 'BOND_REDEMPTION' as TypeEnum,
    BondRedemptionPartial: 'BOND_REDEMPTION_PARTIAL' as TypeEnum,
    Buy: 'BUY' as TypeEnum,
    CashTransfer: 'CASH_TRANSFER' as TypeEnum,
    CorporateAction: 'CORPORATE_ACTION' as TypeEnum,
    ExerciseOption: 'EXERCISE_OPTION' as TypeEnum,
    Expense: 'EXPENSE' as TypeEnum,
    ExpireOption: 'EXPIRE_OPTION' as TypeEnum,
    Fee: 'FEE' as TypeEnum,
    Fx: 'FX' as TypeEnum,
    Income: 'INCOME' as TypeEnum,
    Info: 'INFO' as TypeEnum,
    Interest: 'INTEREST' as TypeEnum,
    InternalTransfer: 'INTERNAL_TRANSFER' as TypeEnum,
    Investment: 'INVESTMENT' as TypeEnum,
    Margin: 'MARGIN' as TypeEnum,
    Payment: 'PAYMENT' as TypeEnum,
    ProductSubscription: 'PRODUCT_SUBSCRIPTION' as TypeEnum,
    ProductUnsubscription: 'PRODUCT_UNSUBSCRIPTION' as TypeEnum,
    Repo: 'REPO' as TypeEnum,
    Revenue: 'REVENUE' as TypeEnum,
    Reversal: 'REVERSAL' as TypeEnum,
    SecurityTransfer: 'SECURITY_TRANSFER' as TypeEnum,
    Sell: 'SELL' as TypeEnum,
    Tax: 'TAX' as TypeEnum,
    Transfer: 'TRANSFER' as TypeEnum,
    Unknown: 'UNKNOWN' as TypeEnum,
    VirtualSecurityTransfer: 'VIRTUAL_SECURITY_TRANSFER' as TypeEnum,
    Withdrawal: 'WITHDRAWAL' as TypeEnum,
  };
}
