import { Coordinate } from '@shared/types/coordinate';
import { Rect } from '@shared/types/rect';

export function coordInsideRect(coord: Coordinate, rect: Rect): boolean {
  if (
    coord.x >= rect.left &&
    coord.x <= rect.right &&
    coord.y >= rect.top &&
    coord.y <= rect.bottom
  ) {
    return true;
  } else {
    return false;
  }
}

export function rectInsideViewPort(testRect: Rect): boolean {
  let result = false;
  const vpWidth = window.innerWidth;
  const vpHeight = window.innerHeight;

  if (
    testRect.left >= 0 &&
    testRect.right <= vpWidth &&
    testRect.top >= 0 &&
    testRect.bottom <= vpHeight
  ) {
    result = true;
  }

  return result;
}

export function rectInsideOtherRect(testRect: Rect, otherRect: Rect): boolean {
  let result = false;

  if (
    testRect.left >= otherRect.left &&
    testRect.right <= otherRect.right &&
    testRect.top >= otherRect.top &&
    testRect.bottom <= otherRect.bottom
  ) {
    result = true;
  }

  return result;
}

export function rangeInsideOtherRange(
  testRange: { min: number; max: number },
  otherRange: { min: number; max: number },
): 'outside' | 'inside' | 'intersect' {
  switch (true) {
    case testRange.min < otherRange.min && testRange.max < otherRange.min:
    case testRange.min > otherRange.max && testRange.max > otherRange.max:
      return 'outside';

    case testRange.min >= otherRange.min && testRange.max <= otherRange.max:
      return 'inside';

    default:
      return 'intersect';
  }
}

export function getElementRect(element: HTMLElement): Rect {
  const rect = element.getBoundingClientRect();
  const resultRect: Rect = {
    left: rect.left,
    right: rect.right,
    top: rect.top,
    bottom: rect.bottom,
    width: rect.width,
    height: rect.height,
  };

  return resultRect;
}

export function correctRectByGap(rect: Rect, addLeftRightGap: number): Rect {
  if (!addLeftRightGap) {
    return rect;
  }

  const resultRect: Rect = {
    left: rect.left,
    right: rect.right,
    top: rect.top,
    bottom: rect.bottom,
    width: rect.width + 2 * addLeftRightGap,
    height: rect.height,
  };

  resultRect.left = rect.left - addLeftRightGap;
  resultRect.right = rect.right + addLeftRightGap;

  return resultRect;
}
