import { AnimationBuilder, AnimationFactory, AnimationPlayer } from '@angular/animations';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject, filter, fromEvent, map, merge, of, switchMap, takeUntil, tap } from 'rxjs';
import { tooltipEnterAnimation } from '@shared/animations/animations';
import { BaseObject } from '@shared/base/base-object';
import {
  coordInsideRect,
  correctRectByGap,
  getElementRect,
} from '@shared/helpers/coordinate.helper';
import { Coordinate } from '@shared/types/coordinate';

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective extends BaseObject implements OnInit {
  @Input('appTooltip') public tooltipTemplate: TemplateRef<unknown>;

  @Input('appTooltipWait') public wait: boolean = true;

  private overlayRef: OverlayRef;
  private animationFactory: AnimationFactory;
  private animationPlayer: AnimationPlayer;
  private closeEvent$ = new Subject<void>();

  constructor(
    private viewContainerRef: ViewContainerRef,
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private animationBuilder: AnimationBuilder,
  ) {
    super();

    this.animationFactory = this.animationBuilder.build(tooltipEnterAnimation);

    this.destroy$.subscribe(() => this.animationPlayer?.destroy());
  }

  public ngOnInit(): void {
    this.overlayRef = this.overlay.create({
      panelClass: 'app-tooltip-panel',
      positionStrategy: this.overlayPositionBuilder
        .flexibleConnectedTo(this.viewContainerRef.element)
        .withPositions([
          {
            originX: 'end',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'center',
          },
          {
            originX: 'start',
            originY: 'center',
            overlayX: 'end',
            overlayY: 'center',
          },
        ]),
    });

    this.listenToOpen();
  }

  private listenToOpen(): void {
    const targetElement = this.viewContainerRef.element.nativeElement;

    if (this.wait) {
      fromEvent(targetElement, 'mouseenter')
        .pipe(
          filter(() => !this.overlayRef.hasAttached()),
          switchMap(() => {
            const tooltipElement = this.open();

            return tooltipElement
              ? merge(
                  fromEvent(tooltipElement, 'mouseleave').pipe(map(() => true)),
                  fromEvent(targetElement, 'mouseleave').pipe(
                    switchMap(() => fromEvent(window, 'mousemove')),
                    takeUntil(this.closeEvent$),
                    map((event: MouseEvent) => {
                      const coord: Coordinate = {
                        x: event.clientX,
                        y: event.clientY,
                      };

                      const tooltipRect = correctRectByGap(getElementRect(tooltipElement), 5);

                      return !coordInsideRect(coord, tooltipRect);
                    }),
                  ),
                )
              : of(false);
          }),
          takeUntil(this.destroy$),
        )
        .subscribe((close) => {
          if (close) {
            this.closeEvent$.next();
            this.overlayRef.detach();
          }
        });
    } else {
      fromEvent(targetElement, 'mouseenter')
        .pipe(
          filter(() => !this.overlayRef.hasAttached()),
          switchMap(() => {
            this.open();

            return fromEvent(targetElement, 'mouseleave');
          }),
        )
        .subscribe(() => {
          this.closeEvent$.next();
          this.overlayRef.detach();
        });
    }
  }

  private open(): HTMLElement {
    if (this.overlayRef.hasAttached()) {
      return null;
    }

    const viewRef = this.overlayRef.attach(
      new TemplatePortal(this.tooltipTemplate, this.viewContainerRef),
    );
    const parentElement = (viewRef.rootNodes[0] as HTMLElement).parentElement;

    this.animationPlayer = this.animationFactory.create(parentElement);
    this.animationPlayer.play();

    return parentElement;
  }
}
