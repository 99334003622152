import { Directive, ElementRef, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { BaseObject } from '@shared/base/base-object';

@Directive({
  selector: '[appSize]',
  exportAs: 'appSize',
})
export class SizeDirective extends BaseObject implements OnInit {
  @Output('appSize.width') public readonly widthChange = new EventEmitter<number>();
  @Output('appSize.height') public readonly heightChange = new EventEmitter<number>();

  private observer: ResizeObserver;

  constructor(
    public el: ElementRef<HTMLElement>,
    private zone: NgZone,
  ) {
    super();

    this.destroy$.subscribe(() => this.observer.unobserve(this.el.nativeElement));
  }

  public ngOnInit(): void {
    this.observer = new ResizeObserver((entries) => {
      this.zone.run(() => {
        this.widthChange.next(entries[0].contentRect.width);
        this.heightChange.next(entries[0].contentRect.height);
      });
    });

    this.observer.observe(this.el.nativeElement);
  }
}
