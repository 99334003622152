/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GlobalAllocationTagDTO } from './global-allocation-tag-dto';

export interface GlobalAllocationTemplateDTO {
  assetType?: GlobalAllocationTemplateDTO.AssetTypeEnum;
  createdAt?: string;
  createdBy?: string;
  currency?: string;
  description?: string;
  id?: string;
  multiAsset?: boolean;
  multiCurrency?: boolean;
  multiRisk?: boolean;
  name?: string;
  riskProfile?: GlobalAllocationTemplateDTO.RiskProfileEnum;
  status?: GlobalAllocationTemplateDTO.StatusEnum;
  tags?: Array<GlobalAllocationTagDTO>;
  updatedAt?: string;
  updatedBy?: string;
}
export namespace GlobalAllocationTemplateDTO {
  export type AssetTypeEnum =
    | 'ALTERNATIVE_ASSETS'
    | 'CASH'
    | 'CERTIFICATE'
    | 'COMMODITIES'
    | 'CRYPTO'
    | 'DEPOSIT'
    | 'DERIVATIVE'
    | 'EQUITIES'
    | 'ETF'
    | 'FIXED_INCOME'
    | 'FUNDS'
    | 'FUTURES'
    | 'FX'
    | 'GRANTED_LOAN'
    | 'HEDGE_FUND'
    | 'INDEX'
    | 'INTANGIBLE_ASSET'
    | 'LOAN'
    | 'MUTUAL_FUND'
    | 'OPTIONS'
    | 'OTHER'
    | 'PRIVATE_EQUITY'
    | 'RECOMMENDATION'
    | 'REPO'
    | 'SP'
    | 'STRATEGY'
    | 'TANGIBLE_ASSET';
  export const AssetTypeEnum = {
    AlternativeAssets: 'ALTERNATIVE_ASSETS' as AssetTypeEnum,
    Cash: 'CASH' as AssetTypeEnum,
    Certificate: 'CERTIFICATE' as AssetTypeEnum,
    Commodities: 'COMMODITIES' as AssetTypeEnum,
    Crypto: 'CRYPTO' as AssetTypeEnum,
    Deposit: 'DEPOSIT' as AssetTypeEnum,
    Derivative: 'DERIVATIVE' as AssetTypeEnum,
    Equities: 'EQUITIES' as AssetTypeEnum,
    Etf: 'ETF' as AssetTypeEnum,
    FixedIncome: 'FIXED_INCOME' as AssetTypeEnum,
    Funds: 'FUNDS' as AssetTypeEnum,
    Futures: 'FUTURES' as AssetTypeEnum,
    Fx: 'FX' as AssetTypeEnum,
    GrantedLoan: 'GRANTED_LOAN' as AssetTypeEnum,
    HedgeFund: 'HEDGE_FUND' as AssetTypeEnum,
    Index: 'INDEX' as AssetTypeEnum,
    IntangibleAsset: 'INTANGIBLE_ASSET' as AssetTypeEnum,
    Loan: 'LOAN' as AssetTypeEnum,
    MutualFund: 'MUTUAL_FUND' as AssetTypeEnum,
    Options: 'OPTIONS' as AssetTypeEnum,
    Other: 'OTHER' as AssetTypeEnum,
    PrivateEquity: 'PRIVATE_EQUITY' as AssetTypeEnum,
    Recommendation: 'RECOMMENDATION' as AssetTypeEnum,
    Repo: 'REPO' as AssetTypeEnum,
    Sp: 'SP' as AssetTypeEnum,
    Strategy: 'STRATEGY' as AssetTypeEnum,
    TangibleAsset: 'TANGIBLE_ASSET' as AssetTypeEnum,
  };
  export type RiskProfileEnum =
    | 'AGGRESSIVE'
    | 'CONSERVATIVE'
    | 'MODERATE'
    | 'MODERATELY_AGGRESSIVE'
    | 'MODERATELY_CONSERVATIVE';
  export const RiskProfileEnum = {
    Aggressive: 'AGGRESSIVE' as RiskProfileEnum,
    Conservative: 'CONSERVATIVE' as RiskProfileEnum,
    Moderate: 'MODERATE' as RiskProfileEnum,
    ModeratelyAggressive: 'MODERATELY_AGGRESSIVE' as RiskProfileEnum,
    ModeratelyConservative: 'MODERATELY_CONSERVATIVE' as RiskProfileEnum,
  };
  export type StatusEnum = 'ACTIVE' | 'CLOSED' | 'DRAFT' | 'ON_REVIEW' | 'PUBLISHED';
  export const StatusEnum = {
    Active: 'ACTIVE' as StatusEnum,
    Closed: 'CLOSED' as StatusEnum,
    Draft: 'DRAFT' as StatusEnum,
    OnReview: 'ON_REVIEW' as StatusEnum,
    Published: 'PUBLISHED' as StatusEnum,
  };
}
