<div class="app-profile-menu__card app-bg-10">
  <div *ngIf="authService.loggedIn"
       class="app-profile-menu__card__body">
    <span class="h4-b">{{userFullName$|async}}</span>
    <span class="b4-r app-text-not-capitalize app-text-color-50"
          style="padding-top: 2px;">
      <span *ngLet="'@' + (userState.userLogin$|async) as _userLogin"
            [appClippedTextTooltip]="_userLogin">
        {{ _userLogin }}
      </span>
    </span>
    <span class="b4-r app-text-color-50"
          style="padding-top: 4px;">
      {{ (userState.role$|async)?.code }}
    </span>

    <div class="app-horizontal-divider --05"
         style="margin-bottom: 8px; width: calc(100% + 24px)">
    </div>

    <span class="b4-r app-text-color-50">
      Site Version: {{ settingsState.version || '-' }}
    </span>
  </div>

  <app-svg [icon]="'Edit_24'"
           class="ml-auto mb-auto app-bg-10 app-border-r4"
           style=" margin-left: 8px;"
           (click)="_onEditProfileClick()">
  </app-svg>
</div>

<a *appIfAuthorities="[Authorities.SettingsView]"
   [routerLink]="['system-settings']"
   class="app-profile-menu__item app-text-color"
   style="text-decoration-line: none"
   (click)="_onSettingsClick()">
  <app-svg [icon]="'Settings_24'"
           [disabledHover]="true"
           class="app-profile-menu__item__icon">
  </app-svg>

  <span class="b4-r">
    {{ 'common.system-settings'|translate }}
  </span>
</a>

<div *ngIf="authService.loggedIn; else showLoginMenuItem"
     class="app-profile-menu__item"
     (click)="_onLogoutClick()">
  <app-svg [icon]="'Logout_24'"
           [disabledHover]="true"
           class="app-profile-menu__item__icon">
  </app-svg>

  <span class="b4-r">
    {{ 'common.logout' | translate }}
  </span>
</div>

<ng-template #showLoginMenuItem>
  <div class="app-profile-menu__item"
       (click)="_onLoginClick()">
    <app-svg [icon]="'Login_24'"
             [disabledHover]="true"
             class="app-profile-menu__item__icon">
    </app-svg>

    <span class="b4-r">
      {{ 'common.login' | translate }}
    </span>
  </div>
</ng-template>