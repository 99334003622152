import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@shared/pipes/pipes.module';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { SvgModule } from '@ui/svg/svg.module';
import { TooltipModule } from '@ui/tooltip/tooltip.module';
import { ActionNotificationComponent } from './action-notification/action-notification.component';
import { HighlightNotificationComponent } from './highlight-notification/highlight-notification.component';
import { NoDataNotificationComponent } from './no-data-notification/no-data-notification.component';
import { SmallNotificationComponent } from './small-notification/small-notification.component';
import { StatusNotificationComponent } from './status-notification/status-notification.component';
import { SubscriptionNotificationComponent } from './subscription-notification/subscription-notification.component';
import { SubscriptionSmallNotificationComponent } from './subscription-small-notification/subscription-small-notification.component';

@NgModule({
  imports: [CommonModule, SvgModule, TranslateModule, TooltipModule, ButtonsModule, PipesModule],
  declarations: [
    SmallNotificationComponent,
    ActionNotificationComponent,
    SubscriptionNotificationComponent,
    SubscriptionSmallNotificationComponent,
    HighlightNotificationComponent,
    NoDataNotificationComponent,
    StatusNotificationComponent,
  ],
  exports: [
    SmallNotificationComponent,
    ActionNotificationComponent,
    SubscriptionNotificationComponent,
    SubscriptionSmallNotificationComponent,
    HighlightNotificationComponent,
    NoDataNotificationComponent,
    StatusNotificationComponent,
  ],
})
export class NotificationModule {}
