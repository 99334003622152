import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { filter } from 'rxjs';
import { UserState } from '@shared/states/user.state';

export interface SeoRouteData {
  pageTitle: string;
  description: string;
}

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private title: Title,
    private meta: Meta,
    private userState: UserState,
  ) {
    this.userState.companyName$.pipe(filter((v) => !!v)).subscribe((companyName) => {
      this.setMetaTags([
        {
          property: 'og:site_name',
          content: companyName,
        },
        {
          property: 'og:type',
          content: 'website',
        },
      ]);
    });
  }

  public setPageTitle(pageTitle: string): void {
    this.title.setTitle(`${pageTitle}`);
  }

  public setMetaTags(tags: MetaDefinition[]): void {
    tags.forEach((tag) => this.meta.updateTag(tag));
  }
}
