import { Pipe, PipeTransform } from '@angular/core';
import { ValidationError } from './validation-error.types';

@Pipe({
  name: 'toValidationError',
})
export class ToValidationErrorPipe implements PipeTransform {
  public transform(error: ValidationError | string): ValidationError {
    return error as ValidationError;
  }
}
