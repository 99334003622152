<ng-select *ngLet="{
              _options: options$|async,
              _prefixWidth: prefixWidth$|async,
              _suffixWidth: suffixWidth$|async
            } as obj"
           class="app-select__selector --with-suffix"
           [class.app-select__selector--has-selected]="hasSelected$|async"
           [class.--with-prefix]="!!selectPrefixDef"
           [class.--hide-arrow]="hideArrow"
           [style.--prefix-width]="obj._prefixWidth ? (obj._prefixWidth + 8 + 'px') : null"
           [style.--suffix-width]="obj._suffixWidth ? (obj._suffixWidth + 8 + 'px') : null"
           [attr.required-asterisk]="!hideRequiredMarker && !!formControl?.errors"
           [attr.no-label]="!label || small"
           [attr.small]="small"
           [items]="obj._options"
           [appendTo]="selectAppendTo"
           [dropdownPosition]="'bottom'"
           [bindLabel]="bindName"
           [bindValue]="bindValue"
           [groupBy]="bindGroupBy"
           [formControl]="formControl"
           [placeholder]="label || (placeholder && (formControl | getChanges | async | isEmpty) ? placeholder : null)"
           [addTag]="addTag"
           [multiple]="multiple"
           [closeOnSelect]="!multiple && closeOnSelect"
           [clearOnBackspace]="false"
           [clearable]="clearable"
           [openOnEnter]="false"
           [hideSelected]="false"
           [searchable]="false"
           [isOpen]="false"
           [virtualScroll]="virtualScroll"
           [typeahead]="lazySearch ? lazySearchEvent$ : null"
           [loading]="loading$|async"
           [compareWith]="optionsComparator"
           [searchFn]="searchFunction"
           (scroll)="_onScroll($event)"
           (scrollToEnd)="_onScrollToEnd()"
           (change)="_onChanged($event)"
           (clear)="_onClearClick()"
           (add)="_onAddOption($event)"
           (remove)="_onRemoveOption($event)">
  <ng-template ng-option-tmp
               let-option="item$"
               let-searchTerm="searchTerm">
    <ng-container *ngTemplateOutlet="selectOptionDef?.template || selectOptionDefaultTemplate;
                                      context: { $implicit: option, searchTerm: searchTerm };
                                      injector: option | getSelectOptionInjector">
    </ng-container>
  </ng-template>

  <ng-template ng-optgroup-tmp
               let-item="item">
    <ng-container *ngTemplateOutlet="selectGroupDef?.template || selectGroupDefaultTemplate;
                                      context: { $implicit: item?.label }">
    </ng-container>
  </ng-template>

  <ng-template ng-label-tmp
               let-item="item"
               let-clear="clear">
    <ng-container *ngTemplateOutlet="selectValueDef?.template || selectValueDefaultTemplate;
                                      context: { $implicit: item, removeOptionFunction: clear }">
    </ng-container>
  </ng-template>

  <ng-template ng-multi-label-tmp
               let-items="items"
               let-clear="clear">
    <ng-container *ngTemplateOutlet="selectMultiValueDef?.template || selectMultiValueDefaultTemplate;
                                      context: { $implicit: items, removeOptionFunction: clear }">
    </ng-container>
  </ng-template>

  <ng-template ng-header-tmp>
    <mat-form-field *ngIf="showSearch"
                    appearance="outline"
                    class="app-select__selector__search"
                    floatLabel="always"
                    [style]="searchFieldStyles">
      <ng-container [ngSwitch]="true">
        <ng-container *ngSwitchCase="searchFieldType === 'number'">
          <input [formControl]="searchTextControl"
                 matInput
                 type="text"
                 [mask]="numberInputMask"
                 [prefix]="searchFieldMaskPrefix"
                 [thousandSeparator]="thousandSeparator"
                 [placeholder]="mode === 'search' ? ('common.search'|translate) : ('common.enter'|translate)">
        </ng-container>

        <ng-container *ngSwitchDefault>
          <input [formControl]="searchTextControl"
                 matInput
                 type="text"
                 [placeholder]="mode === 'search' ? ('common.search'|translate) : ('common.enter'|translate)">
        </ng-container>
      </ng-container>

      <app-svg *ngIf="mode === 'search'"
               matPrefix
               [icon]="'Search_24'">
      </app-svg>
    </mat-form-field>

    <ng-container *ngIf="selectHeaderDef?.template">
      <ng-container *ngTemplateOutlet="selectHeaderDef.template">
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template ng-notfound-tmp>
    <div @contentAnimation>
      <app-svg type="skeleton"
               [src]="skeleton || '/assets/img/skeletons/empty-select.svg'">
      </app-svg>

      <app-no-data-notification [isEmptyState]="!errorState"
                                [isErrorState]="errorState"
                                [small]="true"
                                [message]="customMessage || (searchTextControl.value ? ('msg.info.noDataForSearchRequest'|translate) : ('msg.info.noDataForSelect'|translate))"
                                [title]="customTitle"
                                style="padding: 10px">
      </app-no-data-notification>
    </div>
  </ng-template>

  <ng-template ng-tag-tmp
               let-search="searchTerm">
    <div style="display: flex; width: 100%; align-items: center">
      <span>{{ search }}</span>
      <app-svg [icon]="'Add_16'"
               class="ml-auto">
      </app-svg>
    </div>
  </ng-template>

  <ng-template ng-typetosearch-tmp>
    <div @contentAnimation>
      <app-svg type="skeleton"
               [src]="skeleton || '/assets/img/skeletons/empty-select.svg'">
      </app-svg>

      <app-no-data-notification [small]="true"
                                [message]="'common.startTypeToSearch'|translate">
      </app-no-data-notification>
    </div>
  </ng-template>

  <ng-template ng-loadingtext-tmp>
    <div class="app-widget-spinner"></div>

    <app-svg type="skeleton"
             [src]="skeleton || '/assets/img/skeletons/empty-select.svg'"
             @contentAnimation>
    </app-svg>
  </ng-template>

  <ng-template ng-loadingspinner-tmp>
    <app-spinner *ngIf="!(isSelectOpened$|async)"
                 style="width: 30px; position: absolute; right: 28px;"
                 size="16px">
    </app-spinner>
  </ng-template>
</ng-select>

<div *ngIf="selectPrefixDef"
     class="app-select__prefix"
     appSize
     (appSize.width)="prefixWidth$.next($event)"
     (ngDestroy)="prefixWidth$.next(null)">
  <ng-container *ngTemplateOutlet="selectPrefixDef.template"></ng-container>
</div>

<div class="app-select__suffix"
     appSize
     (appSize.width)="suffixWidth$.next($event)">
  <app-svg *ngIf="formControl | hasError:null:'touched-or-dirty' | async"
           [icon]="'StatusAttentionOutline_16'"
           [disabledHover]="true"
           [subType]="'danger'"
           class="app-hover-cursor-pointer"
           [appTooltip]="errorsTooltipTemplate">
  </app-svg>

  <ng-container *ngTemplateOutlet="selectSuffixDef?.template"></ng-container>
</div>

<ng-template #errorsTooltipTemplate>
  <app-validation-errors-tooltip [errors]="formControl.errors">
    <ng-container *ngIf="selectErrorDef">
      <ng-container *appValidationErrorDef="let error;">
        <ng-container *ngTemplateOutlet="selectErrorDef?.template; context: { $implicit: error }"></ng-container>
      </ng-container>
    </ng-container>
  </app-validation-errors-tooltip>
</ng-template>

<ng-template #selectOptionDefaultTemplate
             [ngTemplateContextType]="selectOptionTemplateContext"
             let-option="$implicit"
             let-searchTerm="searchTerm">
  <app-select-option>
    <span [appClippedTextTooltip]="option.label">
      <span [ngOptionHighlight]="searchTerm">
        {{ option.label }}
      </span>
    </span>
  </app-select-option>
</ng-template>

<ng-template #selectGroupDefaultTemplate
             [ngTemplateContextType]="selectGroupTemplateContext"
             let-item="$implicit">
  <span class="sibtitle-3 app-text-white-space-nowrap app-text-color-50"
        [appClippedTextTooltip]="item | toString">
    {{ item }}
  </span>
</ng-template>

<ng-template #selectValueDefaultTemplate
             let-item
             let-removeOptionFunction="removeOptionFunction">
  <app-select-value *ngLet="{ _value: bindName ? (item | getValue:bindName) : item } as obj">
    <span [class.app-select__field-value]="multiple"
          [appClippedTextTooltip]="obj._value">
      {{ obj._value }}
    </span>

    <app-svg *ngIf="multiple && !disabled"
             [icon]="'Close_24'"
             class="app-select__close-icon"
             (click)="_onRemoveOptionByCloseClick(removeOptionFunction, item, $event)">
    </app-svg>
  </app-select-value>
</ng-template>

<ng-template #selectMultiValueDefaultTemplate
             let-items
             let-removeOptionFunction="removeOptionFunction">

  <ng-container *ngIf="items.length === 1; else showMultipleValues">
    <ng-container
                  *ngTemplateOutlet="selectValueDefaultTemplate; context: { $implicit: items[0], removeOptionFunction: removeOptionFunction }">
    </ng-container>
  </ng-container>

  <ng-template #showMultipleValues>
    <ng-container *ngIf="multipleValueShort; else showAllMultipleValues">
      <app-select-value *ngLet="{
                          _firstValue: bindName ? (items[0] | getValue:bindName) : items[0],
                          _valuesCountStr: ' (' + items.length +')' } as obj"
                        @listItemAnimation>
        <span [appClippedTextTooltip]="obj._firstValue + obj._valuesCountStr"
              class="app-select__field-value"
              [class.app-text-color-50]="disabled"
              style="width: calc(100% - 24px)">
          {{ obj._firstValue + obj._valuesCountStr }}
        </span>

        <app-menu *ngIf="!withoutMenu"
                  [items]="items"
                  [menuItemTemplate]="menuItemTemplate"
                  [attr.disabled]="(isSelectOpened$|async)">
          <app-svg [icon]="'MenuHorizontal_24'"></app-svg>

          <ng-template #menuItemTemplate
                       [ngTemplateContextType]="menuItemTemplateContext"
                       let-item="item">
            <span *ngLet="(bindName ? (item | getValue:bindName) : item) | toString as _valueString"
                  class="app-text-white-space-nowrap"
                  style="width: 100%"
                  [appClippedTextTooltip]="_valueString">
              {{ _valueString }}
            </span>
          </ng-template>
        </app-menu>
      </app-select-value>
    </ng-container>
  </ng-template>

  <ng-template #showAllMultipleValues>
    <ng-container *ngFor="let item of items">
      <ng-container
                    *ngTemplateOutlet="selectValueDefaultTemplate; context: { $implicit: item, removeOptionFunction: removeOptionFunction }">
      </ng-container>
    </ng-container>
  </ng-template>
</ng-template>