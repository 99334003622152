import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { ImgSrcCach } from '@shared/helpers/img-src-cash.service';

@Pipe({
  name: 'getInstrumentImgSrc',
})
export class GetInstrumentImgSrcPipe implements PipeTransform {
  constructor(private imgSrcCach: ImgSrcCach) {}

  public transform(bloombergId: string, micCode: string): Observable<string> {
    if (!bloombergId) {
      return null;
    }

    const symbol = bloombergId.trim().split(' ').shift();

    if (!symbol) {
      return null;
    }

    return this.imgSrcCach.getInstrumentLogo(symbol, micCode);
  }
}
