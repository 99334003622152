import { Pipe, PipeTransform } from '@angular/core';
import { Observable, concat, map, of } from 'rxjs';
import { FieldDictionaryService } from '@shared/api/field-dictionary.service';
import { DefaultKeyValue } from '@shared/dto/gateway-secured/models';

export interface GetSelectorFieldValuesResult {
  loading?: boolean;
  value: DefaultKeyValue[];
}

@Pipe({
  name: 'getSelectorFieldValues',
})
export class GetSelectorFieldValuesPipe implements PipeTransform {
  constructor(private fieldDictionaryService: FieldDictionaryService) {}

  public transform(fieldName: string): Observable<GetSelectorFieldValuesResult> {
    return fieldName
      ? concat(
          of<GetSelectorFieldValuesResult>({ loading: true, value: [] }),
          this.fieldDictionaryService
            .getFieldDictionary(fieldName)
            .pipe(map((result) => ({ loading: false, value: result }))),
        )
      : of<GetSelectorFieldValuesResult>({ value: [] });
  }
}
