import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  Inject,
  Injectable,
  Injector,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { PreferencesService } from '@shared/api/preferences.service';
import { PushNotificationsService } from '@shared/api/push-notifications.service';
import { BaseObject } from '@shared/base/base-object';
import { isNotEmptyObject, isNotNull } from '@shared/base/core';
import { AuthService } from '@shared/helpers/auth.service';
import { FirebaseMessagingService } from '@shared/helpers/firebase-messaging.service';
import { WebSocketService } from '@shared/helpers/websocket.service';
import { UserState } from '@shared/states/user.state';
import { DialogService, RIGHT_SIDE_DIALOG_CONFIG } from '@ui/dialog/dialog.service';
import { AppScrollStrategy } from 'app/config/scroll-strategy';
import { NotificationsDialogFactory } from 'app/dialogs/notifications/notifications-dialog.factory';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import {
  ConfirmationData,
  ConfirmationResult,
  SubscriptionData,
} from './confirmation/confirmation.types';
import { CookieAcceptComponent } from './cookie-accept/cookie-accept.component';
import { ErrorComponent } from './error/error.component';
import { ErrorData } from './error/error.types';
import { LogsComponent } from './logs/logs.component';
import { LogsData } from './logs/logs.types';
import { MessageComponent } from './message/message.component';
import { MessageData } from './message/message.types';
import {
  FirebaseMessage,
  NotificationData,
  NotificationOptions,
  NotificationPosition,
  NotificationType,
} from './notifications.types';
import { NotificationsContainerComponent } from './push-notifications/notifications-container.component';
import { SessionExpirationComponent } from './session-expiration/session-expiration.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { VersionUpdateComponent } from './version-update/version-update.component';
import { VersionUpdateResult } from './version-update/version-update.types';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService extends BaseObject {
  public pushNotificationMessage$ = new BehaviorSubject<FirebaseMessage>(null);
  public pushNotificationsCount$ = new BehaviorSubject<number>(0);

  private bodyPortalOutlet: DomPortalOutlet;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private appRef: ApplicationRef,
    private injector: Injector,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private toastr: ToastrService,
    private auth: AuthService,
    private dialogService: DialogService,
    private preferencesService: PreferencesService,
    private firebaseMessagingService: FirebaseMessagingService,
    private pushNotificationsService: PushNotificationsService,
    private resolver: ComponentFactoryResolver,
    private ws: WebSocketService,
    private userState: UserState,
  ) {
    super();

    this.bodyPortalOutlet = new DomPortalOutlet(
      this.document.body,
      this.resolver,
      this.appRef,
      this.injector,
    );
  }

  public createPushNotificationsContainer(): void {
    if (!this.bodyPortalOutlet.hasAttached()) {
      const componentPortal = new ComponentPortal(NotificationsContainerComponent);
      this.bodyPortalOutlet.attach(componentPortal);

      this.destroy$.subscribe(() => this.bodyPortalOutlet.detach());
    }
  }

  public addNotification(
    type: NotificationType,
    title: string,
    message?: string,
    opts?: NotificationOptions,
  ): NotificationData {
    const toast = this.toastr.show(
      message,
      title,
      {
        timeOut: opts && isNotNull(opts.timeOut) ? opts.timeOut : 5000,
        disableTimeOut: opts && opts.disableTimeOut !== undefined ? opts.disableTimeOut : false,
        extendedTimeOut: 2000,
        tapToDismiss: false,
        positionClass: opts && opts.position ? opts.position : NotificationPosition.BottomLeft,
      },
      type,
    );

    return {
      id: toast.toastId,
      afterOpened$: toast.onShown,
      afterClosed$: toast.onHidden,
    };
  }

  public removeNotification(notificationId: number): void {
    this.toastr.remove(notificationId);
  }

  public openSessionExpirationDialog(): Observable<boolean> {
    return this.dialog
      .open<SessionExpirationComponent, void, boolean>(SessionExpirationComponent, {
        width: '420px',
        scrollStrategy: new AppScrollStrategy(),
        disableClose: true,
        panelClass: ['app-dialog-popup', '--no-bg'],
        backdropClass: 'app-dialog-backdrop',
      })
      .afterClosed();
  }

  public openVersionUpdateDialog(): Observable<VersionUpdateResult> {
    return this.dialog
      .open<VersionUpdateComponent, void, VersionUpdateResult>(VersionUpdateComponent, {
        width: '418px',
        scrollStrategy: new AppScrollStrategy(),
        disableClose: true,
        panelClass: ['app-dialog-popup', '--no-bg'],
        backdropClass: 'app-dialog-backdrop',
      })
      .afterClosed();
  }

  public openSystemNotificationsDialog(): void {
    this.dialogService.openLazy<void, void>({ factory: NotificationsDialogFactory }, null, {
      ...RIGHT_SIDE_DIALOG_CONFIG,
      width: '385px',
    });
  }

  public openCookieAcceptDialog(): void {
    this.userState.preferences$
      .pipe(
        filter((preferences) => isNotEmptyObject(preferences)),
        first(),
      )
      .subscribe((preferences) => {
        if (!preferences.cookieAccepted) {
          this.snackBar
            .openFromComponent(CookieAcceptComponent, {
              verticalPosition: 'bottom',
              horizontalPosition: 'right',
            })
            .afterDismissed()
            .subscribe(() => {
              const submitData = Object.assign({}, this.userState.preferences);
              submitData.cookieAccepted = true;

              this.preferencesService.save(submitData).subscribe(() => {
                this.userState.setPreferences(submitData);
              });
            });
        }
      });
  }

  public openConfirmationDialog(
    data: ConfirmationData,
    width?: string,
  ): Observable<ConfirmationResult> {
    return this.dialog
      .open<ConfirmationComponent, ConfirmationData, ConfirmationResult>(ConfirmationComponent, {
        width: width || '418px',
        scrollStrategy: new AppScrollStrategy(),
        disableClose: true,
        panelClass: ['app-dialog-popup', '--no-bg'],
        backdropClass: 'app-dialog-backdrop',
        data,
      })
      .afterClosed();
  }

  public openSubscriptionDialog(data?: SubscriptionData): Observable<void> {
    return this.dialog
      .open<SubscriptionComponent, SubscriptionData, void>(SubscriptionComponent, {
        width: '930px',
        scrollStrategy: new AppScrollStrategy(),
        panelClass: ['app-dialog-popup', '--no-bg'],
        backdropClass: 'app-dialog-backdrop',
        data,
      })
      .afterClosed();
  }

  public openMessageDialog(data: MessageData, width?: string): Observable<void> {
    return this.dialog
      .open<MessageComponent, MessageData, void>(MessageComponent, {
        width: width || '418px',
        scrollStrategy: new AppScrollStrategy(),
        disableClose: true,
        panelClass: ['app-dialog-popup', '--no-bg'],
        backdropClass: 'app-dialog-backdrop',
        data,
      })
      .afterClosed();
  }

  public openErrorDialog(data: ErrorData, width?: string): Observable<void> {
    return this.dialog
      .open<ErrorComponent, ErrorData, void>(ErrorComponent, {
        width: width || '620px',
        scrollStrategy: new AppScrollStrategy(),
        disableClose: true,
        panelClass: ['app-dialog-popup', '--no-bg'],
        backdropClass: 'app-dialog-backdrop',
        data,
      })
      .afterClosed();
  }

  public openLogsDialog(data: LogsData, width?: string): Observable<void> {
    return this.dialog
      .open<LogsComponent, ErrorData, void>(LogsComponent, {
        width: width || '620px',
        scrollStrategy: new AppScrollStrategy(),
        panelClass: ['app-dialog-popup', '--no-bg'],
        backdropClass: 'app-dialog-backdrop',
        data,
      })
      .afterClosed();
  }

  public subscribeToPushNotifications(): void {
    this.auth.loggedIn$
      .pipe(
        filter((logged) => logged),
        switchMap(() => {
          this.firebaseMessagingService.startListenToMessages();
          return this.firebaseMessagingService.token$;
        }),
        filter((firebaseToken) => !!firebaseToken),
      )
      .subscribe((firebaseToken) => {
        this.pushNotificationsService.subscribeToNotifications(firebaseToken).subscribe();
      });

    this.auth.loggedOut$.subscribe(() => {
      const firebaseToken = this.firebaseMessagingService.token$.value;

      if (!firebaseToken) {
        return;
      }

      this.pushNotificationsService.unsubscribeFromNotifications(firebaseToken).subscribe();
    });

    // subscribe to push notifications
    this.firebaseMessagingService.message$
      .pipe(filter((v) => !!v))
      .subscribe((message) => this.pushNotificationMessage$.next(message));
  }

  public getUnreadPushNotificationsCount(): void {
    this.pushNotificationsService
      .getUnreadPushNotificationsCount()
      .pipe(first())
      .subscribe((count) => {
        this.pushNotificationsCount$.next(count || 0);
        this.listenToUnreadPushNotificationCountChange();
      });
  }

  public closeAll(): void {
    this.dialog.closeAll();
  }

  private listenToUnreadPushNotificationCountChange(): void {
    this.ws
      .listenChannel<number>(`/topic/users.${this.userState.userId$.value}.open-push-count`, [
        this.destroy$,
      ])
      .subscribe((count) => {
        this.pushNotificationsCount$.next(count || 0);
      });
  }
}
