import { Pipe, PipeTransform } from '@angular/core';
import { UserState } from '@shared/states/user.state';
import { AuthoritiesCondition, Authority } from '@shared/types/authority';

@Pipe({
  name: 'needSubscriptionForAuthorities',
})
export class NeedSubscriptionForAuthoritiesPipe implements PipeTransform {
  constructor(private userState: UserState) {}

  public transform(
    checkAuthorities: Authority[],
    condition: AuthoritiesCondition = 'all',
  ): boolean {
    return this.userState.needSubscriptionForAuthorities(checkAuthorities, condition);
  }
}
