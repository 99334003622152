import { Directive, TemplateRef } from '@angular/core';
import { ValidationError } from '@ui/error/validation-error/validation-error.types';

export interface InputErrorDefContext {
  $implicit: ValidationError;
}

@Directive({
  selector: '[appInputError]',
})
export class InputErrorDefDirective {
  constructor(public template: TemplateRef<InputErrorDefContext>) {}

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  static ngTemplateContextGuard(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dir: InputErrorDefDirective,
    ctx: unknown,
  ): ctx is InputErrorDefContext {
    return true;
  }
}
