import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, Subject, interval, of } from 'rxjs';
import { catchError, filter, map, takeUntil, tap } from 'rxjs/operators';
import { LocalStorageConstants } from '@shared/constants/local-storage-constants';
import { ManagerAuthResponse } from '@shared/dto/gateway-public/models';
import { BrowserInfoService } from '@shared/helpers/browser-info.service';
import { DateHelper } from '@shared/helpers/date-helper.service';
import { RerdirectService } from '@shared/helpers/redirect.service';
import { ScreenRef } from '@shared/helpers/screen-ref';
import { SettingsState } from '@shared/states/settings.state';
import { LocalStore } from '@shared/store/local-store';

export const TOKEN_CHECK_INTERVAL_TIME_SEC = 5;
export const TOKEN_EXPIRATION_BEFORE_SEC = 60;

export interface AuthData {
  username: string;
  password: string;
}

export enum AuthEvents {
  LoggedIn = 'LoggedIn',
  LoggedOut = 'LoggedOut',
}

export interface AuthMessagePayload {
  loggedIn: boolean;
}

export interface AuthMessage {
  type: AuthEvents;
  payload: AuthMessagePayload;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _loggedIn$ = new BehaviorSubject<boolean>(
    !!localStorage.getItem(LocalStorageConstants.Token),
  );
  public loggedOut$ = new Subject<void>();
  private jwtHelperService = new JwtHelperService();
  private authChannel = new BroadcastChannel('AUTH_CHANNEL');

  constructor(
    private zone: NgZone,
    private http: HttpClient,
    private settingsState: SettingsState,
    private store: LocalStore,
    private browserInfoService: BrowserInfoService,
    private rerdirectService: RerdirectService,
    private screenRef: ScreenRef,
    private dateHelper: DateHelper,
  ) {
    this.loggedIn$.pipe(filter((loggedIn) => loggedIn)).subscribe(() => {
      this.runTokenCheckLoop();

      this.authChannel.onmessage = (event) => {
        const message = event.data as AuthMessage;

        if (message.type === AuthEvents.LoggedOut && !message.payload.loggedIn) {
          this.logout(true);
        }
      };
    });
  }

  public setLoggedIn(value: boolean): void {
    if (this._loggedIn$.value !== value) {
      this._loggedIn$.next(value);
    }
  }

  public get loggedIn(): boolean {
    return this._loggedIn$.value;
  }

  public get loggedIn$(): Observable<boolean> {
    return this._loggedIn$.asObservable();
  }

  public login(authData: AuthData): Observable<ManagerAuthResponse> {
    const userClient = this.browserInfoService.browserInfo;
    const params = new HttpParams()
      .append('os', userClient.os)
      .append('isMobile', String(userClient.mobile))
      .append('browser', userClient.browser)
      .append('timezone', this.getUTCTimezone(new Date().getTimezoneOffset()))
      .append('screenSize', userClient.screenSize)
      .append('clientApplicationType', 'WEB');

    return this.http
      .post<ManagerAuthResponse>(this.settingsState.apiPath + '/auth/cookie', authData, {
        observe: 'response',
        params,
        headers: {
          Authorization: 'Basic ' + btoa(`${authData.username.trim()}:${authData.password}`),
        },
      })
      .pipe(
        map((res) => {
          const data = res.body;

          this.clearStorage();
          localStorage.setItem(LocalStorageConstants.Token, data.token);
          localStorage.setItem(LocalStorageConstants.Name, data.name);
          localStorage.setItem(LocalStorageConstants.UserName, data.username?.trim());
          localStorage.setItem(LocalStorageConstants.Email, data.email?.trim());
          localStorage.setItem(LocalStorageConstants.UserId, data.managerId);
          localStorage.setItem(LocalStorageConstants.Authorities, JSON.stringify(data.authorities));
          localStorage.setItem(LocalStorageConstants.Role, JSON.stringify(data.role));

          const serverVersion = res.headers.get('x-app-version');

          if (serverVersion && serverVersion !== this.settingsState.version) {
            this.settingsState.version = serverVersion;
            this.store.clearTablesStoreAfterInit = true;
          }

          this.sendMessage(AuthEvents.LoggedIn, { loggedIn: true });

          return data;
        }),
      );
  }

  public logout(withReload?: boolean, resetLastPage?: boolean, sendMessage?: boolean): void {
    this.setLoggedIn(false);
    this.rerdirectService.lastPage = location.href;

    this.http.post(this.settingsState.apiPath + '/session-logout', null).subscribe();

    this.loggedOut$.next();

    if (sendMessage) {
      this.sendMessage(AuthEvents.LoggedOut, { loggedIn: false });
    }

    this.clearStorage(resetLastPage);
    this.rerdirectService.goToLoginPage(withReload);
  }

  private runTokenCheckLoop(): void {
    this.zone.runOutsideAngular(() => {
      interval(TOKEN_CHECK_INTERVAL_TIME_SEC * 1000)
        .pipe(
          filter(() => this.loggedIn && this.screenRef.visible$.value),
          takeUntil(this.loggedOut$),
        )
        .subscribe(() =>
          this.zone.run(() => {
            const token = localStorage.getItem(LocalStorageConstants.Token);
            const isTokenExpired = this.jwtHelperService.isTokenExpired(token);

            if (!token || isTokenExpired) {
              this.setLoggedIn(false);
              this.logout(true);
              return;
            }

            const tokenExpirationDate = this.jwtHelperService.getTokenExpirationDate(token);
            const timeForTokenUpdate = this.dateHelper.sub(
              tokenExpirationDate,
              TOKEN_EXPIRATION_BEFORE_SEC,
              'Seconds',
            );

            if (this.dateHelper.isAfterOrEqual(this.dateHelper.now(false), timeForTokenUpdate)) {
              this.refreshToken().subscribe((valid) => {
                this.setLoggedIn(valid);

                if (!valid) {
                  this.logout(true);
                }
              });
            }
          }),
        );
    });
  }

  private sendMessage(type: AuthEvents, payload: AuthMessagePayload): void {
    this.authChannel.postMessage({
      type,
      payload,
    });
  }

  public validate(): Observable<boolean> {
    const token = localStorage.getItem(LocalStorageConstants.Token);

    if (!token) {
      return of(false);
    }

    const valid = this.tokenIsValid();

    if (!valid) {
      return this.refreshToken().pipe(tap((result) => this.setLoggedIn(result)));
    } else {
      this.setLoggedIn(true);
      return of(true);
    }
  }

  private tokenIsValid(): boolean {
    try {
      const token = localStorage.getItem(LocalStorageConstants.Token);
      const valid = !this.jwtHelperService.isTokenExpired(token);

      return valid;
    } catch (e) {
      return false;
    }
  }

  public clearStorage(resetLastPage: boolean = false): void {
    const currentTheme = localStorage.getItem(LocalStorageConstants.CurrentTheme);
    const lastPage = this.rerdirectService.lastPage;
    const isAdvancedFrom = localStorage.getItem(LocalStorageConstants.AdvancedTransactionMode);

    localStorage.clear();

    if (!resetLastPage) {
      this.rerdirectService.lastPage = lastPage;
    }

    localStorage.setItem(LocalStorageConstants.AdvancedTransactionMode, isAdvancedFrom);
    localStorage.setItem(LocalStorageConstants.CurrentTheme, currentTheme);
  }

  private refreshToken(): Observable<boolean> {
    return this.http
      .put(this.settingsState.apiPath + '/auth/cookie', null, {
        responseType: 'text',
      })
      .pipe(
        tap((token) => localStorage.setItem(LocalStorageConstants.Token, token)),
        map((token) => !!token),
        catchError(() => of(false)),
      );
  }

  private getUTCTimezone(offset: number): string {
    // get timezone in hours and invert.
    // By default getTimeZoneOffset returns -180 for UTC +3 and vice versa
    return `${(offset / 60) * -1}`;
  }
}
