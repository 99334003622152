import { DomPortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Inject,
  Injector,
  Input,
  NgZone,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { tooltipAnimation } from '@shared/animations/animations';
import { BaseObject } from '@shared/base/base-object';
import { getElementRect, rectInsideViewPort } from '@shared/helpers/coordinate.helper';
import { Rect } from '@shared/types/rect';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[appClippedTextTooltip]',
  templateUrl: './clipped-text-tooltip.component.html',
  styleUrls: ['./clipped-text-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [tooltipAnimation],
  host: {
    class: 'app-clipped-text-tooltip',
    '[class.--text-to-right]': 'textToRight',
  },
})
export class ClippedTextTooltipComponent extends BaseObject implements AfterViewInit {
  @Input('appClippedTextTooltip') public tooltip: string | string[];
  @Input('appClippedTextTooltipTextToRight') public textToRight: boolean;
  @Input('appClippedTextTooltipLineLimit') public lineLimit: number;

  @ViewChild('label') public labelElement: ElementRef<HTMLElement>;
  @ViewChild('labelTest') public labelTestElement: ElementRef<HTMLElement>;

  @ViewChild('tooltipTemplate', { static: true })
  public tooltipTemplate: TemplateRef<unknown>;

  public message: string;
  public hostRect: Rect;
  private bodyPortalOutlet: DomPortalOutlet;

  public tooltipVisible$ = new BehaviorSubject<boolean>(false);
  public tooltipTop$ = new BehaviorSubject<number>(0);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public el: ElementRef<HTMLElement>,
    private zone: NgZone,
    private injector: Injector,
    private appRef: ApplicationRef,
    private viewContainerRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.listenToMouse();
    });
  }

  private listenToMouse(): void {
    fromEvent(this.labelElement.nativeElement, 'mouseenter')
      .pipe(
        tap(() => this.showTooltipIfRequired()),
        switchMap(() => fromEvent(this.labelElement.nativeElement, 'mouseleave')),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.labelTestElement.nativeElement.textContent = null;

        this.zone.run(() => {
          this.hideTooltip();
        });
      });
  }

  private showTooltipIfRequired(): void {
    const labelRect = getElementRect(this.labelElement.nativeElement);
    this.hostRect = getElementRect(this.el.nativeElement);

    if (labelRect.width < this.hostRect.width) {
      this.hideTooltip();
    } else {
      this.labelTestElement.nativeElement.textContent = Array.isArray(this.tooltip)
        ? this.tooltip.join(', ')
        : this.tooltip;

      const labelTestRect = getElementRect(this.labelTestElement.nativeElement);

      if (
        labelTestRect.width > this.hostRect.width ||
        (this.lineLimit && labelTestRect.height > this.hostRect.height)
      ) {
        this.zone.run(() => {
          const message = Array.isArray(this.tooltip) ? this.tooltip.join('\n') : this.tooltip;

          this.openTooltip(message);
        });
      }
    }
  }

  private openTooltip(message: string): void {
    this.message = message;

    this.bodyPortalOutlet = new DomPortalOutlet(
      this.document.body,
      this.resolver,
      this.appRef,
      this.injector,
    );

    if (!this.bodyPortalOutlet.hasAttached()) {
      this.tooltipTop$.next(this.hostRect.top + this.hostRect.height);

      const portal = new TemplatePortal(this.tooltipTemplate, this.viewContainerRef);
      const viewRef = this.bodyPortalOutlet.attach(portal);
      const tooltipRect = getElementRect(viewRef.rootNodes[0] as HTMLElement);

      if (rectInsideViewPort(tooltipRect)) {
        this.tooltipVisible$.next(true);
      } else {
        this.tooltipTop$.next(this.hostRect.top - tooltipRect.height);
        this.tooltipVisible$.next(true);
      }

      this.destroy$.subscribe(() => this.bodyPortalOutlet.detach());
    }
  }

  private hideTooltip(): void {
    this.message = null;
    this.tooltipVisible$.next(false);
    this.tooltipTop$.next(0);
    this.bodyPortalOutlet?.detach();
  }
}
