import { Pipe, PipeTransform } from '@angular/core';

/* eslint-disable @typescript-eslint/no-explicit-any */
@Pipe({
  name: 'mergeObjects',
})
export class MergeObjectsPipe implements PipeTransform {
  public transform(objects: Record<string, any>[]): Record<string, any> {
    return Object.assign({}, ...objects.filter((v) => v));
  }
}
