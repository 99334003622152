import { Platform } from '@angular/cdk/platform';
import { Inject, Injectable } from '@angular/core';
import { MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { DateHelper } from '@shared/helpers/date-helper.service';
import { AppDatePipe } from '@shared/pipes/date.pipe';
import { UserState } from '@shared/states/user.state';
import { AppInjector } from 'app/config/app-injector';
import { DEFAULT_DATE_FORMATS, DEFAULT_DATE_INPUT_FORMAT } from './input.config';

@Injectable()
export class InputDateTimeAdapter extends NativeDateAdapter {
  private DATE_PIPE = new AppDatePipe();
  private dateHelper: DateHelper;
  private userState: UserState;

  constructor(
    @Inject(MAT_DATE_LOCALE) public matDateLocale: string,
    public platform: Platform,
  ) {
    super(matDateLocale, platform);

    this.dateHelper = AppInjector.Injector.get(DateHelper);
    this.userState = AppInjector.Injector.get(UserState);
  }

  public deserialize(date: string | Date): Date | null {
    return (
      this.dateHelper.parse(date, `${this.userState.dateFormat} HH:mm:ss`) ||
      super.deserialize(date)
    );
  }

  public parse(date: string): Date | null {
    return this.dateHelper.parse(date, `${this.userState.dateFormat} HH:mm:ss`);
  }

  public format(date: Date, displayFormat: string): string {
    switch (displayFormat) {
      case DEFAULT_DATE_INPUT_FORMAT:
        return this.DATE_PIPE.transform(this.dateHelper.parse(date), true, 'HH:mm:ss');

      case DEFAULT_DATE_FORMATS.display.monthYearLabel:
        return this.DATE_PIPE.transform(this.dateHelper.parse(date));

      default:
        return super.format(date, displayFormat);
    }
  }

  public compareDate(first: Date, second: Date): number {
    return first.getTime() - second.getTime();
  }
}
