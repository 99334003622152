import { Pipe, PipeTransform } from '@angular/core';
import get from 'lodash-es/get';

@Pipe({
  name: 'getValue',
})
export class GetValuePipe implements PipeTransform {
  public transform(obj: unknown, valueKeyPath: string): unknown {
    return get(obj, valueKeyPath);
  }
}
