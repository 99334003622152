import { Platform } from '@angular/cdk/platform';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Host,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  SkipSelf,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerToggle } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { BaseControl } from '@shared/base/base-control';
import { isNotNull } from '@shared/base/core';
import { DateHelper } from '@shared/helpers/date-helper.service';
import { UserState } from '@shared/states/user.state';
import { THOUSAND_SEPARATOR } from '@shared/types/preferences';
import { DatepickerCalendarHeaderComponent } from './datepicker/datepicker-calendar-header.component';
import { DateRangePeriod } from './datepicker/daterange-picker.types';
import { InputDateAdapter } from './input-date-adapter';
import { InputDateTimeAdapter } from './input-date-time-adapter';
import { InputErrorDefDirective } from './input-error-def.directive';
import { InputPrefixDirective } from './input-prefix.directive';
import { InputSuffixDirective } from './input-suffix.directive';
import { DEFAULT_DATE_FORMATS } from './input.config';
import { InputState } from './input.state';
import {
  CustomDatepickerType,
  CustomDaterangePickerType,
  InputControlType,
  InputCustomDateType,
  InputType,
} from './input.types';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
    { provide: MAT_DATE_FORMATS, useValue: DEFAULT_DATE_FORMATS },
    {
      provide: DateAdapter,
      useFactory: (
        matDateLocale: string,
        platform: Platform,
        inputState: InputState,
      ): DateAdapter<Date> => {
        switch (inputState.inputType) {
          case 'date':
          case 'date-range':
            return new InputDateAdapter(matDateLocale, platform);

          case 'datetime':
          case 'datetime-range':
            return new InputDateTimeAdapter(matDateLocale, platform);

          default:
            return null;
        }
      },
      deps: [MAT_DATE_LOCALE, Platform, InputState],
    },
    InputState,
  ],
  host: {
    class: 'app-input',
    '[class.--no-gap]': '!gap',
    '[class.--no-bg]': 'noBg',
    '[class.--no-label]': '!label',
  },
})
export class InputComponent<TValue = InputControlType>
  extends BaseControl<FormControl<TValue>>
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() public formControl: FormControl<TValue>;
  @Input() public formControlName: string;

  @Input() public allowIntegerCounterButtons: boolean = false;
  @Input() public allowNegativeIntegers: boolean = false;
  @Input() public allowNegativeNumbers: boolean = true;
  @Input() public autocomplete: 'off' | 'email' | 'current-password' | 'new-password' = 'off';
  @Input() public autofocus: boolean = false;
  @Input() public calendarIconBeforePosition: boolean = false;
  @Input() public customDate: InputCustomDateType;
  @Input() public decimalsCount: number;
  @Input() public defaultPeriod: DateRangePeriod;
  @Input() public gap: boolean = true;
  @Input() public hideRequiredMarker: boolean = false;
  @Input() public requiredMarkerIsVisible: (control: FormControl<TValue>) => boolean;
  @Input() public label: string;
  @Input() public mask: string;
  @Input() public maskPrefix: string = '';
  @Input() public maskValidation: boolean = true;
  @Input() public maxDate: Date;
  @Input() public minDate: Date;
  @Input() public noBg: boolean = false;
  @Input() public placeholder: string;
  @Input() public readonly: boolean;
  @Input() public textareaFillContent: boolean = false;
  @Input() public type: InputType = 'text';
  @Input() public value: TValue;

  @Output() public readonly inputTextEvent = new EventEmitter<string>();
  @Output() public readonly inputNumberEvent = new EventEmitter<number>();
  @Output() public readonly focusEvent = new EventEmitter<FocusEvent>();
  @Output() public readonly blurEvent = new EventEmitter<Event>();
  @Output() public readonly datepickerClosedEvent = new EventEmitter<Date | void>();
  @Output() public readonly dateRangePeriodChange = new EventEmitter<DateRangePeriod>();
  @Output() public readonly customDatepickerClosedEvent =
    new EventEmitter<CustomDatepickerType | null>();
  @Output() public readonly customDaterangePickerClosedEvent =
    new EventEmitter<CustomDaterangePickerType | null>();

  @HostBinding('class.app-input--textarea')
  public get _isTextarea(): boolean {
    return this.type === 'textarea';
  }

  @HostBinding('class.app-input--textarea-fill-content')
  public get _textareaFillContent(): boolean {
    return this._isTextarea && this.textareaFillContent;
  }

  @HostBinding('class.app-input--integer')
  public get _isInteger(): boolean {
    return this.type === 'integer' && this.allowIntegerCounterButtons && this._valueIsInteger;
  }

  public numberInputMask: string;
  public passwordPatterns = {
    P: {
      pattern: new RegExp('[0-9]|[A-Za-z]|[@$!%*#?&^_]'),
    },
  };
  public showPasswordAsDots: boolean = true;
  public get _passwordType(): 'text' | 'password' {
    return this.showPasswordAsDots ? 'password' : 'text';
  }
  public thousandSeparator = THOUSAND_SEPARATOR;

  @ViewChild(MatInput) private matInput: MatInput;
  @ViewChild(MatInput, { read: ElementRef }) public inputElement: ElementRef<
    HTMLInputElement | HTMLTextAreaElement
  >;
  @ViewChild(MatDatepickerToggle) private datepickerToggle: MatDatepickerToggle<string>;

  @ContentChild(InputPrefixDirective) public prefixDef: InputPrefixDirective;
  @ContentChild(InputSuffixDirective) public suffixDef: InputSuffixDirective;
  @ContentChild(InputErrorDefDirective) public errorDef: InputErrorDefDirective;

  public calendarHeader = DatepickerCalendarHeaderComponent;

  public _dateRangeGroup = new FormGroup({
    start: new FormControl<Date>(null, Validators.required),
    end: new FormControl<Date>(null, Validators.required),
  });

  public _customDatePickerOpened: boolean = false;
  public hasPrefix$ = new BehaviorSubject<boolean>(false);

  public get _requiredMarkerIsVisible(): boolean {
    return (
      this.formControl &&
      this.requiredMarkerIsVisible &&
      this.requiredMarkerIsVisible(this.formControl)
    );
  }

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,

    public element: ElementRef<HTMLElement>,
    public cd: ChangeDetectorRef,
    private zone: NgZone,
    private userState: UserState,
    private dateHelper: DateHelper,

    public inputState: InputState,
  ) {
    super();
  }

  public ngOnInit(): void {
    const decimalsCount = this.decimalsCount || this.userState.preferences.decimalsCount;
    this.numberInputMask = `separator.${decimalsCount}`;

    if (!this.formControl && this.controlContainer) {
      this.formControl = this.controlContainer.control.get(
        this.formControlName,
      ) as FormControl<TValue>;
    }

    this.inputState.inputType = this.type;
    this.inputState.customDate = this.customDate;

    switch (this.type) {
      case 'integer': {
        if (this.allowIntegerCounterButtons) {
          const formControl = this.formControl as FormControl<number>;

          formControl.setValue(
            isNotNull(this.formControl.value) ? parseInt(this.formControl.value as string) : 0,
            {
              emitEvent: false,
              onlySelf: true,
            },
          );

          this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
            // check empty value and set to 0
            if (!value && !this._valueIsInteger) {
              formControl.setValue(0, { emitEvent: false, onlySelf: true });
            }
          });
        }

        break;
      }

      case 'number': {
        if (isNotNull(this.formControl.value)) {
          this.zone.runOutsideAngular(() => {
            setTimeout(() => {
              const formControl = this.formControl as FormControl<number>;

              formControl.setValue(parseFloat(this.formControl.value as string), {
                emitEvent: false,
                onlySelf: true,
              });
              this.cd.markForCheck();
            });
          });
        }
        break;
      }

      case 'date':
      case 'datetime':
        if (!this.customDate) {
          const formControl = this.formControl as FormControl<Date>;

          formControl.setValue(
            isNotNull(this.formControl.value)
              ? this.dateHelper.parse(this.formControl.value as string)
              : null,
            {
              emitEvent: false,
            },
          );
        }

        break;

      case 'date-range':
      case 'datetime-range': {
        const formControl = this.formControl as FormControl<[Date, Date]>;
        let initialRange = formControl.value;

        if ((!initialRange?.length || !initialRange[0] || !initialRange[1]) && this.defaultPeriod) {
          const range = this.dateHelper.convertDateRangePeriodToDateRange(this.defaultPeriod);
          initialRange = [this.minDate || range.start, range.end];
          this._dateRangeGroup.patchValue({ start: initialRange[0], end: initialRange[1] });
          formControl.setValue(initialRange);
        }

        this.setDateRangeArrayToRangeGroup(initialRange);

        this._dateRangeGroup.valueChanges
          .pipe(
            distinctUntilChanged(
              (prev, curr) =>
                this.dateHelper.isEqual(prev.start, curr.start) &&
                this.dateHelper.isEqual(prev.end, curr.end),
            ),
            takeUntil(this.destroy$),
          )
          .subscribe((range) => {
            switch (true) {
              case !!range.start && !!range.end:
                if (this._onChange) {
                  this._onChange([range.start, range.end]);
                }
                break;

              case !range.start && !range.end:
                if (this._onChange) {
                  this._onChange([]);
                }
                break;
            }

            this.inputState.dateRangePeriod$.next(null);
          });

        this.inputState.dateRangeGroup = this._dateRangeGroup;
        this.inputState.dateRangePeriod$.next(this.defaultPeriod);
        this.inputState.dateRangePeriod$
          .pipe(takeUntil(this.destroy$))
          .subscribe((dateRangePeriod) => {
            this.dateRangePeriodChange.next(dateRangePeriod);
          });
        break;
      }
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.value?.currentValue) {
      if (!this.formControl) {
        this.formControl = new FormControl();
      }

      this.formControl.setValue(this.value);
    }
  }

  public ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.cd.detectChanges();
      });
    });
  }

  public writeValue(obj: unknown): void {
    switch (this.type) {
      case 'date-range':
      case 'datetime-range': {
        this._dateRangeGroup.reset({ start: null, end: null }, { emitEvent: false });
        this.setDateRangeArrayToRangeGroup(obj as [Date, Date], false);
        break;
      }
    }

    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.cd.markForCheck();
      });
    });
  }

  public validate(_: AbstractControl): ValidationErrors | null {
    return null;
  }

  public setDisabledState(value: boolean): void {
    super.setDisabledState(value);

    this.cd.markForCheck();
  }

  public _onFocus(event: FocusEvent): void {
    this.focusEvent.next(event);
  }

  public _onBlur(event: Event): void {
    this.blurEvent.next(event);
  }

  public _onInputText(e: Event): void {
    const input = e.target as HTMLInputElement;
    const _inputValue = input.value?.split(THOUSAND_SEPARATOR)?.join('');
    this.inputTextEvent.next(_inputValue);
  }

  public _onInputNumber(e: Event): void {
    const input = e.target as HTMLInputElement;
    const cursorAtEnd = input.value.length === input.selectionStart;
    const _inputValue = input.value?.split(THOUSAND_SEPARATOR)?.join('');
    const inputValue = this.type === 'integer' ? parseInt(_inputValue) : parseFloat(_inputValue);

    this.inputNumberEvent.next(inputValue);

    if (!cursorAtEnd) {
      return;
    }

    const eventData = (e as InputEvent).data;

    const eventValue = parseInt(eventData);
    if (isNaN(eventValue)) {
      return;
    }

    if (isNaN(inputValue)) {
      return;
    }

    if (inputValue > 0) {
      return;
    }

    switch (this.type) {
      case 'integer':
        if (eventValue !== 0) {
          const formControl = this.formControl as FormControl<number>;
          formControl.setValue(eventValue);
        }
        break;

      case 'number': {
        const isNegativeNumber = input.value.startsWith('-');

        if (!isNegativeNumber) {
          const isFloatNumber = input.value.startsWith('0.') || input.value.startsWith('-0.');

          if (eventValue !== 0 && !isFloatNumber) {
            const formControl = this.formControl as FormControl<number>;
            formControl.setValue(eventValue);
          }
        }

        break;
      }
    }
  }

  public focus(): void {
    this.matInput.focus();
  }

  public blur(): void {
    this.inputElement.nativeElement.blur();
  }

  public _onIncrement(event: MouseEvent): void {
    event.stopPropagation();

    if (this._valueIsInteger) {
      const formControl = this.formControl as FormControl<number>;
      formControl.setValue(parseInt(this.formControl.value as string) + 1);
    }
  }

  public _onDecrement(event: MouseEvent): void {
    event.stopPropagation();

    if (this._valueIsInteger) {
      const formControl = this.formControl as FormControl<number>;

      if (this.allowNegativeIntegers) {
        formControl.setValue(parseInt(this.formControl.value as string) - 1);
      } else {
        if (formControl.value > 0) {
          formControl.setValue(parseInt(this.formControl.value as string) - 1);
        }
      }
    }
  }

  public get _valueIsInteger(): boolean {
    return Number.isInteger(parseInt(this.formControl.value as string));
  }

  public openDatepicker(): void {
    this.datepickerToggle.datepicker.open();
  }

  public datepickerIsOpened(): boolean {
    return this.datepickerToggle.datepicker.opened;
  }

  public getDatepickerElement(): HTMLElement {
    return this.inputState.datepickerElement;
  }

  private setDateRangeArrayToRangeGroup(range: [Date, Date], emitEvent: boolean = true): void {
    if (range && Array.isArray(range) && range.length === 2) {
      const start = this.dateHelper.parse(range[0]);
      const end = this.dateHelper.parse(range[1]);

      this._dateRangeGroup.patchValue(
        {
          start,
          end,
        },
        { emitEvent },
      );
    }
  }

  public _onCustomDatepickerClose(data: CustomDatepickerType): void {
    this.customDatepickerClosedEvent.next(data);
    this._customDatePickerOpened = !this._customDatePickerOpened;

    if (data) {
      const formControl = this.formControl as FormControl<CustomDatepickerType>;
      formControl.setValue(data);
    }
  }

  public _onCustomDaterangePickerClose(data: CustomDaterangePickerType): void {
    this.customDaterangePickerClosedEvent.next(data);
    this._customDatePickerOpened = !this._customDatePickerOpened;

    if (data) {
      const formControl = this.formControl as FormControl<CustomDaterangePickerType>;
      formControl.setValue(data);
    }
  }
}
