/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LeadManagerDTO } from './lead-manager-dto';
import { CompanyDTO } from './company-dto';

export interface LeadDTO {
  accountType?: LeadDTO.AccountTypeEnum;
  availableTransitions?: Array<LeadDTO.AvailableTransitionsEnum>;
  birthDate?: string;
  citizenships?: Array<string>;
  city?: string;
  clientId?: string;
  company?: CompanyDTO;
  country?: string;
  countryOfTaxation?: string;
  createdAt?: string;
  createdBy?: string;
  createdByName?: string;
  dateOfAppearance?: string;
  dateOfExpiry?: string;
  dateOfIssue?: string;
  email?: string;
  extId?: string;
  firstName: string;
  fullName?: string;
  houseNumber?: string;
  id?: string;
  identificationNumber?: string;
  investmentObjective?: LeadDTO.InvestmentObjectiveEnum;
  lastName: string;
  leadManagers?: Array<LeadManagerDTO>;
  modifiable?: boolean;
  nationalIdCountryOfIssue?: string;
  opportunityType?: LeadDTO.OpportunityTypeEnum;
  passportCountryOfIssue?: string;
  passportNumber?: string;
  pep?: boolean;
  personalId?: string;
  phoneNumber?: string;
  questionnaires?: string;
  riskLevel?: LeadDTO.RiskLevelEnum;
  source?: LeadDTO.SourceEnum;
  status?: LeadDTO.StatusEnum;
  street?: string;
  tin?: string;
  updatedAt?: string;
  updatedBy?: string;
  worldCheck?: boolean;
  zipCode?: string;
}
export namespace LeadDTO {
  export type AccountTypeEnum = 'ENTITY' | 'INDIVIDUAL';
  export const AccountTypeEnum = {
    Entity: 'ENTITY' as AccountTypeEnum,
    Individual: 'INDIVIDUAL' as AccountTypeEnum,
  };
  export type AvailableTransitionsEnum =
    | 'TO_BACK_TO_WORKING'
    | 'TO_LOST'
    | 'TO_NEW'
    | 'TO_QUALIFIED'
    | 'TO_READY_TO_QUALIFIED'
    | 'TO_REOPEN'
    | 'TO_SUSPENDED'
    | 'TO_WORKING'
    | 'TO_WORLD_CHECK';
  export const AvailableTransitionsEnum = {
    BackToWorking: 'TO_BACK_TO_WORKING' as AvailableTransitionsEnum,
    Lost: 'TO_LOST' as AvailableTransitionsEnum,
    New: 'TO_NEW' as AvailableTransitionsEnum,
    Qualified: 'TO_QUALIFIED' as AvailableTransitionsEnum,
    ReadyToQualified: 'TO_READY_TO_QUALIFIED' as AvailableTransitionsEnum,
    Reopen: 'TO_REOPEN' as AvailableTransitionsEnum,
    Suspended: 'TO_SUSPENDED' as AvailableTransitionsEnum,
    Working: 'TO_WORKING' as AvailableTransitionsEnum,
    WorldCheck: 'TO_WORLD_CHECK' as AvailableTransitionsEnum,
  };
  export type InvestmentObjectiveEnum =
    | 'CAPITAL_GROWTH'
    | 'CAPITAL_PRESERVATION'
    | 'DIVERSIFICATION_OF_INVESTMENT'
    | 'INCOME_GENERATION'
    | 'LONG_TERM_CAPITAL_GROWTH'
    | 'LOW_RISK_INCOME_GENERATION';
  export const InvestmentObjectiveEnum = {
    CapitalGrowth: 'CAPITAL_GROWTH' as InvestmentObjectiveEnum,
    CapitalPreservation: 'CAPITAL_PRESERVATION' as InvestmentObjectiveEnum,
    DiversificationOfInvestment: 'DIVERSIFICATION_OF_INVESTMENT' as InvestmentObjectiveEnum,
    IncomeGeneration: 'INCOME_GENERATION' as InvestmentObjectiveEnum,
    LongTermCapitalGrowth: 'LONG_TERM_CAPITAL_GROWTH' as InvestmentObjectiveEnum,
    LowRiskIncomeGeneration: 'LOW_RISK_INCOME_GENERATION' as InvestmentObjectiveEnum,
  };
  export type OpportunityTypeEnum = 'BROKERAGE' | 'CLIENT_REQUEST' | 'EMA' | 'IMA' | 'RTL';
  export const OpportunityTypeEnum = {
    Brokerage: 'BROKERAGE' as OpportunityTypeEnum,
    ClientRequest: 'CLIENT_REQUEST' as OpportunityTypeEnum,
    Ema: 'EMA' as OpportunityTypeEnum,
    Ima: 'IMA' as OpportunityTypeEnum,
    Rtl: 'RTL' as OpportunityTypeEnum,
  };
  export type RiskLevelEnum = 'HIGH_RISK' | 'LOW_RISK' | 'NORMAL_RISK';
  export const RiskLevelEnum = {
    HighRisk: 'HIGH_RISK' as RiskLevelEnum,
    LowRisk: 'LOW_RISK' as RiskLevelEnum,
    NormalRisk: 'NORMAL_RISK' as RiskLevelEnum,
  };
  export type SourceEnum =
    | 'AGENT'
    | 'APPLICATION'
    | 'OTHER'
    | 'SALES_MANAGER'
    | 'SUMSUB'
    | 'WEBSITE';
  export const SourceEnum = {
    Agent: 'AGENT' as SourceEnum,
    Application: 'APPLICATION' as SourceEnum,
    Other: 'OTHER' as SourceEnum,
    SalesManager: 'SALES_MANAGER' as SourceEnum,
    Sumsub: 'SUMSUB' as SourceEnum,
    Website: 'WEBSITE' as SourceEnum,
  };
  export type StatusEnum =
    | 'LOST'
    | 'NEW'
    | 'QUALIFIED'
    | 'READY_TO_QUALIFIED'
    | 'SUSPENDED'
    | 'UNQUALIFIED'
    | 'WORKING'
    | 'WORLD_CHECK';
  export const StatusEnum = {
    Lost: 'LOST' as StatusEnum,
    New: 'NEW' as StatusEnum,
    Qualified: 'QUALIFIED' as StatusEnum,
    ReadyToQualified: 'READY_TO_QUALIFIED' as StatusEnum,
    Suspended: 'SUSPENDED' as StatusEnum,
    Unqualified: 'UNQUALIFIED' as StatusEnum,
    Working: 'WORKING' as StatusEnum,
    WorldCheck: 'WORLD_CHECK' as StatusEnum,
  };
}
