<div class="header-container"
     [style.margin-top]="!!errorMessage?.length ? '20px' : null">
  <div *ngIf="errorMessage?.length > 1"
       class="error-list-container app-bg-05 app-border-r6">
    <div style="overflow-y: scroll; overflow-x: hidden;"
         class="error-list-scrollbar app-scrollbar-block">
      <p class="app-text-danger h4-b"
         style="margin-bottom: 12px;">
        List of Error Positions
      </p>

      <ng-container *ngFor="let error of errorMessage; let index = index;">
        <ng-container *ngIf="index >= 1">
          <div class="app-border-bottom-05 h4-m error-list-item">
            <span [appClippedTextTooltip]="error"
                  class="app-text-white-space-nowrap">
              {{ error }}
            </span>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <p [class.app-text-danger]="!!errorMessage?.length"
     [class.h4-b]="label && !errorMessage?.length"
     [class.b5-r]="!label && errorMessage?.length"
     *ngIf="!!errorMessage?.length || label">
    {{ errorMessage?.length ? errorMessage[0] : label }}
  </p>

  <p *ngIf="isNewDesign"
     [class.app-text-danger]="!!errorMessage?.length"
     [class.app-text-color-50]="!errorMessage?.length"
     [style.margin-bottom]="!!errorMessage?.length ? '24px' : '0'"
     class="h4-m">
    {{ errorMessage?.[0] || 'This should be a CSV file with the fields that the template contains' }}
  </p>
</div>

<div class="app-drag-and-drop-uploader__dnd-place app-border-r6 app-bg-05 app-scrollbar-block"
     [style.height]="dndPlaceHeight"
     [style.max-height]="dndPlaceHeight"
     [style.width]="isNewDesign ? '100%' : null"
     appDragAndDrop
     [format]="format"
     [multiple]="multiple"
     [disabled]="disabled"
     (filesDropped)="_onChangeData($event)"
     (click)="_onDndClick($event)">

  <app-svg [icon]="'Upload_24'"
           style="margin-bottom: 6px;">
  </app-svg>

  <span class="h4-b"
        style="margin-bottom: 2px;">
    Upload File
  </span>

  <span class="b4-r"
        style="margin-bottom: 2px;">
    <span class="app-text-success">Click to Upload</span>
    <span class="app-text-color-50"> or Drag and Drop</span>
  </span>

  <span *ngIf="description || accept"
        class="b4-r app-text-color-50">
    {{ description || (accept | textTransform:'common':'common':'upper') }}
  </span>
</div>

<div class="app-drag-and-drop-uploader__file-list app-scrollbar-block">
  <div *ngFor="let file of files$|async as _files; let index = index; trackBy:_trackByIndex;"
       class="app-drag-and-drop-uploader__file-list__item app-border-05 app-border-r6 app-bg-05">

    <app-svg [icon]="'File_24'"
             [disabledHover]="true"
             style="margin-right: 10px">
    </app-svg>

    <div class="h4-m"
         style="width: calc(100% - 54px)"
         *ngLet="file.name || ('File ' + index) as _text"
         [appClippedTextTooltip]="_text">
      {{ _text }}
    </div>

    <app-svg [icon]="'Delete2_16'"
             [disabled]="disabled"
             style="width: 24px; height: 24px;"
             class="app-bg-10 app-border-r4"
             (click)="_onDeleteFile($event, index)">
    </app-svg>
  </div>
</div>

<app-uploader *ngIf="!disabled"
              [multiple]="multiple"
              [accept]="accept"
              [format]="format"
              (changeData)="_onChangeData($event)"
              style=" margin-top: 8px;"
              [style]="isNewDesign ? 'margin-bottom: 50px' : null">
</app-uploader>
