import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getUniqOrCommonArrayItems',
})
export class GetUniqOrCommonArrayItemsPipe<T extends { id?: string }> implements PipeTransform {
  public transform(items: T[], currentItems: T[], uniq: boolean = true): T[] {
    if (!items?.length) {
      return [];
    }

    if (!currentItems?.length) {
      return items;
    }

    const set = new Set(currentItems.filter((p) => !!p?.id).map((p) => p.id));

    return items.filter((p) => (uniq ? !set.has(p.id) : set.has(p.id)));
  }
}
