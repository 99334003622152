import { Pipe, PipeTransform } from '@angular/core';
import get from 'lodash-es/get';
import { KeysOf } from '@shared/base/types';

@Pipe({
  name: 'findInArray',
})
export class FindInArrayPipe implements PipeTransform {
  public transform<T>(array: T[], field: KeysOf<T>, conditionFn?: (item: T) => boolean): boolean {
    return !!array?.find((item) => {
      const value = get(item, field);
      if (conditionFn) {
        return conditionFn(value);
      } else {
        return !!value;
      }
    });
  }
}
