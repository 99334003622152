import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DisableControlDirective } from './disable-control.directive';
import { ResetControlDirective } from './reset-control.directive';
import { ValidateControlDirective } from './validate-control.directive';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  declarations: [DisableControlDirective, ResetControlDirective, ValidateControlDirective],
  exports: [DisableControlDirective, ResetControlDirective, ValidateControlDirective],
})
export class FormDirectivesModule {}
