import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SettingsState } from '@shared/states/settings.state';
import { TwelveDataInputData } from 'app/widgets/twelve-data-widgets/tvelve-data.types';

export enum TwelveDataEntityTypeEnum {
  Quote = 'QUOTE',
  Dividends = 'DIVIDENDS',
  Recommendations = 'RECOMMENDATIONS',
  Earnings = 'EARNINGS',
  EarningEstimate = 'EARNING_ESTIMATE',
  RevenueEstimate = 'REVENUE_ESTIMATE',
  EpsTrend = 'EPS_TREND',
  DirectHolders = 'DIRECT_HOLDERS',
  FundHolders = 'FUND_HOLDERS',
  CashFlow = 'CASH_FLOW',
  IncomeStatement = 'INCOME_STATEMENT',
  BalanceSheet = 'BALANCE_SHEET',
  CashFlowQuarterly = 'CASH_FLOW_QUARTERLY',
  IncomeStatementQuarterly = 'INCOME_STATEMENT_QUARTERLY',
  BalanceSheetQuarterly = 'BALANCE_SHEET_QUARTERLY',
  PriceTarget = 'PRICE_TARGET',
  Statistics = 'STATISTICS',
}

@Injectable({
  providedIn: 'root',
})
export class TwelveDataService {
  protected readonly entryPoint = '/twelvedata';

  constructor(
    private settingsState: SettingsState,
    private http: HttpClient,
  ) {}

  public getData<T>(
    name: TwelveDataEntityTypeEnum,
    symbol: string,
    micCode: string = null,
    params: { [key: string]: string } = null,
  ): Observable<TwelveDataInputData<T>> {
    if (!(name && symbol)) {
      console.error('name and symbol are required');

      return of({ data: null, error: null });
    }

    return this.http
      .post<T>(`${this.settingsState.apiPath}${this.entryPoint}/indicators`, symbol, {
        params: { ...params, name: name, micCode: micCode },
      })
      .pipe(
        map((result) => {
          return { data: result, error: null };
        }),
        catchError((err: HttpErrorResponse) => of({ data: null, error: err })),
      );
  }

  public getInstrumentLogo(symbol: string, micCode: string): Observable<string> {
    if (!symbol) {
      console.error('symbol is required');

      return of(null);
    }

    return this.http.request('POST', `${this.settingsState.apiPath}${this.entryPoint}/logo`, {
      body: symbol,
      responseType: 'text',
      params: {
        micCode: micCode,
      },
    });
  }
}
