<ng-container *ngIf="validationErrorDef; else defaultErrors">
  <div *ngLet="errors | keyvalue as _errors"
       class="app-flex-column w-100 b4-r">
    <div *ngFor="let error of _errors; let isLastError = last;"
         class="app-flex-row w-100"
         [class.app-border-bottom-10]="!isLastError"
         style="padding: 8px 0;">
      <ng-container *ngTemplateOutlet="validationErrorDef.template; context: { $implicit: error };"></ng-container>
    </div>
  </div>
</ng-container>

<ng-template #defaultErrors>
  <div *ngLet="errors | keyvalue as _errors"
       class="app-flex-column">
    <div *ngFor="let error of _errors; let isLastError = last;"
         class="app-flex-row w-100"
         [class.app-border-bottom-10]="!isLastError"
         style="padding: 8px 0;">
      <app-validation-error [error]="error"></app-validation-error>
    </div>
  </div>
</ng-template>