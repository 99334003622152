import { Directive, TemplateRef } from '@angular/core';
import { ValidationError } from './validation-error.types';

export interface ValidationErrorDefContext {
  $implicit: ValidationError;
}

@Directive({
  selector: '[appValidationErrorDef]',
})
export class ValidationErrorDefDirective {
  constructor(public template: TemplateRef<ValidationErrorDefContext>) {}

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  static ngTemplateContextGuard(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dir: ValidationErrorDefDirective,
    ctx: unknown,
  ): ctx is ValidationErrorDefContext {
    return true;
  }
}
