import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, interval } from 'rxjs';
import { finalize, map, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { BaseObject } from '@shared/base/base-object';

export const SESSION_EXPIRATION_COUNTDOWN_SEC = 20;

@Component({
  selector: 'app-session-expiration',
  templateUrl: './session-expiration.component.html',
  styleUrls: ['./session-expiration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionExpirationComponent extends BaseObject implements OnInit {
  public secondsLeft = SESSION_EXPIRATION_COUNTDOWN_SEC;
  public secondsLeft$: BehaviorSubject<number>;
  public progressLeft$: BehaviorSubject<number>;

  constructor(private dialogRef: MatDialogRef<SessionExpirationComponent, boolean>) {
    super();

    this.secondsLeft$ = new BehaviorSubject<number>(this.secondsLeft);
    this.progressLeft$ = new BehaviorSubject<number>(100);
  }

  public ngOnInit(): void {
    interval(1000)
      .pipe(
        takeWhile(() => this.secondsLeft > 0),
        tap(() => (this.secondsLeft -= 1)),
        map(() => this.secondsLeft),
        finalize(() => {
          if (this.secondsLeft === 0) {
            this.dialogRef.close(false);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((secondsLeft) => {
        this.secondsLeft$.next(secondsLeft);
        this.progressLeft$.next((100 * secondsLeft) / SESSION_EXPIRATION_COUNTDOWN_SEC);
      });
  }

  public _onContinueClick(): void {
    this.dialogRef.close(true);
  }
}
