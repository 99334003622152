import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClippedTextTooltipComponent } from './clipped-text-tooltip/clipped-text-tooltip.component';
import { TooltipDirective } from './tooltip/tooltip.directive';

@NgModule({
  imports: [CommonModule, MatTooltipModule, PortalModule],
  declarations: [ClippedTextTooltipComponent, TooltipDirective],
  exports: [ClippedTextTooltipComponent, TooltipDirective],
})
export class TooltipModule {}
