import { Directive, EventEmitter, OnDestroy, Output } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngDestroy]',
})
export class NgDestroyDirective implements OnDestroy {
  @Output() public ngDestroy = new EventEmitter<void>();

  public ngOnDestroy(): void {
    this.ngDestroy.next();
  }
}
