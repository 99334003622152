import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseObject } from '@shared/base/base-object';
import {
  $darkPalette,
  $lightPalette,
  $lwamPalette,
  $qsfPalette,
} from '@shared/constants/generated-theme-colors/generated-theme-colors';
import { ColorsMap } from '@shared/constants/generated-theme-colors/generated-types';
import { LocalStorageConstants } from '@shared/constants/local-storage-constants';

const ThemePrefix = 'app-theme';

export enum Theme {
  Dark = 'app-theme-dark',
  Light = 'app-theme-light',
  Lwam = 'app-theme-lwam',
  Qsf = 'app-theme-qsf',
}

@Injectable({
  providedIn: 'root',
})
export class ThemeState extends BaseObject {
  public readonly fontFamily = 'PP Object Sans'; // or 'Roboto, sans-serif'
  public readonly theme$: BehaviorSubject<Theme>;
  public readonly colors$: BehaviorSubject<ColorsMap>;

  private readonly initialTheme: Theme = Theme.Dark;
  public readonly themeColors: Record<Theme, ColorsMap> = {
    [Theme.Dark]: $darkPalette,
    [Theme.Light]: $lightPalette,
    [Theme.Lwam]: $lwamPalette,
    [Theme.Qsf]: $qsfPalette,
  };

  private r: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2,
  ) {
    super();

    this.r = this.rendererFactory.createRenderer(null, null);

    this.colors$ = new BehaviorSubject<ColorsMap>(this.themeColors[this.initialTheme]);
    this.theme$ = new BehaviorSubject<Theme>(this.initialTheme);
  }

  public init(): void {
    const localStorageTheme = localStorage.getItem(LocalStorageConstants.CurrentTheme);
    const findTheme = Object.values(Theme).find((theme) => theme === localStorageTheme);

    this.setTheme(findTheme || this.initialTheme);
  }

  public setTheme(theme: Theme): void {
    localStorage.setItem(LocalStorageConstants.CurrentTheme, theme);
    this.theme$.next(theme);

    this.colors$.next(this.themeColors[theme]);
    this.clearThemeClass();
    this.setThemeClass(theme);
  }

  private clearThemeClass(): void {
    const className = this.document
      .querySelector('html')
      .classList.value.split(' ')
      .find((className) => className.includes(ThemePrefix));

    this.r.removeClass(this.document.querySelector('html'), className);
  }

  private setThemeClass(theme: Theme): void {
    this.r.addClass(this.document.querySelector('html'), theme);
  }
}
