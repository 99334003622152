import { Pipe, PipeTransform } from '@angular/core';
import { DateFormats, DateHelper } from '@shared/helpers/date-helper.service';
import { AppInjector } from 'app/config/app-injector';

@Pipe({
  name: 'appTime',
})
export class TimePipe implements PipeTransform {
  public transform(
    value: Date | string,
    hideEmptyTime: boolean = false,
    timeFormat: string = DateFormats.Time,
  ): string {
    const dateHelper = AppInjector.Injector.get(DateHelper);
    const date = dateHelper.parse(value);

    if (!date) {
      return null;
    }

    if (hideEmptyTime) {
      if (!date.getHours() && !date.getMinutes() && !date.getSeconds()) {
        return null;
      } else {
        return dateHelper.format(date, timeFormat);
      }
    } else {
      return dateHelper.format(date, timeFormat);
    }
  }
}
