/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountConciseDTO } from './account-concise-dto';
import { MandateFeeDTO } from './mandate-fee-dto';
import { MandateAuthorizedPersonDTO } from './mandate-authorized-person-dto';

export interface MandateDTO {
  account: AccountConciseDTO;
  additionalCriteria?: MandateDTO.AdditionalCriteriaEnum;
  agreementNumber?: string;
  agreementStartDate?: string;
  authorizedPersons?: Array<MandateAuthorizedPersonDTO>;
  clientType?: MandateDTO.ClientTypeEnum;
  companyId: string;
  complexInstrumentIndicator?: boolean;
  createdAt?: string;
  createdBy?: string;
  creditAmount?: number;
  creditCcy?: string;
  creditLimit?: MandateDTO.CreditLimitEnum;
  creditRisk?: number;
  distributionStrategy?: MandateDTO.DistributionStrategyEnum;
  entities?: { [key: string]: string };
  fees?: Array<MandateFeeDTO>;
  financialSituation?: MandateDTO.FinancialSituationEnum;
  highWaterMarkDate?: string;
  highWaterMarkValue?: number;
  id?: string;
  investmentObjective?: MandateDTO.InvestmentObjectiveEnum;
  investmentTenor?: MandateDTO.InvestmentTenorEnum;
  knowledgeAndExperience?: MandateDTO.KnowledgeAndExperienceEnum;
  name: string;
  orderApprovalRequired?: boolean;
  orderPdfRequired?: boolean;
  riskTolerance?: MandateDTO.RiskToleranceEnum;
  spTrade?: boolean;
  spTradeDate?: string;
  status: MandateDTO.StatusEnum;
  terminationDate?: string;
  type: MandateDTO.TypeEnum;
  updatedAt?: string;
  updatedBy?: string;
  version?: number;
}
export namespace MandateDTO {
  export type AdditionalCriteriaEnum =
    | 'DESIGNED_FOR_RETIREMENT_PROVISION'
    | 'FINANCING_LEVERAGE_MARGIN_TRADING_REPOS'
    | 'HEDGING'
    | 'TO_ACHIEVE_TAX_EFFICIENCY_BASED_ON_COUNTRY';
  export const AdditionalCriteriaEnum = {
    DesignedForRetirementProvision: 'DESIGNED_FOR_RETIREMENT_PROVISION' as AdditionalCriteriaEnum,
    FinancingLeverageMarginTradingRepos:
      'FINANCING_LEVERAGE_MARGIN_TRADING_REPOS' as AdditionalCriteriaEnum,
    Hedging: 'HEDGING' as AdditionalCriteriaEnum,
    ToAchieveTaxEfficiencyBasedOnCountry:
      'TO_ACHIEVE_TAX_EFFICIENCY_BASED_ON_COUNTRY' as AdditionalCriteriaEnum,
  };
  export type ClientTypeEnum =
    | 'ELIGIBLE_COUNTERPARTY'
    | 'PER_REQUEST_PROFESSIONAL'
    | 'PER_SE_PROFESSIONAL'
    | 'RETAIL';
  export const ClientTypeEnum = {
    EligibleCounterparty: 'ELIGIBLE_COUNTERPARTY' as ClientTypeEnum,
    PerRequestProfessional: 'PER_REQUEST_PROFESSIONAL' as ClientTypeEnum,
    PerSeProfessional: 'PER_SE_PROFESSIONAL' as ClientTypeEnum,
    Retail: 'RETAIL' as ClientTypeEnum,
  };
  export type CreditLimitEnum = 'FIXED_AMOUNT' | 'PERCENTAGE_OF_AUM' | 'UNLIMITED';
  export const CreditLimitEnum = {
    FixedAmount: 'FIXED_AMOUNT' as CreditLimitEnum,
    PercentageOfAum: 'PERCENTAGE_OF_AUM' as CreditLimitEnum,
    Unlimited: 'UNLIMITED' as CreditLimitEnum,
  };
  export type DistributionStrategyEnum =
    | 'EXECUTION_ONLY'
    | 'INVESTMENT_ADVISE'
    | 'PORTFOLIO_MANAGEMENT'
    | 'SELF_DIRECTED';
  export const DistributionStrategyEnum = {
    ExecutionOnly: 'EXECUTION_ONLY' as DistributionStrategyEnum,
    InvestmentAdvise: 'INVESTMENT_ADVISE' as DistributionStrategyEnum,
    PortfolioManagement: 'PORTFOLIO_MANAGEMENT' as DistributionStrategyEnum,
    SelfDirected: 'SELF_DIRECTED' as DistributionStrategyEnum,
  };
  export type FinancialSituationEnum =
    | 'NO_TOLERANCE_FOR_LOSS'
    | 'TOLERATE_LIMITED_LOSS'
    | 'TOLERATE_LOSSES_EXCEEDING_INVESTMENT'
    | 'TOLERATE_LOSS_ENTIRE_INVESTMENT';
  export const FinancialSituationEnum = {
    NoToleranceForLoss: 'NO_TOLERANCE_FOR_LOSS' as FinancialSituationEnum,
    TolerateLimitedLoss: 'TOLERATE_LIMITED_LOSS' as FinancialSituationEnum,
    TolerateLossesExceedingInvestment:
      'TOLERATE_LOSSES_EXCEEDING_INVESTMENT' as FinancialSituationEnum,
    TolerateLossEntireInvestment: 'TOLERATE_LOSS_ENTIRE_INVESTMENT' as FinancialSituationEnum,
  };
  export type InvestmentObjectiveEnum = 'CAPITAL_GROWTH' | 'CAPITAL_PRESERVATION' | 'INCOME';
  export const InvestmentObjectiveEnum = {
    CapitalGrowth: 'CAPITAL_GROWTH' as InvestmentObjectiveEnum,
    CapitalPreservation: 'CAPITAL_PRESERVATION' as InvestmentObjectiveEnum,
    Income: 'INCOME' as InvestmentObjectiveEnum,
  };
  export type InvestmentTenorEnum = 'LONG' | 'MEDIUM' | 'SHORT';
  export const InvestmentTenorEnum = {
    Long: 'LONG' as InvestmentTenorEnum,
    Medium: 'MEDIUM' as InvestmentTenorEnum,
    Short: 'SHORT' as InvestmentTenorEnum,
  };
  export type KnowledgeAndExperienceEnum =
    | 'ADVANCED_INVESTOR'
    | 'BASIC_INVESTOR'
    | 'INFORMED_INVESTOR';
  export const KnowledgeAndExperienceEnum = {
    AdvancedInvestor: 'ADVANCED_INVESTOR' as KnowledgeAndExperienceEnum,
    BasicInvestor: 'BASIC_INVESTOR' as KnowledgeAndExperienceEnum,
    InformedInvestor: 'INFORMED_INVESTOR' as KnowledgeAndExperienceEnum,
  };
  export type RiskToleranceEnum = 'HIGH_RISK' | 'LOW_RISK' | 'MEDIUM_RISK';
  export const RiskToleranceEnum = {
    HighRisk: 'HIGH_RISK' as RiskToleranceEnum,
    LowRisk: 'LOW_RISK' as RiskToleranceEnum,
    MediumRisk: 'MEDIUM_RISK' as RiskToleranceEnum,
  };
  export type StatusEnum = 'ACTIVATED' | 'DRAFT' | 'IN_APPROVAL_PROCESS' | 'TERMINATED';
  export const StatusEnum = {
    Activated: 'ACTIVATED' as StatusEnum,
    Draft: 'DRAFT' as StatusEnum,
    InApprovalProcess: 'IN_APPROVAL_PROCESS' as StatusEnum,
    Terminated: 'TERMINATED' as StatusEnum,
  };
  export type TypeEnum =
    | 'ADVISORY_MANDATE'
    | 'COMPANY_ADMINISTRATION_AGREEMENT'
    | 'CONSULTANCY_SERVICES_AGREEMENT'
    | 'DISCRETIONARY_MANDATE'
    | 'EXECUTION_ONLY_MANDATE'
    | 'EXTERNAL_INVESTMENT_COMPANY_AGREEMENT'
    | 'INTERNAL_AGREEMENT'
    | 'INVESTMENT_MANAGEMENT_AGREEMENT'
    | 'PORTFOLIO_MANAGEMENT_STRATEGY'
    | 'PRIVATE_ASSET'
    | 'SERVICE_AGREEMENT'
    | 'TITLE_TRANSFER_COLLATERAL_ARRANGEMENT';
  export const TypeEnum = {
    AdvisoryMandate: 'ADVISORY_MANDATE' as TypeEnum,
    CompanyAdministrationAgreement: 'COMPANY_ADMINISTRATION_AGREEMENT' as TypeEnum,
    ConsultancyServicesAgreement: 'CONSULTANCY_SERVICES_AGREEMENT' as TypeEnum,
    DiscretionaryMandate: 'DISCRETIONARY_MANDATE' as TypeEnum,
    ExecutionOnlyMandate: 'EXECUTION_ONLY_MANDATE' as TypeEnum,
    ExternalInvestmentCompanyAgreement: 'EXTERNAL_INVESTMENT_COMPANY_AGREEMENT' as TypeEnum,
    InternalAgreement: 'INTERNAL_AGREEMENT' as TypeEnum,
    InvestmentManagementAgreement: 'INVESTMENT_MANAGEMENT_AGREEMENT' as TypeEnum,
    PortfolioManagementStrategy: 'PORTFOLIO_MANAGEMENT_STRATEGY' as TypeEnum,
    PrivateAsset: 'PRIVATE_ASSET' as TypeEnum,
    ServiceAgreement: 'SERVICE_AGREEMENT' as TypeEnum,
    TitleTransferCollateralArrangement: 'TITLE_TRANSFER_COLLATERAL_ARRANGEMENT' as TypeEnum,
  };
}
