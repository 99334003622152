<div class="app-datepicker__body">
  <div class="app-datepicker__side app-border-right-10">
    <div *ngIf="canSetTime && page === DatepickerPage.TimeEditor"
         class="app-datepicker__backdrop app-bg-backdrop"
         @contentAnimation>
    </div>

    <mat-button-toggle-group class="--snack"
                             [formControl]="toggleControl">
      <span class="app-datepicker__snacks-group b5-r app-text-color-75">
        Popular
      </span>
      <ng-container *ngFor="let dateToggle of dateToggles">
        <mat-button-toggle *ngIf="dateToggle.value | canShowDateToggle:minDate:maxDate"
                           [value]="dateToggle.value">
          {{ dateToggle.name }}
        </mat-button-toggle>
      </ng-container>
    </mat-button-toggle-group>

    <ng-container *ngIf="canSetTime">
      <div *ngIf="selected"
           class="app-datepicker__time-card app-bg-block-0">
        <span class="b5-r app-text-color-50"
              style="padding-bottom: 8px;">
          Time
        </span>

        <span class="b5-r"
              style="padding-bottom: 8px;">
          {{ timeControl | getChanges | async | appClone | appTime }}
        </span>

        <app-button type="secondary"
                    (click)="_onEditTimeClick()">
          Edit Time
        </app-button>
      </div>
    </ng-container>
  </div>

  <ng-container [ngSwitch]="page">
    <ng-container *ngSwitchCase="DatepickerPage.Calendar">
      <div class="app-datepicker__page --without-header"
           @contentAnimation>
        <div class="app-datepicker__page__body">
        </div>

        <div class="app-datepicker__page__footer">
          <app-button type="secondary"
                      (click)="_onCancelClick()"
                      class="m-r-8">
            {{ 'common.cancel'|translate }}
          </app-button>

          <app-button (click)="_onApplyClick()">
            {{ 'common.apply'|translate }}
          </app-button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="DatepickerPage.TimeEditor">
      <div class="app-datepicker__page app-bg-main"
           style="z-index: 1;"
           @contentAnimation>
        <div class="app-datepicker__page__header app-border-bottom-10">
          <span class="b5-r app-text-color-75"
                style="padding-bottom: 4px;">
            Date
          </span>

          <span class="h4-b">{{ selected | appDate }}</span>
        </div>

        <div class="app-datepicker__page__body">
          <app-timepicker [formControl]="timeControl"></app-timepicker>
        </div>

        <div class="app-datepicker__page__footer">
          <app-button type="secondary"
                      class="m-r-8"
                      (click)="_onResetTimeClick()">
            {{ 'common.reset'|translate }}
          </app-button>

          <app-button (click)="_onApplyTimeClick()">
            {{ 'common.apply'|translate }}
          </app-button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
