<app-svg *ngIf="dialog.showBackButton && !dialog.dialogOpenInPage"
         [icon]="'ArrowShortLeft_16'"
         class="app-bg-10 app-border-r4 m-r-8"
         style="width: 20px; height: 18px;"
         (click)="_onCloseClick()">
</app-svg>

<ng-content></ng-content>

<div class="app-dialog-header__tools">
  <mat-slide-toggle *ngIf="dialog._showEditSwitch$|async"
                    class="--outline m-r-8"
                    [checked]="dialog.editable$|async"
                    (change)="dialog._onEditSwitchChange($event.checked)">
    {{ 'common.edit'|translate }}
  </mat-slide-toggle>

  <ng-container *ngIf="dialog.showViewSwitch">
    <div class="app-dialog-header__view-switch">
      <div class="app-dialog-header__view-switch__item"
           [class.app-dialog-header__view-switch__item--selected]="(currentViewType$|async) === DialogViewType.full">
        <app-svg [icon]="'FullWindow'"
                 [disabledHover]="true"
                 style="cursor: pointer; width: 30px; height: 24px;"
                 matTooltip="toggle full window view"
                 matTooltipShowDelay="300"
                 (click)="_onViewSwitchClick(DialogViewType.full)">
        </app-svg>
      </div>

      <div class="app-dialog-header__view-switch__item"
           [class.app-dialog-header__view-switch__item--selected]="(currentViewType$|async) === DialogViewType.side">
        <app-svg [icon]="dialogOpenInPage ? 'MiddleWindow' : 'SideWindow'"
                 [disabledHover]="true"
                 style="cursor: pointer; width: 30px; height: 24px;"
                 matTooltip="toggle short window view"
                 matTooltipShowDelay="300"
                 (click)="_onViewSwitchClick(DialogViewType.side)">
        </app-svg>
      </div>
    </div>
  </ng-container>

  <span *ngIf="dialog.canOpenInPage && ((dialog._showEditSwitch$|async) || dialog.showViewSwitch)"
        class="app-vertical-divider"
        style="margin: 0px 9px;">
  </span>

  <ng-container *ngIf="dialog.canOpenInPage">
    <app-svg [icon]="'SettingsHorizontalOutline'"
             [disabledHover]="true"
             style="cursor: pointer;"
             matTooltip="window settings"
             matTooltipShowDelay="300"
             [matMenuTriggerFor]="menu">
    </app-svg>

    <mat-menu xPosition="before"
              #menu="matMenu">
      <button mat-menu-item
              (click)="_onShareClick($event)">
        <app-icon-button [icon]="'Share_24'"
                         position="prefix"
                         style="width: 100%;">
          Copy link
        </app-icon-button>
      </button>

      <button mat-menu-item
              (click)="_onOpenInPageClick($event)">
        <app-icon-button [icon]="'NewWindow'"
                         position="prefix"
                         style="width: 100%;">
          Open in New Tab
        </app-icon-button>
      </button>

      <button *ngFor="let item of additionalMenuItems"
              mat-menu-item
              (click)="item.onClick()">
        <app-icon-button [icon]="item.icon"
                         position="prefix"
                         style="width: 100%;">
          {{ item.name }}
        </app-icon-button>
      </button>
    </mat-menu>
  </ng-container>
</div>

<div *ngIf="dialog._showLoader$|async"
     class="app-widget-spinner"
     [style.top]="height">
</div>
