/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RiskReportRequestDTO {
  baseCcy: string;
  cashAtBank?: { [key: string]: number };
  companyId: string;
  currentLiabilities: number;
  date: string;
  forvalue: number;
  fxSource: RiskReportRequestDTO.FxSourceEnum;
  intangibleAssets?: number;
  investmentCompensation?: number;
  manuallyInputInstruments?: { [key: string]: number };
  nonCurrentAssets: number;
  nonCurrentLiabilities: number;
  operatingProfit: number;
  otherCurrentAssets?: number;
  pmc: RiskReportRequestDTO.PmcEnum;
  receivables: number;
  typeOfCapital: RiskReportRequestDTO.TypeOfCapitalEnum;
}
export namespace RiskReportRequestDTO {
  export type FxSourceEnum = 'BBG' | 'CBRF' | 'ECB';
  export const FxSourceEnum = {
    Bbg: 'BBG' as FxSourceEnum,
    Cbrf: 'CBRF' as FxSourceEnum,
    Ecb: 'ECB' as FxSourceEnum,
  };
  export type PmcEnum = 'HIGH' | 'LOW' | 'MEDIUM';
  export const PmcEnum = {
    High: 'HIGH' as PmcEnum,
    Low: 'LOW' as PmcEnum,
    Medium: 'MEDIUM' as PmcEnum,
  };
  export type TypeOfCapitalEnum = 'AT1' | 'CET1' | 'T2';
  export const TypeOfCapitalEnum = {
    At1: 'AT1' as TypeOfCapitalEnum,
    Cet1: 'CET1' as TypeOfCapitalEnum,
    T2: 'T2' as TypeOfCapitalEnum,
  };
}
