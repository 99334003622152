import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { DirectivesModule } from '@shared/directives/directives.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { ErrorModule } from '@ui/error/error.module';
import { InputStubLabelDirective } from '@ui/input/input-stub/input-stub-label.directive';
import { SvgModule } from '@ui/svg/svg.module';
import { TooltipModule } from '@ui/tooltip/tooltip.module';
import { CustomDatepickerComponent } from './datepicker/custom-datepicker.component';
import { CustomDaterangePickerComponent } from './datepicker/custom-daterange-picker.component';
import { DatepickerCalendarHeaderComponent } from './datepicker/datepicker-calendar-header.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DaterangePickerComponent } from './datepicker/daterange-picker.component';
import { InputAutofocusDirective } from './input-autofocus.directive';
import { InputDateCustomTransformDirective } from './input-date-custom-transform.directive';
import { InputErrorDefDirective } from './input-error-def.directive';
import { InputPrefixDirective } from './input-prefix.directive';
import { InputStubValueDirective } from './input-stub/input-stub-value.directive';
import { InputStubComponent } from './input-stub/input-stub.component';
import { InputSuffixDirective } from './input-suffix.directive';
import { InputComponent } from './input.component';
import { CanShowDateTogglePipe } from './pipes/can-show-date-toggle.pipe';
import { CanShowRangeTogglePipe } from './pipes/can-show-range-toggle.pipe';
import { GetCustomDatepickerControlPipe } from './pipes/get-custom-datepicker-control.pipe';
import { GetCustomDaterangePickerControlPipe } from './pipes/get-custom-daterange-picker-control.pipe';
import { GetDateByDaysMovePipe } from './pipes/get-date-by-days-move.pipe';
import { TimepickerComponent } from './timepicker/timepicker.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    OverlayModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    ButtonsModule,
    TranslateModule,
    TooltipModule,
    NgxMaskDirective,
    NgxMaskPipe,
    SvgModule,
    PipesModule,
    ErrorModule,
  ],
  declarations: [
    InputComponent,
    InputPrefixDirective,
    InputSuffixDirective,
    InputStubComponent,
    DatepickerComponent,
    TimepickerComponent,
    DatepickerCalendarHeaderComponent,
    DaterangePickerComponent,
    GetDateByDaysMovePipe,
    CustomDatepickerComponent,
    InputDateCustomTransformDirective,
    InputAutofocusDirective,
    CustomDaterangePickerComponent,
    CanShowRangeTogglePipe,
    CanShowDateTogglePipe,
    InputStubValueDirective,
    InputStubLabelDirective,
    InputErrorDefDirective,
    GetCustomDatepickerControlPipe,
    GetCustomDaterangePickerControlPipe,
  ],
  exports: [
    InputComponent,
    InputPrefixDirective,
    InputSuffixDirective,
    InputStubComponent,
    InputStubValueDirective,
    InputStubLabelDirective,
    InputErrorDefDirective,
  ],
  providers: [provideNgxMask()],
})
export class InputModule {}
