export enum UserSettingsDialogTabs {
  Personalisation = 1,
  PasswordSetting,
  Preferences,
  Requests,
  Notifications,
}

export interface UserSettingsDialogAction {
  tab: UserSettingsDialogTabs;
  type: 'save';
}
