import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IfAuthoritiesDirective } from '@shared/directives/if-authorities.directive';
import { SvgModule } from '@ui/svg/svg.module';
import { BlinkNumberComponent } from './blink-number.component';
import { NgDestroyDirective } from './destroy.directive';
import { ExternalLinkDirective } from './external-link.directive';
import { HideDirective } from './hide.directive';
import { HighlightNumberDirective } from './highlight-number.directive';
import { NgInitDirective } from './init.directive';
import { LetDirective } from './let.directive';
import { ScrollMoveDirective } from './scroll-move.directive';
import { SizeDirective } from './size.directive';
import { TemplateContextTypeDirective } from './template-context-type.directive';
import { ValueBlinkDirective } from './value-blink.directive';
import { VarDirective } from './var.directive';

@NgModule({
  declarations: [
    IfAuthoritiesDirective,
    HighlightNumberDirective,
    ExternalLinkDirective,
    LetDirective,
    VarDirective,
    SizeDirective,
    TemplateContextTypeDirective,
    ScrollMoveDirective,
    HideDirective,
    ValueBlinkDirective,
    BlinkNumberComponent,
    NgInitDirective,
    NgDestroyDirective,
  ],
  exports: [
    IfAuthoritiesDirective,
    HighlightNumberDirective,
    ExternalLinkDirective,
    LetDirective,
    VarDirective,
    SizeDirective,
    TemplateContextTypeDirective,
    ScrollMoveDirective,
    HideDirective,
    ValueBlinkDirective,
    BlinkNumberComponent,
    NgInitDirective,
    NgDestroyDirective,
  ],
  imports: [CommonModule, SvgModule],
})
export class DirectivesModule {}
