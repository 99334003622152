import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseObject } from '@shared/base/base-object';
import { DialogService } from '@ui/dialog/dialog.service';
import { FeedbackComponent } from 'app/dialogs/feedback/feedback.component';
import { UserSettingsDialogFactory } from '../../../dialogs/user-settings/user-settings-dialog.factory';
import { UserSettingsDialogTabs } from '../../../dialogs/user-settings/user-settings-dialog.types';

@Component({
  selector: 'app-fixed-controls',
  templateUrl: './fixed-controls.component.html',
  styleUrls: ['./fixed-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FixedControlsComponent extends BaseObject {
  public feedbackButtonState$ = new BehaviorSubject<'closed' | 'opened'>('closed');

  constructor(private dialogService: DialogService) {
    super();
  }

  public _onClick(): void {
    this.feedbackButtonState$.next('opened');

    this.dialogService
      .open<void, string>(FeedbackComponent, null, {
        width: '595px',
        height: 'auto',
        maxHeight: '80vh',
        autoFocus: false,
        disableClose: true,
        backdropClass: 'app-dialog-backdrop',
      })
      .closed$.subscribe((reason) => {
        this.feedbackButtonState$.next('closed');

        if (reason === 'requests') {
          this.dialogService.openLazy<{ activeTab: UserSettingsDialogTabs }, void>(
            { factory: UserSettingsDialogFactory },
            { activeTab: UserSettingsDialogTabs.Requests },
          );
        }
      });
  }
}
