import { Pipe, PipeTransform } from '@angular/core';
import { isNotNaN, isNotNull } from '@shared/base/core';
import { GetThousandValueWithSuffixPipe } from '@shared/pipes/get-thousand-value-with-suffix.pipe';
import { NumberPipe } from '@shared/pipes/number.pipe';

@Pipe({
  name: 'appGetTwelveDataValueWithSuffix',
})
export class GetTwelveDataValueWithSuffixPipe implements PipeTransform {
  private GET_THOUSAND_VALUE_WITH_SUFFIX_PIPE = new GetThousandValueWithSuffixPipe();
  private NUMBER_PIPE = new NumberPipe();

  constructor() {}

  public transform(value: string | number, decimalCount: number = null): string {
    if (isNotNaN(Number(value)) && isNotNull(Number(value)) && isFinite(Number(value))) {
      const v = this.GET_THOUSAND_VALUE_WITH_SUFFIX_PIPE.transform(+value);
      return `${this.NUMBER_PIPE.transform(v.value, decimalCount)}${v.suffix}`;
    }

    return value.toString();
  }
}
