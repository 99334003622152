<app-dialog (closeClick)="_onClose()"
            [disableCloseByBackButton]="true"
            *ngLet="{
              prefix: descriptionPrefix$ | async,
              componentSelectOptions: componentSelectOptions$ | async,
              featuresSelectOptions: featuresSelectOptions$ | async} as obj"
            [canOpenInPage]="false"
            [showLoader]="loading$|async">
  <app-dialog-header>
    <span class="b2-r app-text-not-capitalize">Send Request</span>
  </app-dialog-header>

  <app-grid [gap]="'12px'"
            [gapType]="'vertical'"
            [cols]="2"
            [formGroup]="formGroup">
    <app-grid-tile [colspan]="2">
      <div class="app-flex-row">
        <mat-button-toggle-group class="--outline"
                                 formControlName="issueType">
          <mat-button-toggle [value]="IssueType.Bug">Report an Issue</mat-button-toggle>
          <mat-button-toggle [value]="IssueType.Enhancement">Suggest an Enhancement</mat-button-toggle>
          <mat-button-toggle [value]="IssueType.Request">Service Request</mat-button-toggle>
        </mat-button-toggle-group>
        <app-button [type]="'secondary'"
                    style="margin-left: 6px;"
                    (click)="_onClose('requests')">
          See My Requests
          <app-svg [icon]="'Forward_16'"></app-svg>
        </app-button>
      </div>
    </app-grid-tile>

    <app-grid-tile [colspan]="2">
      <app-input [label]="obj.prefix + ' Summary'"
                 [gap]="false"
                 formControlName="summary">
      </app-input>
    </app-grid-tile>

    <app-grid-tile selfPadding="0 6px 0 0"
                   [colspan]="1">
      <app-select label="Priority Level*"
                  [gap]="false"
                  [clearable]="false"
                  [options]="priorities"
                  [showSearch]="false"
                  formControlName="priority">
      </app-select>
    </app-grid-tile>

    <app-grid-tile selfPadding="0 0 0 6px"
                   [colspan]="1">
      <app-select label="Urgency Level*"
                  [gap]="false"
                  [clearable]="false"
                  [options]="urgencies"
                  [showSearch]="false"
                  formControlName="urgency">
      </app-select>
    </app-grid-tile>

    <app-grid-tile [colspan]="2"
                   *ngIf="formGroup.controls.issueType.value !== IssueType.Request">
      <app-select label="Component"
                  [gap]="false"
                  [options]="obj.componentSelectOptions"
                  [loading]="!obj.componentSelectOptions"
                  [clearable]="false"
                  bindName="name"
                  bindValue="value"
                  formControlName="component">
      </app-select>
    </app-grid-tile>

    <app-grid-tile [colspan]="2"
                   *ngIf="formGroup.controls.issueType.value !== IssueType.Request">
      <app-select label="Feature"
                  [gap]="false"
                  [options]="obj.featuresSelectOptions"
                  [appDisableControl]="!obj.featuresSelectOptions"
                  bindName="name"
                  bindValue="value"
                  formControlName="feature">
      </app-select>
    </app-grid-tile>

    <app-grid-tile [colspan]="2">
      <app-input
        [label]="formGroup.controls.issueType.value === IssueType.Bug ? 'Steps you took that led to this error' : obj.prefix + ' Description'"
        type="textarea"
        [gap]="false"
        formControlName="description">
        <app-validation-error *appInputError="let error"
                              [error]="error.key === 'minlength' ? 'Minimum 30 characters' : error">
        </app-validation-error>
      </app-input>
    </app-grid-tile>

    <app-grid-tile [colspan]="2">
      <app-input label="Expected Results"
                 type="textarea"
                 [gap]="false"
                 formControlName="expectedResult">
        <app-validation-error *appInputError="let error"
                              [error]="error.key === 'minlength' ? 'Minimum 15 characters' : error">
        </app-validation-error>
      </app-input>
    </app-grid-tile>

    <app-grid-tile [colspan]="2">
      <app-drag-and-drop-uploader *ngLet="files$|async as _files"
                                  [files]="_files"
                                  format="base64"
                                  [accept]="'.jpg,.jpeg,.png,.gif,.pdf,.hevc,.txt,.xls,.xlsx,.svg,.zip,.html,.mp4,.docx,.avi,.json,.log,.csv'"
                                  [multiple]="true"
                                  [errorMessage]="filesErrors$ | async"
                                  style="padding: 0;"
                                  (changeFiles)="files$.next($event)">
      </app-drag-and-drop-uploader>
    </app-grid-tile>
  </app-grid>

  <app-dialog-footer>
    <app-button [disabled]="loading$|async"
                (click)="_onSubmitClick()">
      {{ 'common.send'|translate }}
    </app-button>
  </app-dialog-footer>
</app-dialog>
