import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asDate',
})
export class AsDatePipe implements PipeTransform {
  public transform(date: unknown): Date {
    return date as Date;
  }
}
