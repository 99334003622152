import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, map, of, skip, switchMap, zip } from 'rxjs';
import { LogoService } from '@shared/api/logo.service';
import { BaseObject } from '@shared/base/base-object';
import { Theme, ThemeState } from './theme.state';

@Injectable({
  providedIn: 'root',
})
export class LogoState extends BaseObject {
  private favicon$ = new BehaviorSubject<string>(null);
  private loadedLoginLogo$ = new BehaviorSubject<string>(null);
  private loadedHeaderLogo$ = new BehaviorSubject<string>(null);

  private readonly LOGOS_CACHE = {
    favicon: '',
    loginLogo: {
      dark: '',
      light: '',
    },
    headerLogo: {
      dark: '',
      light: '',
    },
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private logoService: LogoService,
    private themeState: ThemeState,
  ) {
    super();

    this.listenToUpdateLogos();

    this.favicon$.subscribe((favicon) => {
      const faviconLink: HTMLLinkElement = this.document.querySelector('link[rel="icon"]');
      faviconLink.href = favicon || '/assets/img/logo/reluna/favicon.svg';
    });
  }

  public get loginLogo$(): Observable<string> {
    return this.loadedLoginLogo$.pipe(
      switchMap((loginLogo) => {
        if (loginLogo) {
          return of(loginLogo);
        } else {
          return this.themeState.theme$.pipe(
            filter((v) => !!v),
            map((theme) => {
              switch (theme) {
                case Theme.Dark:
                case Theme.Lwam:
                case Theme.Qsf:
                  return '/assets/img/logo/reluna/logo-dark.svg';

                case Theme.Light:
                  return '/assets/img/logo/reluna/logo-light.svg';

                default:
                  return '/assets/img/logo/reluna/logo-dark.svg';
              }
            }),
          );
        }
      }),
    );
  }

  public get headerLogo$(): Observable<string> {
    return this.loadedHeaderLogo$.pipe(
      switchMap((loadedHeaderLogo) => {
        if (loadedHeaderLogo) {
          return of(loadedHeaderLogo);
        } else {
          return this.themeState.theme$.pipe(
            filter((v) => !!v),
            map((theme) => {
              switch (theme) {
                case Theme.Dark:
                case Theme.Lwam:
                case Theme.Qsf:
                  return '/assets/img/logo/reluna/logo-dark.svg';

                case Theme.Light:
                  return '/assets/img/logo/reluna/logo-light.svg';

                default:
                  return '/assets/img/logo/reluna/logo-dark.svg';
              }
            }),
          );
        }
      }),
    );
  }

  public loadLogos(): Observable<void> {
    return zip([
      this.loadFaviconIfRequired(),

      this.themeState.theme$.pipe(
        filter((v) => !!v),
        switchMap((theme) => this.loadLoginLogoIfRequired(theme)),
      ),

      this.themeState.theme$.pipe(
        filter((v) => !!v),
        switchMap((theme) => this.loadHeaderLogoIfRequired(theme)),
      ),
    ]).pipe(map(() => null));
  }

  private listenToUpdateLogos(): void {
    this.themeState.theme$
      .pipe(
        skip(1),
        switchMap((theme) =>
          zip([this.loadLoginLogoIfRequired(theme), this.loadHeaderLogoIfRequired(theme)]),
        ),
      )
      .subscribe(() => {});
  }

  private loadFaviconIfRequired(): Observable<void> {
    if (!this.LOGOS_CACHE.favicon) {
      return this.logoService.get('FAVICON').pipe(
        map((result) => {
          this.LOGOS_CACHE.favicon = result?.data;
          this.favicon$.next(result?.data);
          return null;
        }),
      );
    } else {
      this.favicon$.next(this.LOGOS_CACHE.favicon);
      return of(null);
    }
  }

  private loadLoginLogoIfRequired(theme: Theme): Observable<void> {
    switch (theme) {
      case Theme.Dark:
      case Theme.Lwam:
      case Theme.Qsf:
        if (!this.LOGOS_CACHE.loginLogo.dark) {
          return this.logoService.get('LOGIN_DARK').pipe(
            map((result) => {
              this.LOGOS_CACHE.loginLogo.dark = result?.data;
              this.loadedLoginLogo$.next(this.LOGOS_CACHE.loginLogo.dark);
              return null;
            }),
          );
        } else {
          this.loadedLoginLogo$.next(this.LOGOS_CACHE.loginLogo.dark);
          return of(null);
        }

      case Theme.Light:
        if (!this.LOGOS_CACHE.loginLogo.light) {
          return this.logoService.get('LOGIN_LIGHT').pipe(
            map((result) => {
              this.LOGOS_CACHE.loginLogo.light = result?.data;
              this.loadedLoginLogo$.next(this.LOGOS_CACHE.loginLogo.light);
              return null;
            }),
          );
        } else {
          this.loadedLoginLogo$.next(this.LOGOS_CACHE.loginLogo.light);
          return of(null);
        }

      default:
        return of(null);
    }
  }

  private loadHeaderLogoIfRequired(theme: Theme): Observable<void> {
    switch (theme) {
      case Theme.Dark:
      case Theme.Lwam:
      case Theme.Qsf:
        if (!this.LOGOS_CACHE.headerLogo.dark) {
          return this.logoService.get('HEADER_DARK').pipe(
            map((result) => {
              this.LOGOS_CACHE.headerLogo.dark = result?.data;
              this.loadedHeaderLogo$.next(this.LOGOS_CACHE.headerLogo.dark);
              return null;
            }),
          );
        } else {
          this.loadedHeaderLogo$.next(this.LOGOS_CACHE.headerLogo.dark);
          return of(null);
        }

      case Theme.Light:
        if (!this.LOGOS_CACHE.headerLogo.light) {
          return this.logoService.get('HEADER_LIGHT').pipe(
            map((result) => {
              this.LOGOS_CACHE.headerLogo.light = result?.data;
              this.loadedHeaderLogo$.next(this.LOGOS_CACHE.headerLogo.light);
              return null;
            }),
          );
        } else {
          this.loadedHeaderLogo$.next(this.LOGOS_CACHE.headerLogo.light);
          return of(null);
        }

      default:
        return of(null);
    }
  }
}
