import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidationError, ValidationKeys } from './validation-error.types';

@Component({
  selector: 'app-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationErrorComponent {
  public readonly ValidationKeys = ValidationKeys;

  @Input({ required: true }) public error: ValidationError | string;
}
