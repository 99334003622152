import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { DefaultKeyValue } from '@shared/dto/gateway-secured/models';
import { SettingsState } from '@shared/states/settings.state';

@Injectable({
  providedIn: 'root',
})
export class FieldDictionaryService {
  constructor(
    private settingsState: SettingsState,
    private http: HttpClient,
  ) {}

  public getFieldDictionary(fieldName: string): Observable<DefaultKeyValue[]> {
    return this.http
      .get<DefaultKeyValue[]>(`${this.settingsState.apiPath}/field-dictionary/${fieldName}`)
      .pipe(catchError(() => of([])));
  }
}
