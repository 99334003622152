<div class="app-dialog-notification">
  <div class="app-dialog-notification__header">
    <app-svg class="app-dialog-notification__icon"
             [icon]="'StatusAttentionRectangle_24'"
             [disabledHover]="true"
             [subType]="data.subType">
    </app-svg>

    <span class="h4-sp">
      {{ data.title }}
    </span>

    <app-svg [icon]="'Close_24'"
             (click)="_onCloseClick()"
             class="app-dialog-notification__close">
    </app-svg>
  </div>

  <div *ngIf="data.description"
       class="app-dialog-notification__body">
    <span class="b3-r"
          style="padding-top: 5px;">
      {{ data.description }}
    </span>
  </div>

  <div class="app-dialog-notification__footer">
    <ng-container *ngIf="!data.footerTemplate">
      <app-button class="m-r-10"
                  (click)="_onConfirmClick()">
        {{ data.confirmBtnText || 'common.confirm' | translate }}
      </app-button>

      <app-button type="secondary"
                  (click)="_onCloseClick()">
        {{ data.closeBtnText || 'common.close' | translate }}
      </app-button>
    </ng-container>

    <ng-template *ngIf="data.footerTemplate"
                 [ngTemplateOutlet]="data.footerTemplate">
    </ng-template>
  </div>
</div>
