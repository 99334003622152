import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { LogotypeDTO } from '@shared/dto/accounts/models';
import { SettingsState } from '@shared/states/settings.state';

@Injectable({
  providedIn: 'root',
})
export class LogoService {
  constructor(
    private http: HttpClient,
    private settingsState: SettingsState,
  ) {}

  public get(type: LogotypeDTO['type']): Observable<LogotypeDTO> {
    return this.http
      .get<LogotypeDTO>(`${this.settingsState.apiPath}/logotype/by-type/${type}`)
      .pipe(catchError(() => of(null)));
  }

  public save(data: LogotypeDTO): Observable<LogotypeDTO> {
    return this.http.post<LogotypeDTO>(`${this.settingsState.apiPath}/logotype`, data);
  }

  public delete(type: LogotypeDTO['type']): Observable<boolean> {
    return this.http.delete(`${this.settingsState.apiPath}/logotype/${type}`).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }
}
