<ng-container *ngIf="data"
              @contentAnimation>
  <ng-container *ngIf="data.formatType === 'TEXT'; else showHtml">
    {{ data.text }}
  </ng-container>

  <ng-template #showHtml>
    <div class="app-flex-row app-site-info-outlet__text-container">
      <div class="text-container__expander" [style.height]="showExpander && !expanded ? '25px' : 'auto'"
           [innerHTML]="data.text | safeHtml">
      </div>

      <app-icon-button *ngIf="showExpander"
                       (click)="expanded = !expanded"
                       class="app-bg-main">
      <span class="c2-r app-text-color-50">
        {{ expanded ? ('common.hide'|translate) : ('common.show'|translate) }}
      </span>

        <app-svg [icon]="'ArrowShortDown_16'"
                 class="app-expander"
                 [class.app-expander--up]="expanded">
        </app-svg>
      </app-icon-button>
    </div>
  </ng-template>
</ng-container>
