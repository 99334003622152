import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { fromEvent, takeUntil } from 'rxjs';
import { BaseObject } from '@shared/base/base-object';
import { VersionService } from '@shared/helpers/version.service';
import { ThemeState } from '@shared/states/theme.state';
import { UserState } from '@shared/states/user.state';

// TODO вурнуть google analytics когда будет нужен
// declare let gtag: (...params: any[]) => any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends BaseObject {
  constructor(
    private router: Router,
    private versionService: VersionService,
    public userState: UserState,
    public themeState: ThemeState,
  ) {
    super();

    //this.listenToRouterEvents();
    this.versionService.start();

    this.updateClipboardTextOnCopy();
  }

  private updateClipboardTextOnCopy(): void {
    fromEvent(document, 'copy')
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: ClipboardEvent) => {
        const selection = document.getSelection();

        if (selection.type === 'Range') {
          event.preventDefault();
          const copyTextCorrected = selection.toString().trim();
          event.clipboardData.setData('text/plain', copyTextCorrected);
        }
      });
  }

  private listenToRouterEvents(): void {
    this.router.events.subscribe((event) => {
      //TODO вурнуть google analytics когда будет нужен
      if (event instanceof NavigationEnd) {
        // gtag('config', 'UA-167402363-1', {
        //   page_path: event.urlAfterRedirects,
        // });
      }
      // TODO добавить google analytics в index.html в <head>
      // <script async src="https://www.googletagmanager.com/gtag/js?id=G-SC89MNZVPV">
      // </script>
      // <script>
      // window.dataLayer = window.dataLayer || [];
      // function gtag() { dataLayer.push(arguments); }
      // gtag('js', new Date());
      // </script>
    });
  }
}
