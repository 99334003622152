import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { dropDownAnimation } from '@shared/animations/animations';
import { BaseObject } from '@shared/base/base-object';
import { isNotNull } from '@shared/base/core';
import { EntityAliases } from '@shared/constants/entity-aliases';
import { ScrollMoveDirective } from '@shared/directives/scroll-move.directive';
import { openEntityInPage } from '@shared/helpers/detail-page.helper';
import {
  DIALOG_CONFIG,
  DIALOG_DATA,
  DIALOG_OPEN_IN_PAGE,
  DialogConfig,
  DialogData,
} from '../dialog.types';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [dropDownAnimation],
  host: {
    class: 'app-dialog',
  },
})
export class DialogComponent extends BaseObject {
  @Input() public canOpenInPage: boolean = true;
  @Input() public showBackButton: boolean = true;
  @Input() public disableCloseByBackButton: boolean = false;
  @Input() public showViewSwitch: boolean = false;
  @Input() public openInPageEntityAliases: EntityAliases;
  @Input() public openInPageParams: Record<string, string>;
  @Input() public currentTab: number | string;
  @Input() public notificationTemplate: TemplateRef<unknown>;
  @Input() public notificationOpen: boolean;

  @Input() public set showEditSwitch(value: boolean) {
    this._showEditSwitch$.next(value);
  }

  @Input() public set showLoader(value: boolean) {
    this._showLoader$.next(value);
  }

  @HostBinding('class.app-dialog--opened-in-page')
  public get openedInPage(): boolean {
    return this.canOpenInPage ? !!this.dialogOpenInPage : false;
  }

  @HostBinding('style.height')
  public get height(): string {
    return this.dialogConfig?.height;
  }

  @HostBinding('style.min-height')
  public get minHeight(): string {
    return this.dialogConfig?.minHeight;
  }

  @HostBinding('style.max-height')
  public get maxHeight(): string {
    return this.dialogConfig?.maxHeight ? `calc(${this.dialogConfig.maxHeight} - 2px)` : null;
  }

  @Output() public readonly editChange = new EventEmitter<boolean>();
  @Output() public readonly closeClick = new EventEmitter<void>();

  public _showEditSwitch$ = new BehaviorSubject<boolean>(false);
  public _showLoader$ = new BehaviorSubject<boolean>(false);

  public get editable(): boolean {
    return this.dialogData.editable$.value;
  }

  public editable$: BehaviorSubject<boolean>;

  @ViewChild(ScrollMoveDirective, { static: true }) public bodyScrollMove: ScrollMoveDirective;

  constructor(
    @Optional()
    @Inject(DIALOG_OPEN_IN_PAGE)
    public dialogOpenInPage: boolean,

    @Inject(DIALOG_DATA)
    public dialogData: DialogData<{ id?: string }>,

    @Optional()
    @Inject(DIALOG_CONFIG)
    public dialogConfig?: DialogConfig,
  ) {
    super();

    this.editable$ = this.dialogData.editable$;
  }

  public _onEditSwitchChange(checked: boolean): void {
    this.editable$.next(checked);
    this.editChange.next(checked);
  }

  public openInPage(): void {
    if (!this.canOpenInPage) {
      throw new Error('dialog can not open in page');
    }

    const params: Record<string, string> = this.openInPageParams || {
      id: this.dialogData.data.id,
    };
    const fragment = isNotNull(this.currentTab) ? `tab=${this.currentTab}` : null;

    openEntityInPage(this.openInPageEntityAliases, params, fragment);
  }
}
