import { ChangeDetectionStrategy, Component, ContentChild, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationErrorDefDirective } from '../validation-error/validation-error-def.directive';

@Component({
  selector: 'app-validation-errors-tooltip',
  templateUrl: './validation-errors-tooltip.component.html',
  styleUrls: ['./validation-errors-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-border app-border-r4',
  },
})
export class ValidationErrorsTooltipComponent {
  @Input({ required: true }) public errors: FormControl['errors'];

  @ContentChild(ValidationErrorDefDirective)
  public validationErrorDef: ValidationErrorDefDirective;
}
