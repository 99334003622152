<div class="app-push-notification_container">
  <div class="app-push-notification_icon">
    <app-svg [icon]="'PushIconArrows'"></app-svg>
  </div>
  <div class="app-push-notification_body">
    <div class="app-push-notification_title h4-b">{{message.notification.title}}</div>
    <div class="app-push-notification_text b4-r">{{message.notification.body | cleanHtmlTags}}</div>
    <div class="b5-r">Now</div>
  </div>
  <div class="app-push-notification_actions">
    <div class="app-push-notification_close"
         (click)="onClose()">
      <app-svg [icon]="'Close_24'"></app-svg>
    </div>
    <div class="app-push-notification_action"
         (click)="onShow()">
      <span>{{ 'common.show'|translate }}</span>
      <app-svg [icon]="'Forward_24'"
               class="app-push-notification_action-icon">
      </app-svg>
    </div>
  </div>
</div>