import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  AccountCompanyDTO,
  AccountDTO,
  ActionDTO,
  AddressDTO,
  BankAccountDTO,
  ChangeRequestSettingsDTO,
  CustodianDTO,
  DefaultKeyValue,
  MandateAuthorizedPersonDTO,
  MandateDTO,
  MandateFeeDTO,
  ProcessDTO,
} from '@shared/dto/accounts/models';
import { PrivateAssetDocumentContentDTO } from '@shared/dto/alternative-assets/models';
import {
  ComponentDTO,
  CountryDTO,
  FieldMetaDTO,
  InstrumentDTO,
  JiraCreateIssueDTO,
  OrderTransactionTypeInfo,
} from '@shared/dto/gateway-secured/models';
import { NotificationTemplateDTO } from '@shared/dto/notification/notification-template-dto';
import {
  CompanyReportDTO,
  CustodianTinyDTO,
  FeeDTO,
  OrderDTO,
  PortfolioDTO,
  ReportDTO,
  TradeTypeDTO,
  TransactionDTO,
} from '@shared/dto/positions/models';
import { SettingsState } from '@shared/states/settings.state';
import { DictionaryItem } from '@shared/types/dictionary-item';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  constructor(
    private settingsState: SettingsState,
    private http: HttpClient,
  ) {}

  public getAccountOwnerTypes(): Observable<DictionaryItem<AccountDTO.AccountOwnerTypeEnum>[]> {
    return this.http
      .get<DictionaryItem<AccountDTO.AccountOwnerTypeEnum>[]>(
        `${this.settingsState.apiPath}/account-owner-types`,
      )
      .pipe(catchError(() => of([])));
  }

  public getAccountInstitutionalSectors(): Observable<DefaultKeyValue[]> {
    return this.http
      .get<DefaultKeyValue[]>(`${this.settingsState.apiPath}/account-institutional-sectors`)
      .pipe(catchError(() => of([])));
  }

  public getAccountStatuses(): Observable<DictionaryItem<AccountDTO.AccountStatusEnum>[]> {
    return this.http
      .get<DictionaryItem<AccountDTO.AccountStatusEnum>[]>(
        `${this.settingsState.apiPath}/account-statuses`,
      )
      .pipe(catchError(() => of([])));
  }

  public getBankAccountStatuses(): Observable<BankAccountDTO.StatusEnum[]> {
    return this.http
      .get<BankAccountDTO.StatusEnum[]>(`${this.settingsState.apiPath}/bank-account-statuses`)
      .pipe(catchError(() => of([])));
  }

  public getAccountWorkflowStatuses(): Observable<
    DictionaryItem<AccountCompanyDTO.WorkflowStatusEnum>[]
  > {
    return this.http
      .get<DictionaryItem<AccountCompanyDTO.WorkflowStatusEnum>[]>(
        `${this.settingsState.apiPath}/account-workflow-statuses`,
      )
      .pipe(catchError(() => of([])));
  }

  public getAccountRiskLevels(): Observable<DictionaryItem<AccountDTO.RiskLevelEnum>[]> {
    return this.http
      .get<DictionaryItem<AccountDTO.RiskLevelEnum>[]>(
        `${this.settingsState.apiPath}/account-risk-levels`,
      )
      .pipe(catchError(() => of([])));
  }

  public getCountries(): Observable<CountryDTO[]> {
    return this.http.get<CountryDTO[]>(`${this.settingsState.apiPath}/countries`).pipe(
      map((data) => data.sort((a, b) => (a.name > b.name ? 1 : -1))),
      catchError(() => of([])),
    );
  }

  public getRegions(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.settingsState.apiPath}/countries/regions`)
      .pipe(catchError(() => of([])));
  }

  public getCustodianEntityTypes(): Observable<
    { key: CustodianDTO.EntityTypeEnum; value: string }[]
  > {
    return this.http
      .get<{ key: CustodianDTO.EntityTypeEnum; value: string }[]>(
        `${this.settingsState.apiPath}/custodian-entity-types`,
      )
      .pipe(catchError(() => of([])));
  }

  public getAddressTypes(): Observable<AddressDTO.AddressTypeEnum[]> {
    return this.http.get<AddressDTO.AddressTypeEnum[]>(
      `${this.settingsState.apiPath}/address-types`,
    );
  }

  public getCustodians(): Observable<CustodianDTO[]> {
    return this.http
      .get<CustodianDTO[]>(`${this.settingsState.apiPath}/custodians`)
      .pipe(catchError(() => of([])));
  }

  public getCurrencies(isForCalc: boolean = false): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.settingsState.apiPath}/currencies`, { params: { isForCalc } })
      .pipe(catchError(() => of([])));
  }

  public getOrderDurations(): Observable<{ key: OrderDTO.DurationEnum; value: string }[]> {
    return this.http
      .get<{ key: OrderDTO.DurationEnum; value: string }[]>(
        `${this.settingsState.apiPath}/order-duration`,
      )
      .pipe(catchError(() => of([])));
  }

  public getTransactionStatuses(): Observable<TransactionDTO.StatusEnum[]> {
    return this.http
      .get<TransactionDTO.StatusEnum[]>(`${this.settingsState.apiPath}/transaction-statuses`)
      .pipe(catchError(() => of([])));
  }

  public getTransactionStatusReasonsWithStatus(): Observable<
    Record<TransactionDTO.StatusEnum, TransactionDTO.StatusReasonEnum[]>
  > {
    return this.http
      .get<Record<TransactionDTO.StatusEnum, TransactionDTO.StatusReasonEnum[]>>(
        `${this.settingsState.apiPath}/transaction-status-reasons-with-status`,
      )
      .pipe(catchError(() => of(null)));
  }

  public getTransactionStatusReasons(): Observable<TransactionDTO.StatusReasonEnum[]> {
    return this.http
      .get<TransactionDTO.StatusReasonEnum[]>(
        `${this.settingsState.apiPath}/transaction-status-reasons`,
      )
      .pipe(catchError(() => of([])));
  }

  public getTransactionTypes(): Observable<TransactionDTO.TypeEnum[]> {
    return this.http
      .get<TransactionDTO.TypeEnum[]>(`${this.settingsState.apiPath}/transaction-types`)
      .pipe(catchError(() => of([])));
  }

  public getExecutionTypes(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.settingsState.apiPath}/execution-types`)
      .pipe(catchError(() => of([])));
  }

  public getHandlInst(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.settingsState.apiPath}/handl-inst`)
      .pipe(catchError(() => of([])));
  }

  public getTransactionSubTypes(): Observable<TransactionDTO.SubTypeEnum[]> {
    return this.http
      .get<TransactionDTO.SubTypeEnum[]>(`${this.settingsState.apiPath}/transaction-sub-types`)
      .pipe(catchError(() => of([])));
  }

  public getTransactionSources(): Observable<CustodianDTO.BankEnum[]> {
    return this.http
      .get<CustodianDTO.BankEnum[]>(`${this.settingsState.apiPath}/transaction-sources`)
      .pipe(catchError(() => of([])));
  }

  public getMandateFeeTypes(): Observable<MandateFeeDTO.TypeEnum[]> {
    return this.http.get<MandateFeeDTO.TypeEnum[]>(
      `${this.settingsState.apiPath}/mandate-fee-types`,
    );
  }

  public getTransactionSubTypesWithType(): Observable<
    Record<string, TransactionDTO.SubTypeEnum[]>
  > {
    return this.http.get<Record<string, TransactionDTO.SubTypeEnum[]>>(
      `${this.settingsState.apiPath}/transaction-sub-types-with-type`,
    );
  }

  public getOrderTransactionSubTypeWithType(): Observable<
    Record<OrderDTO.OrderTransactionTypeEnum, OrderDTO.OrderTransactionSubtypeEnum[]>
  > {
    return this.http
      .get<Record<OrderDTO.OrderTransactionTypeEnum, OrderDTO.OrderTransactionSubtypeEnum[]>>(
        `${this.settingsState.apiPath}/order-transaction-sub-types-with-type`,
      )
      .pipe(catchError(() => of(null)));
  }

  public getFeeTypes(): Observable<FeeDTO.TypeEnum[]> {
    return this.http
      .get<FeeDTO.TypeEnum[]>(`${this.settingsState.apiPath}/fee-types`)
      .pipe(catchError(() => of([])));
  }

  public getFeeStatuses(): Observable<FeeDTO.StatusEnum[]> {
    return this.http
      .get<FeeDTO.StatusEnum[]>(`${this.settingsState.apiPath}/fee-statuses`)
      .pipe(catchError(() => of([])));
  }

  public getFeeStatusReasons(): Observable<FeeDTO.StatusReasonEnum[]> {
    return this.http
      .get<FeeDTO.StatusReasonEnum[]>(`${this.settingsState.apiPath}/fee-status-reasons`)
      .pipe(catchError(() => of([])));
  }

  public getSimpleStatuses(): Observable<FeeDTO.StatusReasonEnum[]> {
    return this.http
      .get<FeeDTO.StatusReasonEnum[]>(`${this.settingsState.apiPath}/simple-statuses`)
      .pipe(catchError(() => of([])));
  }

  public getGroupedFeeStatusReasons(): Observable<Record<string, FeeDTO.StatusReasonEnum[]>> {
    return this.http
      .get<Record<string, FeeDTO.StatusReasonEnum[]>>(
        `${this.settingsState.apiPath}/grouped-fee-status-reasons`,
      )
      .pipe(catchError(() => of({})));
  }

  public getMandateTypes(): Observable<MandateDTO.TypeEnum[]> {
    return this.http
      .get<MandateDTO.TypeEnum[]>(`${this.settingsState.apiPath}/mandate-types`)
      .pipe(catchError(() => of([])));
  }

  public getMandateStatuses(): Observable<MandateDTO.StatusEnum[]> {
    return this.http
      .get<MandateDTO.StatusEnum[]>(`${this.settingsState.apiPath}/mandate-statuses`)
      .pipe(catchError(() => of([])));
  }

  public getPortfolioTypes(): Observable<PortfolioDTO.TypeEnum[]> {
    return this.http
      .get<PortfolioDTO.TypeEnum[]>(`${this.settingsState.apiPath}/portfolio-types`)
      .pipe(catchError(() => of([])));
  }

  public getMandateAuthorizedPersonStatuses(): Observable<MandateAuthorizedPersonDTO.StatusEnum[]> {
    return this.http
      .get<MandateAuthorizedPersonDTO.StatusEnum[]>(
        `${this.settingsState.apiPath}/mandate-authorized-person-statuses`,
      )
      .pipe(catchError(() => of([])));
  }

  public getTradeTypes(): Observable<TradeTypeDTO[]> {
    return this.http
      .get<TradeTypeDTO[]>(`${this.settingsState.apiPath}/trade-types`)
      .pipe(catchError(() => of([])));
  }

  public getOrderTransactionTypes(): Observable<OrderDTO.OrderTransactionTypeEnum[]> {
    return this.http
      .get<OrderDTO.OrderTransactionTypeEnum[]>(
        `${this.settingsState.apiPath}/order-transaction-types`,
      )
      .pipe(catchError(() => of([])));
  }

  public getOrderTransactionTypesInfo(): Observable<OrderTransactionTypeInfo[]> {
    return this.http
      .get<OrderTransactionTypeInfo[]>(`${this.settingsState.apiPath}/order-transaction-type-info`)
      .pipe(catchError(() => of([])));
  }

  public getOrderTransactionSubTypes(): Observable<OrderDTO.OrderTransactionSubtypeEnum[]> {
    return this.http
      .get<OrderDTO.OrderTransactionSubtypeEnum[]>(
        `${this.settingsState.apiPath}/order-transaction-sub-types`,
      )
      .pipe(catchError(() => of([])));
  }

  public getOrderSentBy(): Observable<OrderDTO.OrderSentByEnum[]> {
    return this.http
      .get<OrderDTO.OrderSentByEnum[]>(`${this.settingsState.apiPath}/order-sent-by`)
      .pipe(catchError(() => of([])));
  }

  public getOrderStatuses(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.settingsState.apiPath}/order-statuses`)
      .pipe(catchError(() => of([])));
  }

  public getOrderTypes(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.settingsState.apiPath}/order-types`)
      .pipe(catchError(() => of([])));
  }

  public getOrderRejectReasons(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.settingsState.apiPath}/order-reject-reasons`)
      .pipe(catchError(() => of([])));
  }

  public getSides(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.settingsState.apiPath}/sides`)
      .pipe(catchError(() => of([])));
  }

  public getTimeInForce(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.settingsState.apiPath}/time-in-force`)
      .pipe(catchError(() => of([])));
  }

  public getAssetTypes(assetTypePrivacy?: 'PUBLIC' | 'PRIVATE' | 'STRATEGY'): Observable<string[]> {
    const params: { [p: string]: string } = {};

    if (assetTypePrivacy) {
      params['assetTypePrivacy'] = `${assetTypePrivacy}`;
    }

    return this.http
      .get<string[]>(`${this.settingsState.apiPath}/asset-types`, { params })
      .pipe(catchError(() => of(null)));
  }

  public getAssetTypesTexts(): Observable<DictionaryItem<InstrumentDTO.AssetTypeEnum>[]> {
    return this.http
      .get<DictionaryItem<InstrumentDTO.AssetTypeEnum>[]>(
        `${this.settingsState.apiPath}/asset-type-values`,
      )
      .pipe(catchError(() => of(null)));
  }

  public getAssetSubTypes(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.settingsState.apiPath}/asset-sub-types`)
      .pipe(catchError(() => of(null)));
  }

  public getAssetSubTypesTexts(): Observable<DictionaryItem<InstrumentDTO.AssetSubTypeEnum>[]> {
    return this.http
      .get<DictionaryItem<InstrumentDTO.AssetSubTypeEnum>[]>(
        `${this.settingsState.apiPath}/asset-sub-type-values`,
      )
      .pipe(catchError(() => of(null)));
  }

  public getTradingTypes(
    orderTransactionType: OrderDTO.OrderTransactionTypeEnum,
    instrumentAssetType: InstrumentDTO.AssetTypeEnum,
    bank?: CustodianTinyDTO.BankEnum,
  ): Observable<OrderDTO.TradingTypeEnum[]> {
    const params: { [p: string]: string } = {
      orderTransactionType,
      assetType: instrumentAssetType,
    };

    if (bank) {
      params.bank = bank;
    }

    return this.http
      .get<OrderDTO.TradingTypeEnum[]>(`${this.settingsState.apiPath}/trading-types`, {
        params,
      })
      .pipe(catchError(() => of([])));
  }

  public getFixTimeInForce(): Observable<OrderDTO.TimeInForceEnum[]> {
    return this.http
      .get<OrderDTO.TimeInForceEnum[]>(`${this.settingsState.apiPath}/fix-time-in-forces`)
      .pipe(catchError(() => of([])));
  }

  public getReportTypes(): Observable<{ key: ReportDTO.TypeEnum; value: string }[]> {
    return this.http
      .get<{ key: ReportDTO.TypeEnum; value: string }[]>(
        `${this.settingsState.apiPath}/report-types`,
      )
      .pipe(catchError(() => of([])));
  }

  public getCompanyReportTypes(): Observable<{ key: CompanyReportDTO.TypeEnum; value: string }[]> {
    return this.http
      .get<{ key: CompanyReportDTO.TypeEnum; value: string }[]>(
        `${this.settingsState.apiPath}/company-report-types`,
      )
      .pipe(catchError(() => of([])));
  }

  public getGroupedAssetSubTypes(
    assetTypePrivacy?: 'PUBLIC' | 'PRIVATE' | 'PRODUCT',
  ): Observable<Record<string, string[]>> {
    const params: { [p: string]: string } = {};

    if (assetTypePrivacy) {
      params['privacy'] = `${assetTypePrivacy}`;
    }

    return this.http
      .get<Record<string, string[]>>(`${this.settingsState.apiPath}/grouped-asset-sub-types`, {
        params: params,
      })
      .pipe(catchError(() => of(null)));
  }

  public getGroupedStatuses(
    assetType: InstrumentDTO.AssetTypeEnum,
  ): Observable<Record<string, string[]>> {
    return this.http
      .get<Record<string, string[]>>(`${this.settingsState.apiPath}/grouped-statuses`, {
        params: { assetType },
      })
      .pipe(catchError(() => of(null)));
  }

  public getNoteTypes(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.settingsState.apiPath}/note-types`)
      .pipe(catchError(() => of([])));
  }

  public getIbanTypes(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.settingsState.apiPath}/iban-types`)
      .pipe(catchError(() => of([])));
  }

  public getFieldTypes(): Observable<FieldMetaDTO.FieldTypeEnum[]> {
    return this.http
      .get<FieldMetaDTO.FieldTypeEnum[]>(`${this.settingsState.apiPath}/field-types`)
      .pipe(catchError(() => of([])));
  }

  public getNewIssueAssetTypes(): Observable<OrderDTO.NewIssueAssetTypeEnum[]> {
    return this.http
      .get<OrderDTO.NewIssueAssetTypeEnum[]>(`${this.settingsState.apiPath}/new-issue-asset-types`)
      .pipe(catchError(() => of([])));
  }

  public getChangeRequestEntitiesTypes(): Observable<ChangeRequestSettingsDTO.EntityTypeEnum[]> {
    return this.http
      .get<ChangeRequestSettingsDTO.EntityTypeEnum[]>(
        `${this.settingsState.apiPath}/change-request-entities`,
      )
      .pipe(catchError(() => of([])));
  }

  public getNotificationTemplateEntitiesTypes(): Observable<NotificationTemplateDTO.TypeEnum[]> {
    return this.http
      .get<NotificationTemplateDTO.TypeEnum[]>(
        `${this.settingsState.apiPath}/change-request-entities`,
      )
      .pipe(catchError(() => of([])));
  }

  public getProcessEntitiesTypes(): Observable<ProcessDTO.EntityTypeEnum[]> {
    return this.http
      .get<ProcessDTO.EntityTypeEnum[]>(`${this.settingsState.apiPath}/process-entities`)
      .pipe(catchError(() => of([])));
  }

  public getProcessTriggersTypes(): Observable<ProcessDTO.TriggerTypeEnum[]> {
    return this.http
      .get<ProcessDTO.TriggerTypeEnum[]>(`${this.settingsState.apiPath}/process-triggers`)
      .pipe(catchError(() => of([])));
  }

  public getProcessActionss(): Observable<ActionDTO[]> {
    return this.http
      .get<ActionDTO[]>(`${this.settingsState.apiPath}/process-actions`)
      .pipe(catchError(() => of([])));
  }

  public getNonPhotoDocumentTypes(): Observable<PrivateAssetDocumentContentDTO.DocumentTypeEnum[]> {
    return this.http
      .get<PrivateAssetDocumentContentDTO.DocumentTypeEnum[]>(
        `${this.settingsState.apiPath}/alternative-assets-dictionary/non-photo-document-types`,
      )
      .pipe(catchError(() => of([])));
  }

  public getComponentFeaturesMap(): Observable<
    Record<JiraCreateIssueDTO.ComponentEnum, ComponentDTO>
  > {
    return this.http
      .get<Record<JiraCreateIssueDTO.ComponentEnum, ComponentDTO>>(
        `${this.settingsState.apiPath}/component-features-map`,
      )
      .pipe(catchError(() => of(null)));
  }
}
