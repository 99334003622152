import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { TwelveDataService } from '@shared/api/twelve-data.service';
import { BaseObject } from '@shared/base/base-object';

@Injectable({
  providedIn: 'root',
})
export class ImgSrcCach extends BaseObject {
  constructor(private twelveDataService: TwelveDataService) {
    super();
  }

  public getInstrumentLogo(symbol: string, micCode: string): Observable<string> {
    return this.cachClassMethodByMultipleResults(this._getInstrumentLogo, symbol, micCode);
  }

  private _getInstrumentLogo(symbol: string, micCode: string): Observable<string> {
    return this.twelveDataService
      .getInstrumentLogo(symbol, micCode)
      .pipe(catchError(() => of(null)));
  }
}
