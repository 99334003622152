<div class="app-subscription-notification__logo app-bg-05">
  <app-svg src="/assets/img/logo/reluna/logo-subscription.svg"
           [subType]="'success'"
           [disabledHover]="true"
           style="margin-right: 8px;">
  </app-svg>

  <span class="h3-b app-text-success">PREMIUM</span>
</div>

<span class="h3-sp app-text-color"
      style="margin-bottom: 10px; text-align: center;">
  {{ title || 'The feature' }}
</span>

<span class="b2-r app-text-color-75"
      style="text-wrap: wrap; text-align: center; max-width: 350px;">
  This feature is not available for your current subscription plan. Contact us for upgrade
</span>