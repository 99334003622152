import { Injectable, NgZone } from '@angular/core';
import orderBy from 'lodash-es/orderBy';
import { filter, fromEvent, interval, merge, skip, takeUntil } from 'rxjs';
import * as uuid from 'uuid';
import { LocalStorageConstants } from '@shared/constants/local-storage-constants';
import { NotificationsService } from 'app/notifications/notifications.service';
import { AuthService } from './auth.service';
import { DateHelper } from './date-helper.service';
import { ScreenRef } from './screen-ref';

export const SESSION_EXPIRATION_SEC = 10 * 60;
export const SESSION_EXPIRATION_CHECK_INTERVAL_SEC = 2;

export enum TabEvents {
  Activity = 'Activity',
  Created = 'Created',
  Closed = 'Closed',
}

export interface TabMessagePayload {
  tabId: string;
  visible?: boolean;
  lastActivityTimestamp?: number;
}

export interface TabMessage {
  type: TabEvents;
  payload: TabMessagePayload;
}

@Injectable({
  providedIn: 'root',
})
export class UserActivityService {
  private readonly tabId: string;
  private userActivityChannel = new BroadcastChannel('USER_ACTIVITY_CHANNEL');
  private siteTabs = new Map<string, TabMessagePayload>();
  private isListen = false;

  constructor(
    private zone: NgZone,
    private screenRef: ScreenRef,
    private auth: AuthService,
    private dateHelper: DateHelper,
    private notificationsService: NotificationsService,
  ) {
    this.tabId = uuid.v4();

    this.siteTabs.set(this.tabId, {
      tabId: this.tabId,
      visible: true,
      lastActivityTimestamp: new Date().getTime(),
    });
  }

  public listenIfRequired(): void {
    if (!this.isListen) {
      this.auth.loggedIn$.subscribe((loggedIn) => {
        this.isListen = loggedIn;

        if (loggedIn) {
          this.listenToUserActivityChannel();

          this.sendTabCreated();
          this.sendTabActivity();
          this.sentTabClosed();

          this.listenToUserActivity();
        }
      });
    }
  }

  private listenToUserActivityChannel(): void {
    this.zone.runOutsideAngular(() => {
      this.userActivityChannel.onmessage = (event) => {
        const message = event.data as TabMessage;

        switch (message.type) {
          case TabEvents.Activity:
            this.siteTabs.set(message.payload.tabId, message.payload);
            break;

          case TabEvents.Created:
            this.siteTabs.set(message.payload.tabId, message.payload);
            break;

          case TabEvents.Closed:
            this.siteTabs.delete(message.payload.tabId);
            break;
        }
      };
    });
  }

  private sendTabCreated(): void {
    this.sendMessage(TabEvents.Created, { tabId: this.tabId, visible: true });
  }

  private sendTabActivity(): void {
    this.zone.runOutsideAngular(() => {
      interval(1000)
        .pipe(takeUntil(this.auth.loggedOut$))
        .subscribe(() => {
          this.sendMessage(TabEvents.Activity, {
            tabId: this.tabId,
            visible: this.screenRef.visible$.value,
            lastActivityTimestamp: this.siteTabs.get(this.tabId).lastActivityTimestamp,
          });
        });
    });
  }

  private sentTabClosed(): void {
    this.zone.runOutsideAngular(() => {
      fromEvent(window, 'beforeunload')
        .pipe(takeUntil(this.auth.loggedOut$))
        .subscribe(() => {
          this.sendMessage(TabEvents.Closed, { tabId: this.tabId });
        });
    });
  }

  private sendMessage(type: TabEvents, payload: TabMessagePayload): void {
    this.userActivityChannel.postMessage({
      type,
      payload,
    });
  }

  private listenToUserActivity(): void {
    this.zone.runOutsideAngular(() => {
      this.screenRef.visible$
        .pipe(skip(1), takeUntil(this.auth.loggedOut$))
        .subscribe((visible) => {
          const activityMessage = this.siteTabs.get(this.tabId);
          activityMessage.visible = visible;
          this.siteTabs.set(this.tabId, activityMessage);
          this.sendMessage(TabEvents.Activity, activityMessage);
        });

      merge(fromEvent(window, 'mousemove'), fromEvent(window, 'click'))
        .pipe(takeUntil(this.auth.loggedOut$))
        .subscribe(() => {
          const activityMessage: TabMessagePayload = {
            tabId: this.tabId,
            visible: true,
            lastActivityTimestamp: new Date().getTime(),
          };
          this.siteTabs.set(this.tabId, activityMessage);
          this.sendMessage(TabEvents.Activity, activityMessage);
        });

      interval(SESSION_EXPIRATION_CHECK_INTERVAL_SEC * 1000)
        .pipe(
          filter(() => !this.sessionInFinalCountdown),
          takeUntil(this.auth.loggedOut$),
        )
        .subscribe(() => {
          const nowTime = this.dateHelper.now(false);
          const siteTabs = [...this.siteTabs.values()];
          const orderedTabs = orderBy(
            siteTabs,
            [
              (tab) => {
                const diff = this.dateHelper.difference(
                  nowTime,
                  new Date(tab.lastActivityTimestamp),
                  'Seconds',
                );

                return diff;
              },
            ],
            ['asc'],
          );

          const firstTabDiff = this.dateHelper.difference(
            nowTime,
            new Date(orderedTabs[0].lastActivityTimestamp),
            'Seconds',
          );
          const isSessionExpiration = firstTabDiff > SESSION_EXPIRATION_SEC;

          if (isSessionExpiration) {
            const visibleOrderedTabs = orderedTabs.filter((tab) => tab.visible);

            if (!visibleOrderedTabs.length) {
              this.auth.logout();
              return;
            }

            const findActiveTabIndex = visibleOrderedTabs.findIndex(
              (tab) => tab.tabId === this.tabId,
            );

            if (findActiveTabIndex === 0) {
              this.openSessionExpirationDialog();
              return;
            }
          }
        });
    });
  }

  private openSessionExpirationDialog(): void {
    this.sessionInFinalCountdown = true;

    this.zone.run(() => {
      this.notificationsService.openSessionExpirationDialog().subscribe((result) => {
        if (result) {
          this.sessionInFinalCountdown = false;
        } else {
          this.auth.logout(false, false, true);
        }
      });
    });
  }

  private set sessionInFinalCountdown(value: boolean) {
    localStorage.setItem(LocalStorageConstants.SessionInFinalCountdown, `${value}`);
  }

  private get sessionInFinalCountdown(): boolean {
    return localStorage.getItem(LocalStorageConstants.SessionInFinalCountdown) === 'true';
  }
}
