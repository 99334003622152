import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '@shared/directives/directives.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { TooltipModule } from '@ui/tooltip/tooltip.module';
import { ToValidationErrorPipe } from './validation-error/to-validation-error.pipe';
import { ValidationErrorDefDirective } from './validation-error/validation-error-def.directive';
import { ValidationErrorComponent } from './validation-error/validation-error.component';
import { ValidationErrorsTooltipComponent } from './validation-errors-tooltip/validation-errors-tooltip.component';

@NgModule({
  imports: [CommonModule, PipesModule, DirectivesModule, TooltipModule],
  declarations: [
    ValidationErrorComponent,
    ValidationErrorDefDirective,
    ValidationErrorsTooltipComponent,
    ToValidationErrorPipe,
  ],
  exports: [
    ValidationErrorComponent,
    ValidationErrorDefDirective,
    ValidationErrorsTooltipComponent,
  ],
})
export class ErrorModule {}
