<div class="app-custom-daterange-picker__body">
  <div class="app-custom-daterange-picker__side app-border-right-10">
    <mat-button-toggle-group class="--snack app-custom-daterange-picker__snacks app-scrollbar-block"
                             [formControl]="toggleControl">
      <span class="app-custom-daterange-picker__snacks-group b5-r app-text-color-75">
        Popular
      </span>

      <mat-button-toggle [value]="ToggleValue.Last7Days">Last 7 Days</mat-button-toggle>
      <mat-button-toggle *ngIf="maxDate && maxDate.getTime() !== today.getTime()"
                         [value]="ToggleValue.Next7Days">
        Next 7 Days
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="app-custom-daterange-picker__page"
       @contentAnimation>
    <div class="app-custom-daterange-picker__page__header app-border-bottom-10">
      <span class="b5-r app-text-color-75"
            style="padding-bottom: 4px;">
        Today
      </span>

      <span class="h4-b">{{ today | appDate }}</span>
    </div>

    <div class="app-custom-daterange-picker__page__body">
      <div class="app-custom-daterange-picker__custom-range"
           [formGroup]="rangeDaysGroup">
        <div class="app-custom-daterange-picker__custom-range__from">
          <span class="b5-r app-text-color-50"
                style="padding-bottom: 8px;">
            From
          </span>

          <span class="c1-r"
                style="padding-bottom: 5px;">
            {{ rangeDaysGroup.controls.fromDay | getChanges | async | getDateByDaysMove | appDate }}
          </span>

          <app-input label="From Days"
                     type="integer"
                     [allowNegativeIntegers]="true"
                     [allowIntegerCounterButtons]="true"
                     style="margin-bottom: 0;"
                     formControlName="fromDay">
          </app-input>
        </div>

        <div class="app-custom-daterange-picker__custom-range__to">
          <span class="b5-r app-text-color-50"
                style="padding-bottom: 8px;">
            To
          </span>

          <span class="c1-r"
                style="padding-bottom: 5px;">
            {{
              rangeDaysGroup.controls.toDay | getChanges | async | getDateByDaysMove | appDate
            }}
          </span>

          <app-input label="To Days"
                     type="integer"
                     [allowNegativeIntegers]="true"
                     [allowIntegerCounterButtons]="true"
                     style="margin-bottom: 0;"
                     formControlName="toDay">
          </app-input>
        </div>
      </div>
    </div>

    <div class="app-custom-daterange-picker__page__footer">
      <app-button type="secondary"
                  class="m-r-8"
                  (click)="_onCancelClick()">
        {{ 'common.cancel'|translate }}
      </app-button>

      <app-button (click)="_onApplyClick()">
        {{ 'common.apply'|translate }}
      </app-button>
    </div>
  </div>
</div>
