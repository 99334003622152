import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomerInfoDto } from '@shared/dto/gateway-secured/models';
import { SettingsState } from '@shared/states/settings.state';

@Injectable({
  providedIn: 'root',
})
export class ControlplaneService {
  protected readonly entryPoint = '/services';

  constructor(
    private http: HttpClient,
    private settingsState: SettingsState,
  ) {}

  public getCustomerInfo(): Observable<CustomerInfoDto> {
    return this.http
      .get(`${this.settingsState.apiPath}${this.entryPoint}/customer-info`)
      .pipe(catchError(() => of(null)));
  }
}
