<ng-container *ngIf="error | isString">
  <div class="app-flex-column b4-r w-100">
    <div class="app-flex-row w-100">
      <span class="b4-r app-text-white-space-nowrap app-text-danger"
            *ngLet="error | toString as _text"
            [appClippedTextTooltip]="_text">
        {{ _text }}
      </span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="error | isObject">
  <ng-container *ngLet="error | toValidationError as _error">
    <div *ngIf="_error.value | isObject; else showSimpleValue"
         class="app-flex-column w-100"
         style="gap: 4px;">
      <div class="app-flex-row w-100">
        <span class="b4-r app-text-white-space-nowrap app-text-danger"
              style="margin-right: 8px;"
              *ngLet="_error.key | textTransform:'camel':'common':'lower' as _key"
              [appClippedTextTooltip]="_key">
          {{ _key }}
        </span>
      </div>

      <div class="app-flex-column w-100">
        <div *ngFor="let subError of _error.value | keyvalue"
             class="app-flex-row w-100">
          <span class="b4-r app-text-white-space-nowrap app-text-color-50"
                style="margin-right: 6px;">
            {{ subError.key | toString | textTransform:'camel':'common':'lower' }}
          </span>

          <ng-container [ngSwitch]="true">
            <ng-container *ngSwitchCase="_error.key === ValidationKeys.MatDatepickerMax ||
                                          _error.key === ValidationKeys.MatDatepickerMin">
              <span class="b4-r app-text-white-space-nowrap"
                    style="overflow: hidden;"
                    *ngLet="subError.value | asDate | appDate as _value"
                    [appClippedTextTooltip]="_value"
                    [appClippedTextTooltipTextToRight]="true">
                {{ (_value | isNotNull) ? _value : '-' }}
              </span>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <span class="b4-r app-text-white-space-nowrap"
                    style="overflow: hidden;"
                    *ngLet="subError.value | toString as _value"
                    [appClippedTextTooltip]="_value"
                    [appClippedTextTooltipTextToRight]="true">
                {{ (_value | isNotNull) ? _value : '-' }}
              </span>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <ng-template #showSimpleValue>
      <div class="app-flex-column b4-r w-100">
        <div class="app-flex-row w-100">
          <span class="b4-r app-text-white-space-nowrap app-text-danger"
                *ngLet="_error.key | textTransform:'camel':'common':'lower' as _key"
                [appClippedTextTooltip]="_key">
            {{ _key }}
          </span>
        </div>

        <ng-container *ngIf="_error.value | isNotNull">
          <div *ngIf="!(_error.value | isBoolean)"
               class="app-flex-row w-100">
            <span class="b4-r app-text-white-space-nowrap"
                  style="overflow: hidden;"
                  *ngLet="_error.value | toString as _value"
                  [appClippedTextTooltip]="_value"
                  [appClippedTextTooltipTextToRight]="true">
              {{ _value }}
            </span>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>
</ng-container>