import { Pipe, PipeTransform } from '@angular/core';
import { UserState } from '@shared/states/user.state';

@Pipe({
  name: 'hasRoleCodes',
})
export class HasRoleCodesPipe implements PipeTransform {
  constructor(private userState: UserState) {}

  public transform(roleCodes: string[]): boolean {
    if (!roleCodes?.length) {
      return true;
    }

    return !!roleCodes.find((roleCode) => roleCode === this.userState.role$.value.code);
  }
}
