import { Pipe, PipeTransform } from '@angular/core';
import { Observable, catchError, concat, map, of } from 'rxjs';
import { PortfoliosService } from '@shared/api/portfolios.service';
import { BaseObject } from '@shared/base/base-object';
import { PriceStreamsService } from '@shared/helpers/price-streams.service';

export interface GetInstrumentPriceResult {
  value: number;
  loading?: boolean;
}

@Pipe({
  name: 'getInstrumentPrice',
})
export class GetInstrumentPricePipe extends BaseObject implements PipeTransform {
  constructor(
    private portfolioService: PortfoliosService,
    private priceStreamsService: PriceStreamsService,
  ) {
    super();
  }

  public transform(instrumentId: string): Observable<GetInstrumentPriceResult> {
    return instrumentId
      ? concat(
          of<GetInstrumentPriceResult>({ value: 0, loading: true }),

          this.portfolioService.getLatestPricesByInstrumentId(instrumentId).pipe(
            map((data) => ({ value: data?.price || 0, loading: false })),
            catchError(() => of<GetInstrumentPriceResult>({ value: 0, loading: false })),
          ),

          this.priceStreamsService
            .getCurrencyPairPrice(instrumentId)
            .pipe(map((data) => ({ value: data.price }))),
        )
      : of<GetInstrumentPriceResult>({ value: 0 });
  }
}
