<app-lazy-outlet [lazyComponent]="lazyComponent">
  <ng-template appLazyProgress>
    <div class="app-widget-spinner"></div>

    <app-svg type="skeleton"
             src="/assets/img/skeletons/empty-table-small.svg"
             style="width: 100%;">
    </app-svg>
  </ng-template>

  <ng-template appLazyError>
    <app-svg type="skeleton"
             src="/assets/img/skeletons/empty-table-small.svg"
             style="width: 100%;">
    </app-svg>

    <app-no-data-notification [isErrorState]="true"
                              [message]="'msg.err.dialogLoadError'|translate">
    </app-no-data-notification>
  </ng-template>
</app-lazy-outlet>
