import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { DictionaryService } from '@api/dictionary.service';
import { IssuesService } from '@api/issues.service';
import { BaseObject } from '@shared/base/base-object';
import { AttachmentTinyDTO } from '@shared/dto/gateway-secured/attachment-tiny-dto';
import { ComponentDTO } from '@shared/dto/gateway-secured/component-dto';
import { JiraCreateIssueDTO } from '@shared/dto/gateway-secured/jira-create-issue-dto';
import { BrowserInfoService } from '@shared/helpers/browser-info.service';
import { FormHelper } from '@shared/helpers/form.helper';
import { UserState } from '@shared/states/user.state';
import { TranslateService } from '@shared/translates/translate.service';
import { DIALOG_DATA, DialogData } from '@ui/dialog/dialog.types';
import { FileData } from '@ui/uploader/uploader.types';
import { NotificationsService } from '../../notifications/notifications.service';
import { FeedbackFormGroup } from './feedback.types';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackComponent extends BaseObject {
  public componentFeaturesMap$ = new BehaviorSubject<
    Record<JiraCreateIssueDTO.ComponentEnum, ComponentDTO>
  >(null);
  public componentSelectOptions$ = new BehaviorSubject<{ name: string; value: string }[]>(null);
  public featuresSelectOptions$ = new BehaviorSubject<{ name: string; value: string }[]>(null);

  public readonly IssueType = JiraCreateIssueDTO.IssueTypeEnum;

  public readonly priorities = Object.values(JiraCreateIssueDTO.PriorityEnum);
  public readonly urgencies = Object.values(JiraCreateIssueDTO.UrgencyLevelEnum);

  public formGroup: FormGroup<FeedbackFormGroup>;
  public loading$ = new BehaviorSubject<boolean>(false);
  public files$ = new BehaviorSubject<FileData<string>[]>([]);

  public descriptionPrefix$: BehaviorSubject<string> = new BehaviorSubject<string>('Issue');
  public filesErrors$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);

  private isFormChanged = false;

  constructor(
    @Inject(DIALOG_DATA) public dialogData: DialogData<void, string>,
    private browserInfoService: BrowserInfoService,
    private issuesService: IssuesService,
    private userState: UserState,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private dictionaryService: DictionaryService,
  ) {
    super();

    this.createForm();

    this.formGroup.controls.issueType.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        switch (value) {
          case JiraCreateIssueDTO.IssueTypeEnum.Bug:
            this.formGroup.controls.component.setValidators([Validators.required]);
            this.formGroup.controls.feature.setValidators([Validators.required]);
            this.descriptionPrefix$.next('Issue');
            break;
          case JiraCreateIssueDTO.IssueTypeEnum.Enhancement:
            this.formGroup.controls.component.setValidators([Validators.required]);
            this.formGroup.controls.feature.setValidators([Validators.required]);
            this.descriptionPrefix$.next('Enhancement');
            break;
          case JiraCreateIssueDTO.IssueTypeEnum.Request:
            this.formGroup.controls.component.clearValidators();
            this.formGroup.controls.feature.clearValidators();

            this.formGroup.controls.component.updateValueAndValidity();
            this.formGroup.controls.feature.updateValueAndValidity();
            this.descriptionPrefix$.next('Request');
            break;
          default:
            break;
        }
      });

    this.dictionaryService
      .getComponentFeaturesMap()
      .pipe(takeUntil(this.destroy$))
      .subscribe((map) => {
        this.componentFeaturesMap$.next(map);
        const componentsOptions: { name: string; value: string }[] = [];

        for (const key in map) {
          componentsOptions.push({ name: map[key].name, value: key });
        }

        this.componentSelectOptions$.next(componentsOptions);
      });
  }

  private createForm(): void {
    this.formGroup = new FormGroup<FeedbackFormGroup>({
      issueType: new FormControl<JiraCreateIssueDTO.IssueTypeEnum>('BUG', Validators.required),
      summary: new FormControl<string>(null, Validators.required),
      description: new FormControl<string>(null, Validators.required),
      expectedResult: new FormControl<string>(null, [Validators.required]),
      component: new FormControl<JiraCreateIssueDTO.ComponentEnum>(null, Validators.required),
      feature: new FormControl<JiraCreateIssueDTO.FeatureEnum>(null, Validators.required),
      process: new FormControl<string>(null),
      urgency: new FormControl<JiraCreateIssueDTO.UrgencyLevelEnum>('MEDIUM'),
      priority: new FormControl<JiraCreateIssueDTO.PriorityEnum>('MEDIUM'),
    });

    const initialValue = this.formGroup.getRawValue();
    combineLatest([this.formGroup.valueChanges, this.files$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isFormChanged =
          Object.keys(initialValue).some((key) => this.formGroup.value[key] != initialValue[key]) ||
          this.files$.value.length > 0;
      });

    this.formGroup.controls.component.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter((v) => !!v),
        tap(() => this.formGroup.controls.feature.setValue(null)),
      )
      .subscribe((value) => {
        const featuresOptions: { name: string; value: string }[] = [];
        const fullMap = this.componentFeaturesMap$.value;

        for (const key in fullMap[value].features) {
          featuresOptions.push({ name: fullMap[value].features[key], value: key });
        }

        this.featuresSelectOptions$.next(featuresOptions);
      });

    this.files$.pipe(takeUntil(this.destroy$)).subscribe((files) => {
      if (files?.length > 10) {
        this.filesErrors$.next(['You can upload up to 10 files']);
      } else {
        this.filesErrors$.next(null);
      }
    });
  }

  public _onSubmitClick(): void {
    if (
      !this.formGroup.controls.description.hasValidator(Validators.minLength(30)) ||
      !this.formGroup.controls.expectedResult.hasValidator(Validators.minLength(15))
    ) {
      this.formGroup.controls.description.setValidators([
        Validators.required,
        Validators.minLength(30),
      ]);
      this.formGroup.controls.expectedResult.setValidators([
        Validators.required,
        Validators.minLength(15),
      ]);

      this.formGroup.controls.description.updateValueAndValidity();
      this.formGroup.controls.expectedResult.updateValueAndValidity();
    }

    if (this.formGroup.invalid || this.filesErrors$.value?.length > 0) {
      FormHelper.markErrors(this.formGroup);
      return;
    }

    const formData = this.formGroup.getRawValue();

    const submitData: JiraCreateIssueDTO = {
      description: formData.description,
      component:
        formData.issueType !== JiraCreateIssueDTO.IssueTypeEnum.Request ? formData.component : null,
      feature:
        formData.issueType !== JiraCreateIssueDTO.IssueTypeEnum.Request ? formData.feature : null,
      issueType: formData.issueType,
      summary: formData.summary,
      priority: formData.priority,
      expectedResult: formData.expectedResult,
      attachments: this.files$.value.map((file) => {
        const attachment: AttachmentTinyDTO = {
          filename: file.name,
          base64Content: file.file,
        };
        return attachment;
      }),
      reporter: this.userState.userEmail$.value,
      page: location.pathname,
      metaData: JSON.stringify({ ...this.browserInfoService.browserInfo }),
      urgencyLevel: formData.urgency,
    };

    this.loading$.next(true);

    this.issuesService
      .createBug(submitData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.loading$.next(false);

        if (result) {
          this.dialogData.close();
        }
      });
  }

  public _onClose(reason?: string): void {
    if (this.isFormChanged) {
      this.notificationsService
        .openConfirmationDialog(
          {
            title: 'Are you sure you want to close the window?',
            description: 'The data you have changed will not be saved',
            subType: 'warning',
            closeBtnText: this.translateService.get('common.cancel'),
            confirmBtnText: this.translateService.get('common.close'),
          },
          '434px',
        )
        .subscribe((confirmationResult) => {
          if (confirmationResult.closeType === 'YES') {
            this.dialogData.close(reason);
          }
        });
    } else {
      this.dialogData.close(reason);
    }
  }
}
