<div class="app-custom-datepicker__body">
  <div class="app-custom-datepicker__side app-border-right-10">
    <mat-button-toggle-group class="app-custom-datepicker__snacks --snack"
                             [formControl]="toggleControl">
      <span class="app-custom-datepicker__snacks-group b5-r app-text-color-75">
        Popular
      </span>

      <mat-button-toggle [value]="ToggleValue.Today">Today</mat-button-toggle>
      <mat-button-toggle [value]="ToggleValue.Tomorrow">Tomorrow</mat-button-toggle>
      <mat-button-toggle [value]="ToggleValue.Yesterday">Yesterday</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="app-custom-datepicker__page"
       @contentAnimation>
    <div class="app-custom-datepicker__page__header app-border-bottom-10">
      <span class="b5-r app-text-color-75"
            style="padding-bottom: 4px;">
        Today
      </span>

      <span class="h4-b">{{ today | appDate }}</span>
    </div>

    <div class="app-custom-datepicker__page__body">
      <mat-button-toggle-group class="--snack m-b-8"
                               [formControl]="customDateModeControl">
        <span class="app-custom-datepicker__snacks-group b5-r app-text-color-75">
          Choice Mode
        </span>
        <mat-button-toggle [value]="CustomDateMode.Past">Past</mat-button-toggle>
        <mat-button-toggle [value]="CustomDateMode.Future">Future</mat-button-toggle>
      </mat-button-toggle-group>

      <app-input label="To N Day"
                 type="integer"
                 style="margin-bottom: 0px;"
                 [allowIntegerCounterButtons]="true"
                 [formControl]="dayCountControl">
      </app-input>
    </div>

    <div class="app-custom-datepicker__page__footer">
      <app-button type="secondary"
                  class="m-r-8"
                  (click)="_onCancelClick()">
        {{ 'common.cancel'|translate }}
      </app-button>

      <app-button (click)="_onApplyClick()">
        {{ 'common.apply'|translate }}
      </app-button>
    </div>
  </div>
</div>

