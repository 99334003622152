import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { DirectivesModule } from '@shared/directives/directives.module';
import { FormDirectivesModule } from '@shared/form-directives/form-directives.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { ErrorModule } from '@ui/error/error.module';
import { MenuModule } from '@ui/menu/menu.module';
import { NotificationModule } from '@ui/notification/notification.module';
import { SpinnerModule } from '@ui/spinner/spinner.module';
import { SvgRegistryService } from '@ui/svg/svg-registry.service';
import { SvgModule } from '@ui/svg/svg.module';
import { TooltipModule } from '@ui/tooltip/tooltip.module';
import { GetSelectOptionInjectorPipe } from './get-select-option-injector.pipe';
import { SelectDropdownComponent } from './select-dropdown.component';
import { SelectErrorDefDirective } from './select-error-def.directive';
import { SelectGroupDefDirective } from './select-group-def.directive';
import { SelectHeaderDefDirective } from './select-header-def.directive';
import { SelectHeaderComponent } from './select-header.component';
import { SelectMultiValueDefDirective } from './select-multi-value-def.directive';
import { SelectMultiValueComponent } from './select-multi-value.component';
import { SelectOptionDefDirective } from './select-option-def.directive';
import { SelectOptionComponent } from './select-option.component';
import { SelectPrefixDirective } from './select-prefix.directive';
import { SelectSuffixDirective } from './select-suffix.directive';
import { SelectValueDefDirective } from './select-value-def.directive';
import { SelectValueComponent } from './select-value.component';
import { SelectComponent } from './select.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    FormDirectivesModule,
    TooltipModule,
    SpinnerModule,
    NotificationModule,
    MenuModule,
    TranslateModule,
    SvgModule,
    NgSelectModule,
    NgOptionHighlightModule,
    NgxMaskDirective,
    NgxMaskPipe,
    PipesModule,
    ErrorModule,
  ],
  declarations: [
    SelectComponent,
    SelectOptionComponent,
    SelectOptionDefDirective,
    SelectValueComponent,
    SelectValueDefDirective,
    SelectMultiValueComponent,
    SelectMultiValueDefDirective,
    SelectHeaderComponent,
    SelectHeaderDefDirective,
    SelectGroupDefDirective,
    SelectPrefixDirective,
    SelectSuffixDirective,
    SelectErrorDefDirective,
    SelectDropdownComponent,
    GetSelectOptionInjectorPipe,
  ],
  exports: [
    SelectComponent,
    SelectOptionComponent,
    SelectOptionDefDirective,
    SelectValueComponent,
    SelectValueDefDirective,
    SelectMultiValueComponent,
    SelectMultiValueDefDirective,
    SelectHeaderComponent,
    SelectHeaderDefDirective,
    SelectGroupDefDirective,
    SelectPrefixDirective,
    SelectSuffixDirective,
    SelectErrorDefDirective,
  ],
  providers: [provideNgxMask()],
})
export class SelectModule {
  constructor(private svgRegistryService: SvgRegistryService) {
    this.svgRegistryService.preload('/assets/img/skeletons/empty-select.svg');
  }
}
