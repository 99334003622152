<app-svg *ngIf="position === 'prefix'"
         [type]="type"
         [subType]="subType"
         [disabled]="disabled"
         [disabledHover]="true"
         [src]="src"
         [icon]="icon">
</app-svg>

<span class="app-icon-button__text c2-r app-text-first-letter-up">
  <ng-content></ng-content>
</span>

<app-svg *ngIf="position === 'suffix'"
         [type]="type"
         [subType]="subType"
         [disabled]="disabled"
         [disabledHover]="true"
         [src]="src"
         [icon]="icon">
</app-svg>