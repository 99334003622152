import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '@shared/helpers/date-helper.service';
import { UserState } from '@shared/states/user.state';
import { AppInjector } from 'app/config/app-injector';

@Pipe({
  name: 'appDate',
})
export class AppDatePipe implements PipeTransform {
  private dateHelper: DateHelper;

  public transform(
    /**
     * @param date Date object, Date string or Timestamp number in milliseconds (The UNIX timestamp is an integer that represents the number of milliseconds elapsed since January 1 1970)
     */
    date: Date | string | number,
    showTime: boolean = false,
    timeFormat?: string,
    dateFormat?: string,
  ): string {
    if (!date) {
      return null;
    }

    if (!dateFormat) {
      dateFormat = AppInjector.Injector.get(UserState).dateFormat;
    }

    this.dateHelper = AppInjector.Injector.get(DateHelper);

    if (showTime) {
      dateFormat = `${dateFormat} ${timeFormat || 'h:mm'}`;
    }

    const resultDate = typeof date === 'number' ? this.dateHelper.getDateFromTimestamp(date) : date;

    return this.dateHelper.format(resultDate, dateFormat);
  }
}
