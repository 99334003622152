import { Injectable, OnDestroy } from '@angular/core';
import * as objectHash from 'object-hash';
import { Observable, Subject, of, tap } from 'rxjs';

@Injectable()
export abstract class BaseObject implements OnDestroy {
  public destroy$: Subject<void> = new Subject();

  private methodsCachByOne: Map<
    (...args: unknown[]) => Observable<unknown>,
    { paramsHash: string; result: unknown }
  >;

  private methodsCachByMultiple: Map<
    (...args: unknown[]) => Observable<unknown>,
    Map<string, unknown>
  >;

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public cachClassMethodByOneResult<F extends (...args: unknown[]) => Observable<unknown>>(
    classMethod: F,
    ...params: Parameters<F>
  ): ReturnType<F> {
    if (!this.methodsCachByOne) {
      this.methodsCachByOne = new Map();
    }

    const _callAndCach = (callParamsHash: string): ReturnType<F> => {
      return (classMethod.bind(this)(...params) as ReturnType<F>).pipe(
        tap((result) => {
          this.methodsCachByOne.set(classMethod, {
            paramsHash: callParamsHash,
            result: result,
          });
        }),
      ) as ReturnType<F>;
    };

    if (this.methodsCachByOne.has(classMethod)) {
      const currentParamsHash = objectHash.MD5(params);

      if (this.methodsCachByOne.get(classMethod).paramsHash !== currentParamsHash) {
        return _callAndCach(currentParamsHash);
      } else {
        return of(this.methodsCachByOne.get(classMethod).result) as ReturnType<F>;
      }
    } else {
      return _callAndCach(objectHash.MD5(params));
    }
  }

  public cachClassMethodByMultipleResults<F extends (...args: unknown[]) => Observable<unknown>>(
    classMethod: F,
    ...params: Parameters<F>
  ): ReturnType<F> {
    if (!this.methodsCachByMultiple) {
      this.methodsCachByMultiple = new Map();
    }

    const _callAndCach = (callParamsHash: string): ReturnType<F> => {
      return (classMethod.bind(this)(...params) as ReturnType<F>).pipe(
        tap((result) => {
          if (!this.methodsCachByMultiple.has(classMethod)) {
            this.methodsCachByMultiple.set(classMethod, new Map());
          }

          const hashMap = this.methodsCachByMultiple.get(classMethod);
          hashMap.set(callParamsHash, result);
        }),
      ) as ReturnType<F>;
    };

    if (this.methodsCachByMultiple.has(classMethod)) {
      const currentParamsHash = objectHash.MD5(params);

      const hashMap = this.methodsCachByMultiple.get(classMethod);

      if (!hashMap.has(currentParamsHash)) {
        return _callAndCach(currentParamsHash);
      } else {
        return of(hashMap.get(currentParamsHash)) as ReturnType<F>;
      }
    } else {
      return _callAndCach(objectHash.MD5(params));
    }
  }
}
