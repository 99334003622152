/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttachmentTinyDTO } from './attachment-tiny-dto';

export interface JiraCreateIssueDTO {
  attachments?: Array<AttachmentTinyDTO>;
  component?: JiraCreateIssueDTO.ComponentEnum;
  description?: string;
  expectedResult?: string;
  feature?: JiraCreateIssueDTO.FeatureEnum;
  issueType: JiraCreateIssueDTO.IssueTypeEnum;
  metaData?: string;
  page?: string;
  priority?: JiraCreateIssueDTO.PriorityEnum;
  reporter: string;
  summary: string;
  system?: JiraCreateIssueDTO.SystemEnum;
  urgencyLevel?: JiraCreateIssueDTO.UrgencyLevelEnum;
}
export namespace JiraCreateIssueDTO {
  export type ComponentEnum =
    | 'ACCOUNTS'
    | 'ANALYTICS'
    | 'CLIENT'
    | 'CRM'
    | 'CUSTOMIZATION'
    | 'ECOSYSTEM'
    | 'FEES'
    | 'INSTRUMENTS_MARKET_DATA'
    | 'MARKET_PLACE'
    | 'OMS'
    | 'PERSONAL_ASSET_MANAGEMENT'
    | 'PORTFOLIO_MANAGEMENT'
    | 'PRODUCTS'
    | 'RECONCILIATION'
    | 'REPORTING'
    | 'RISK_COMPLIANCE'
    | 'TRANSACTION_LOADERS'
    | 'WHITE_LABEL';
  export const ComponentEnum = {
    Accounts: 'ACCOUNTS' as ComponentEnum,
    Analytics: 'ANALYTICS' as ComponentEnum,
    Client: 'CLIENT' as ComponentEnum,
    Crm: 'CRM' as ComponentEnum,
    Customization: 'CUSTOMIZATION' as ComponentEnum,
    Ecosystem: 'ECOSYSTEM' as ComponentEnum,
    Fees: 'FEES' as ComponentEnum,
    InstrumentsMarketData: 'INSTRUMENTS_MARKET_DATA' as ComponentEnum,
    MarketPlace: 'MARKET_PLACE' as ComponentEnum,
    Oms: 'OMS' as ComponentEnum,
    PersonalAssetManagement: 'PERSONAL_ASSET_MANAGEMENT' as ComponentEnum,
    PortfolioManagement: 'PORTFOLIO_MANAGEMENT' as ComponentEnum,
    Products: 'PRODUCTS' as ComponentEnum,
    Reconciliation: 'RECONCILIATION' as ComponentEnum,
    Reporting: 'REPORTING' as ComponentEnum,
    RiskCompliance: 'RISK_COMPLIANCE' as ComponentEnum,
    TransactionLoaders: 'TRANSACTION_LOADERS' as ComponentEnum,
    WhiteLabel: 'WHITE_LABEL' as ComponentEnum,
  };
  export type FeatureEnum =
    | 'ACCOUNT_STRUCTURE_MAP'
    | 'ACM_ACCOUNT_STRUCTURE'
    | 'ACM_COMPLIANCE_TAB'
    | 'ACM_CREATE_HIERARCHY'
    | 'ACM_MAIN_INFO'
    | 'ACM_PORTFOLIOS'
    | 'ADVANCED_ANALYTICS'
    | 'ASSETS_MAP'
    | 'AUTO_EXPIRATION_ORDER'
    | 'AUTO_GENERATION_RULES'
    | 'AUTO_MATCHING_ORDER'
    | 'BANK_ACCOUNT_MANAGEMENT_OMNIBUS'
    | 'BANK_ACCOUNT_MANAGEMENT_PROP'
    | 'BANK_ACCOUNT_MANAGEMENT_SEGREGATED'
    | 'BANK_STATEMENT_INSTRUMENTS'
    | 'BLOOMBERG'
    | 'CANCEL_ORDER_BEFORE_EXECUTION'
    | 'CASS_REPORT'
    | 'CBH_CONNECTION'
    | 'CHANGE_REQUEST_SETTINGS'
    | 'CLIENT_COUNT_REPORT'
    | 'CLIENT_DASHBOARD'
    | 'CLIENT_PORTFOLIO_CREATION'
    | 'CLIENT_REQUESTS'
    | 'CLIENT_STRUCTURE'
    | 'CLIENT_SUPPORT'
    | 'CLIENT_VALUATION_REPORT'
    | 'COMMUNICATION'
    | 'CONNECTION_EXISTING_CUSTODIAN'
    | 'CONNECT_OWN_PROVIDER'
    | 'CONNECT_TO_RELUNA'
    | 'CONSOLIDATED_DATA_LOGIN'
    | 'CONSOLIDATED_PORTFOLIO_CREATION'
    | 'CORPORATE_ACTIONS_INCOME'
    | 'CORPORATE_ACTIONS_ISIN_CHANGE'
    | 'CORPORATE_ACTIONS_REDEMPTION'
    | 'CORPORATE_ACTIONS_REVERSE_SPLIT'
    | 'CORPORATE_ACTIONS_RIGHTS_ISSUE'
    | 'CORPORATE_ACTIONS_SPIN_OFF'
    | 'CORPORATE_ACTIONS_SPLIT'
    | 'CORP_ACTION_INCOME_REDEMPTION'
    | 'CRM_DASHBOARD'
    | 'CRS_REPORT'
    | 'CUSTOM_FEES'
    | 'DAILY_REPORTS'
    | 'DASHBOARD_DATA'
    | 'DBS_CONNECTION'
    | 'DISCLAIMERS_SETTINGS'
    | 'DYNAMIC_RISK_MANAGEMENT'
    | 'EDR_CONNECTION'
    | 'ELIGIBLE_FUNDS_REPORT'
    | 'EMAIL_CUSTOMIZATION'
    | 'EMIR_REPORT'
    | 'ERBK_CONNECTION'
    | 'EXECUTION_FEE'
    | 'EXPORT_EXCEL'
    | 'EXPORT_PDF'
    | 'EXTERNAL_ORDER_MGMT'
    | 'FEE_INVOICE'
    | 'FEE_MANAGEMENT'
    | 'FREEDOM_FINANCE_CONNECTION'
    | 'FX_ORDER_CREATION'
    | 'GLOBAL_ALLOCATION'
    | 'GPP_CONNECTION'
    | 'IBKR_CONNECTION'
    | 'IMPORT_ORDER'
    | 'INCOME_ORDER_GENERATION'
    | 'INTERNAL_MONITORINGS'
    | 'INVESTEC_CONNECTION'
    | 'INVESTMENT_PROFILE'
    | 'INVITE_BY_INVITATION'
    | 'JPM_CONNECTION'
    | 'JULIUS_BAER_CONNECTION'
    | 'LEAD_OPPORTUNITY'
    | 'MANAGEMENT_FEE'
    | 'MANDATE_MNGT_FEES'
    | 'MANUAL_IMPORT_CUSTODIAN'
    | 'MANUAL_MATCHING_SCREEN'
    | 'MANUAL_POSITION_IMPORT'
    | 'MANUAL_TRANSACTION_IMPORT'
    | 'MANUAL_TRANSACTION_MGMT'
    | 'MARGINAL_TRADING'
    | 'MARKET_ANALYTICS'
    | 'MARKET_LISTS'
    | 'MARKET_MAIN_PAGE_CRUD'
    | 'MARKET_NEWS'
    | 'MARKET_SECURITIES_CONNECTION'
    | 'MASS_ORDER_CREATION'
    | 'MIFIR_REPORT'
    | 'MONTHLY_RECONCILIATION_REPORT'
    | 'NEW_CUSTOM_CONNECTION'
    | 'NON_TRADING_ORDERS_BLOCK'
    | 'NON_TRADING_ORDERS_FEE'
    | 'NON_TRADING_ORDERS_INTEREST'
    | 'NON_TRADING_ORDERS_INTERNAL_LOAN'
    | 'NON_TRADING_ORDERS_INTERNAL_PAYMENT'
    | 'NON_TRADING_ORDERS_INTERNAL_TRANSFER'
    | 'NON_TRADING_ORDERS_INVESTMENT'
    | 'NON_TRADING_ORDERS_LOANS_DEPOSITS'
    | 'NON_TRADING_ORDERS_SECURITY_TRANSFER'
    | 'NON_TRADING_ORDERS_TAX'
    | 'NON_TRADING_ORDER_CREATION'
    | 'NOTIFICATIONS_USER_LOG'
    | 'NOTIFICATION_TEMPLATE_SETTINGS'
    | 'OMNIBUS_RECONCILIATION'
    | 'OMN_CLIENT_ORDER_CANCELLATION'
    | 'OMN_CLIENT_ORDER_MODIFICATION'
    | 'OMN_MATCHING'
    | 'OMN_ORDER_CANCEL'
    | 'OMN_ORDER_CREATION'
    | 'OMN_ORDER_EXECUTION'
    | 'OMN_ORDER_PRICE_LIMIT_UPDATE'
    | 'ORDER_VALIDATIONS'
    | 'OTHER'
    | 'PASSWORD_RESET'
    | 'PERSONAL_CARD'
    | 'PNL_REPORT'
    | 'PORTFOLIO_MANUAL_CLOSURE'
    | 'PORTFOLIO_POSITIONS_VIEW'
    | 'PORTFOLIO_POSITION_CALCULATION'
    | 'PORTFOLIO_TRANSACTIONS_VIEW'
    | 'POSITION_RECALCULATION'
    | 'POSITION_RECALCULATION_DETAILS'
    | 'POST_ORDER_ACTION'
    | 'PRIVATE_EQUITY'
    | 'PRIVATE_INSTRUMENTS_CRUD'
    | 'PROCESS_SETTINGS'
    | 'RECOMMENDATION_PAGE_CRUD'
    | 'RECONCILIATION_STATEMENT'
    | 'REPORT_BUILDER'
    | 'REPORT_EMAIL_SENDING'
    | 'REPORT_GENERATION'
    | 'REPORT_MAIN_PAGE_SETTINGS'
    | 'RESTRICTION_ALERTS'
    | 'REVERT_ORDER_FUNCTION'
    | 'RISK_CRS_REPORT'
    | 'RISK_WIDGET'
    | 'RTS28_REPORT'
    | 'SALES_FORCE_INTEGRATION'
    | 'SEND_RECOMMENDATION'
    | 'SETTLED_AND_UNSETTLED_AMOUNTS'
    | 'SHARED_INSTRUMENTS'
    | 'SHOW_CLOSED_POSITIONS'
    | 'STRATEGIC_POSITIONING'
    | 'STRATEGY_CREATION'
    | 'STRATEGY_EXECUTION_ALL'
    | 'STRATEGY_EXECUTION_SINGLE'
    | 'STRATEGY_PAGE_CRUD'
    | 'SUBSCRIPTION_ORDERS'
    | 'SUCCESS_FEE'
    | 'SUMSUB_INTEGRATION'
    | 'TABLE_BUILDER'
    | 'TANGIBLE_ASSETS_CRUD'
    | 'TAVIRA_CONNECTION'
    | 'TECHNICAL_ORDERS_ADJUSTMENT'
    | 'TECHNICAL_ORDERS_MARGIN'
    | 'TECHNICAL_ORDERS_REVERSAL'
    | 'TECHNICAL_ORDERS_TAX'
    | 'TRADING_ORDERS_BUY_SELL'
    | 'TRADING_ORDERS_CROSS_TRADE'
    | 'TRADING_ORDERS_FX'
    | 'TRADING_ORDER_EXECUTION_DIFF_CURRENCY'
    | 'TRADING_ORDER_EXECUTION_FOP_DVP'
    | 'TWELVE_DATA'
    | 'TWO_AUTH'
    | 'UBS_CONNECTION'
    | 'USER_MANAGEMENT'
    | 'VP_BANK_CONNECTION'
    | 'WHITE_LABEL_LOGO'
    | 'WHITE_LABEL_SETTINGS_PAGE';
  export const FeatureEnum = {
    AccountStructureMap: 'ACCOUNT_STRUCTURE_MAP' as FeatureEnum,
    AcmAccountStructure: 'ACM_ACCOUNT_STRUCTURE' as FeatureEnum,
    AcmComplianceTab: 'ACM_COMPLIANCE_TAB' as FeatureEnum,
    AcmCreateHierarchy: 'ACM_CREATE_HIERARCHY' as FeatureEnum,
    AcmMainInfo: 'ACM_MAIN_INFO' as FeatureEnum,
    AcmPortfolios: 'ACM_PORTFOLIOS' as FeatureEnum,
    AdvancedAnalytics: 'ADVANCED_ANALYTICS' as FeatureEnum,
    AssetsMap: 'ASSETS_MAP' as FeatureEnum,
    AutoExpirationOrder: 'AUTO_EXPIRATION_ORDER' as FeatureEnum,
    AutoGenerationRules: 'AUTO_GENERATION_RULES' as FeatureEnum,
    AutoMatchingOrder: 'AUTO_MATCHING_ORDER' as FeatureEnum,
    BankAccountManagementOmnibus: 'BANK_ACCOUNT_MANAGEMENT_OMNIBUS' as FeatureEnum,
    BankAccountManagementProp: 'BANK_ACCOUNT_MANAGEMENT_PROP' as FeatureEnum,
    BankAccountManagementSegregated: 'BANK_ACCOUNT_MANAGEMENT_SEGREGATED' as FeatureEnum,
    BankStatementInstruments: 'BANK_STATEMENT_INSTRUMENTS' as FeatureEnum,
    Bloomberg: 'BLOOMBERG' as FeatureEnum,
    CancelOrderBeforeExecution: 'CANCEL_ORDER_BEFORE_EXECUTION' as FeatureEnum,
    CassReport: 'CASS_REPORT' as FeatureEnum,
    CbhConnection: 'CBH_CONNECTION' as FeatureEnum,
    ChangeRequestSettings: 'CHANGE_REQUEST_SETTINGS' as FeatureEnum,
    ClientCountReport: 'CLIENT_COUNT_REPORT' as FeatureEnum,
    ClientDashboard: 'CLIENT_DASHBOARD' as FeatureEnum,
    ClientPortfolioCreation: 'CLIENT_PORTFOLIO_CREATION' as FeatureEnum,
    ClientRequests: 'CLIENT_REQUESTS' as FeatureEnum,
    ClientStructure: 'CLIENT_STRUCTURE' as FeatureEnum,
    ClientSupport: 'CLIENT_SUPPORT' as FeatureEnum,
    ClientValuationReport: 'CLIENT_VALUATION_REPORT' as FeatureEnum,
    Communication: 'COMMUNICATION' as FeatureEnum,
    ConnectionExistingCustodian: 'CONNECTION_EXISTING_CUSTODIAN' as FeatureEnum,
    ConnectOwnProvider: 'CONNECT_OWN_PROVIDER' as FeatureEnum,
    ConnectToReluna: 'CONNECT_TO_RELUNA' as FeatureEnum,
    ConsolidatedDataLogin: 'CONSOLIDATED_DATA_LOGIN' as FeatureEnum,
    ConsolidatedPortfolioCreation: 'CONSOLIDATED_PORTFOLIO_CREATION' as FeatureEnum,
    CorporateActionsIncome: 'CORPORATE_ACTIONS_INCOME' as FeatureEnum,
    CorporateActionsIsinChange: 'CORPORATE_ACTIONS_ISIN_CHANGE' as FeatureEnum,
    CorporateActionsRedemption: 'CORPORATE_ACTIONS_REDEMPTION' as FeatureEnum,
    CorporateActionsReverseSplit: 'CORPORATE_ACTIONS_REVERSE_SPLIT' as FeatureEnum,
    CorporateActionsRightsIssue: 'CORPORATE_ACTIONS_RIGHTS_ISSUE' as FeatureEnum,
    CorporateActionsSpinOff: 'CORPORATE_ACTIONS_SPIN_OFF' as FeatureEnum,
    CorporateActionsSplit: 'CORPORATE_ACTIONS_SPLIT' as FeatureEnum,
    CorpActionIncomeRedemption: 'CORP_ACTION_INCOME_REDEMPTION' as FeatureEnum,
    CrmDashboard: 'CRM_DASHBOARD' as FeatureEnum,
    CrsReport: 'CRS_REPORT' as FeatureEnum,
    CustomFees: 'CUSTOM_FEES' as FeatureEnum,
    DailyReports: 'DAILY_REPORTS' as FeatureEnum,
    DashboardData: 'DASHBOARD_DATA' as FeatureEnum,
    DbsConnection: 'DBS_CONNECTION' as FeatureEnum,
    DisclaimersSettings: 'DISCLAIMERS_SETTINGS' as FeatureEnum,
    DynamicRiskManagement: 'DYNAMIC_RISK_MANAGEMENT' as FeatureEnum,
    EdrConnection: 'EDR_CONNECTION' as FeatureEnum,
    EligibleFundsReport: 'ELIGIBLE_FUNDS_REPORT' as FeatureEnum,
    EmailCustomization: 'EMAIL_CUSTOMIZATION' as FeatureEnum,
    EmirReport: 'EMIR_REPORT' as FeatureEnum,
    ErbkConnection: 'ERBK_CONNECTION' as FeatureEnum,
    ExecutionFee: 'EXECUTION_FEE' as FeatureEnum,
    ExportExcel: 'EXPORT_EXCEL' as FeatureEnum,
    ExportPdf: 'EXPORT_PDF' as FeatureEnum,
    ExternalOrderMgmt: 'EXTERNAL_ORDER_MGMT' as FeatureEnum,
    FeeInvoice: 'FEE_INVOICE' as FeatureEnum,
    FeeManagement: 'FEE_MANAGEMENT' as FeatureEnum,
    FreedomFinanceConnection: 'FREEDOM_FINANCE_CONNECTION' as FeatureEnum,
    FxOrderCreation: 'FX_ORDER_CREATION' as FeatureEnum,
    GlobalAllocation: 'GLOBAL_ALLOCATION' as FeatureEnum,
    GppConnection: 'GPP_CONNECTION' as FeatureEnum,
    IbkrConnection: 'IBKR_CONNECTION' as FeatureEnum,
    ImportOrder: 'IMPORT_ORDER' as FeatureEnum,
    IncomeOrderGeneration: 'INCOME_ORDER_GENERATION' as FeatureEnum,
    InternalMonitorings: 'INTERNAL_MONITORINGS' as FeatureEnum,
    InvestecConnection: 'INVESTEC_CONNECTION' as FeatureEnum,
    InvestmentProfile: 'INVESTMENT_PROFILE' as FeatureEnum,
    InviteByInvitation: 'INVITE_BY_INVITATION' as FeatureEnum,
    JpmConnection: 'JPM_CONNECTION' as FeatureEnum,
    JuliusBaerConnection: 'JULIUS_BAER_CONNECTION' as FeatureEnum,
    LeadOpportunity: 'LEAD_OPPORTUNITY' as FeatureEnum,
    ManagementFee: 'MANAGEMENT_FEE' as FeatureEnum,
    MandateMngtFees: 'MANDATE_MNGT_FEES' as FeatureEnum,
    ManualImportCustodian: 'MANUAL_IMPORT_CUSTODIAN' as FeatureEnum,
    ManualMatchingScreen: 'MANUAL_MATCHING_SCREEN' as FeatureEnum,
    ManualPositionImport: 'MANUAL_POSITION_IMPORT' as FeatureEnum,
    ManualTransactionImport: 'MANUAL_TRANSACTION_IMPORT' as FeatureEnum,
    ManualTransactionMgmt: 'MANUAL_TRANSACTION_MGMT' as FeatureEnum,
    MarginalTrading: 'MARGINAL_TRADING' as FeatureEnum,
    MarketAnalytics: 'MARKET_ANALYTICS' as FeatureEnum,
    MarketLists: 'MARKET_LISTS' as FeatureEnum,
    MarketMainPageCrud: 'MARKET_MAIN_PAGE_CRUD' as FeatureEnum,
    MarketNews: 'MARKET_NEWS' as FeatureEnum,
    MarketSecuritiesConnection: 'MARKET_SECURITIES_CONNECTION' as FeatureEnum,
    MassOrderCreation: 'MASS_ORDER_CREATION' as FeatureEnum,
    MifirReport: 'MIFIR_REPORT' as FeatureEnum,
    MonthlyReconciliationReport: 'MONTHLY_RECONCILIATION_REPORT' as FeatureEnum,
    NewCustomConnection: 'NEW_CUSTOM_CONNECTION' as FeatureEnum,
    NonTradingOrdersBlock: 'NON_TRADING_ORDERS_BLOCK' as FeatureEnum,
    NonTradingOrdersFee: 'NON_TRADING_ORDERS_FEE' as FeatureEnum,
    NonTradingOrdersInterest: 'NON_TRADING_ORDERS_INTEREST' as FeatureEnum,
    NonTradingOrdersInternalLoan: 'NON_TRADING_ORDERS_INTERNAL_LOAN' as FeatureEnum,
    NonTradingOrdersInternalPayment: 'NON_TRADING_ORDERS_INTERNAL_PAYMENT' as FeatureEnum,
    NonTradingOrdersInternalTransfer: 'NON_TRADING_ORDERS_INTERNAL_TRANSFER' as FeatureEnum,
    NonTradingOrdersInvestment: 'NON_TRADING_ORDERS_INVESTMENT' as FeatureEnum,
    NonTradingOrdersLoansDeposits: 'NON_TRADING_ORDERS_LOANS_DEPOSITS' as FeatureEnum,
    NonTradingOrdersSecurityTransfer: 'NON_TRADING_ORDERS_SECURITY_TRANSFER' as FeatureEnum,
    NonTradingOrdersTax: 'NON_TRADING_ORDERS_TAX' as FeatureEnum,
    NonTradingOrderCreation: 'NON_TRADING_ORDER_CREATION' as FeatureEnum,
    NotificationsUserLog: 'NOTIFICATIONS_USER_LOG' as FeatureEnum,
    NotificationTemplateSettings: 'NOTIFICATION_TEMPLATE_SETTINGS' as FeatureEnum,
    OmnibusReconciliation: 'OMNIBUS_RECONCILIATION' as FeatureEnum,
    OmnClientOrderCancellation: 'OMN_CLIENT_ORDER_CANCELLATION' as FeatureEnum,
    OmnClientOrderModification: 'OMN_CLIENT_ORDER_MODIFICATION' as FeatureEnum,
    OmnMatching: 'OMN_MATCHING' as FeatureEnum,
    OmnOrderCancel: 'OMN_ORDER_CANCEL' as FeatureEnum,
    OmnOrderCreation: 'OMN_ORDER_CREATION' as FeatureEnum,
    OmnOrderExecution: 'OMN_ORDER_EXECUTION' as FeatureEnum,
    OmnOrderPriceLimitUpdate: 'OMN_ORDER_PRICE_LIMIT_UPDATE' as FeatureEnum,
    OrderValidations: 'ORDER_VALIDATIONS' as FeatureEnum,
    Other: 'OTHER' as FeatureEnum,
    PasswordReset: 'PASSWORD_RESET' as FeatureEnum,
    PersonalCard: 'PERSONAL_CARD' as FeatureEnum,
    PnlReport: 'PNL_REPORT' as FeatureEnum,
    PortfolioManualClosure: 'PORTFOLIO_MANUAL_CLOSURE' as FeatureEnum,
    PortfolioPositionsView: 'PORTFOLIO_POSITIONS_VIEW' as FeatureEnum,
    PortfolioPositionCalculation: 'PORTFOLIO_POSITION_CALCULATION' as FeatureEnum,
    PortfolioTransactionsView: 'PORTFOLIO_TRANSACTIONS_VIEW' as FeatureEnum,
    PositionRecalculation: 'POSITION_RECALCULATION' as FeatureEnum,
    PositionRecalculationDetails: 'POSITION_RECALCULATION_DETAILS' as FeatureEnum,
    PostOrderAction: 'POST_ORDER_ACTION' as FeatureEnum,
    PrivateEquity: 'PRIVATE_EQUITY' as FeatureEnum,
    PrivateInstrumentsCrud: 'PRIVATE_INSTRUMENTS_CRUD' as FeatureEnum,
    ProcessSettings: 'PROCESS_SETTINGS' as FeatureEnum,
    RecommendationPageCrud: 'RECOMMENDATION_PAGE_CRUD' as FeatureEnum,
    ReconciliationStatement: 'RECONCILIATION_STATEMENT' as FeatureEnum,
    ReportBuilder: 'REPORT_BUILDER' as FeatureEnum,
    ReportEmailSending: 'REPORT_EMAIL_SENDING' as FeatureEnum,
    ReportGeneration: 'REPORT_GENERATION' as FeatureEnum,
    ReportMainPageSettings: 'REPORT_MAIN_PAGE_SETTINGS' as FeatureEnum,
    RestrictionAlerts: 'RESTRICTION_ALERTS' as FeatureEnum,
    RevertOrderFunction: 'REVERT_ORDER_FUNCTION' as FeatureEnum,
    RiskCrsReport: 'RISK_CRS_REPORT' as FeatureEnum,
    RiskWidget: 'RISK_WIDGET' as FeatureEnum,
    Rts28Report: 'RTS28_REPORT' as FeatureEnum,
    SalesForceIntegration: 'SALES_FORCE_INTEGRATION' as FeatureEnum,
    SendRecommendation: 'SEND_RECOMMENDATION' as FeatureEnum,
    SettledAndUnsettledAmounts: 'SETTLED_AND_UNSETTLED_AMOUNTS' as FeatureEnum,
    SharedInstruments: 'SHARED_INSTRUMENTS' as FeatureEnum,
    ShowClosedPositions: 'SHOW_CLOSED_POSITIONS' as FeatureEnum,
    StrategicPositioning: 'STRATEGIC_POSITIONING' as FeatureEnum,
    StrategyCreation: 'STRATEGY_CREATION' as FeatureEnum,
    StrategyExecutionAll: 'STRATEGY_EXECUTION_ALL' as FeatureEnum,
    StrategyExecutionSingle: 'STRATEGY_EXECUTION_SINGLE' as FeatureEnum,
    StrategyPageCrud: 'STRATEGY_PAGE_CRUD' as FeatureEnum,
    SubscriptionOrders: 'SUBSCRIPTION_ORDERS' as FeatureEnum,
    SuccessFee: 'SUCCESS_FEE' as FeatureEnum,
    SumsubIntegration: 'SUMSUB_INTEGRATION' as FeatureEnum,
    TableBuilder: 'TABLE_BUILDER' as FeatureEnum,
    TangibleAssetsCrud: 'TANGIBLE_ASSETS_CRUD' as FeatureEnum,
    TaviraConnection: 'TAVIRA_CONNECTION' as FeatureEnum,
    TechnicalOrdersAdjustment: 'TECHNICAL_ORDERS_ADJUSTMENT' as FeatureEnum,
    TechnicalOrdersMargin: 'TECHNICAL_ORDERS_MARGIN' as FeatureEnum,
    TechnicalOrdersReversal: 'TECHNICAL_ORDERS_REVERSAL' as FeatureEnum,
    TechnicalOrdersTax: 'TECHNICAL_ORDERS_TAX' as FeatureEnum,
    TradingOrdersBuySell: 'TRADING_ORDERS_BUY_SELL' as FeatureEnum,
    TradingOrdersCrossTrade: 'TRADING_ORDERS_CROSS_TRADE' as FeatureEnum,
    TradingOrdersFx: 'TRADING_ORDERS_FX' as FeatureEnum,
    TradingOrderExecutionDiffCurrency: 'TRADING_ORDER_EXECUTION_DIFF_CURRENCY' as FeatureEnum,
    TradingOrderExecutionFopDvp: 'TRADING_ORDER_EXECUTION_FOP_DVP' as FeatureEnum,
    TwelveData: 'TWELVE_DATA' as FeatureEnum,
    TwoAuth: 'TWO_AUTH' as FeatureEnum,
    UbsConnection: 'UBS_CONNECTION' as FeatureEnum,
    UserManagement: 'USER_MANAGEMENT' as FeatureEnum,
    VpBankConnection: 'VP_BANK_CONNECTION' as FeatureEnum,
    WhiteLabelLogo: 'WHITE_LABEL_LOGO' as FeatureEnum,
    WhiteLabelSettingsPage: 'WHITE_LABEL_SETTINGS_PAGE' as FeatureEnum,
  };
  export type IssueTypeEnum = 'BUG' | 'ENHANCEMENT' | 'NEW_REQUEST' | 'REQUEST' | 'SUPPORT';
  export const IssueTypeEnum = {
    Bug: 'BUG' as IssueTypeEnum,
    Enhancement: 'ENHANCEMENT' as IssueTypeEnum,
    NewRequest: 'NEW_REQUEST' as IssueTypeEnum,
    Request: 'REQUEST' as IssueTypeEnum,
    Support: 'SUPPORT' as IssueTypeEnum,
  };
  export type PriorityEnum = 'BLOCKER' | 'HIGH' | 'LOW' | 'MEDIUM' | 'MINOR';
  export const PriorityEnum = {
    Blocker: 'BLOCKER' as PriorityEnum,
    High: 'HIGH' as PriorityEnum,
    Low: 'LOW' as PriorityEnum,
    Medium: 'MEDIUM' as PriorityEnum,
    Minor: 'MINOR' as PriorityEnum,
  };
  export type SystemEnum = 'IOS_CLIENT_APPLICATION' | 'IOS_MANAGER_APPLICATION' | 'WEB_VERSION';
  export const SystemEnum = {
    IosClientApplication: 'IOS_CLIENT_APPLICATION' as SystemEnum,
    IosManagerApplication: 'IOS_MANAGER_APPLICATION' as SystemEnum,
    WebVersion: 'WEB_VERSION' as SystemEnum,
  };
  export type UrgencyLevelEnum = 'HIGH' | 'LOW' | 'MEDIUM';
  export const UrgencyLevelEnum = {
    High: 'HIGH' as UrgencyLevelEnum,
    Low: 'LOW' as UrgencyLevelEnum,
    Medium: 'MEDIUM' as UrgencyLevelEnum,
  };
}
