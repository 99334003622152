<div class="app-navbar__container app-page-container">
  <a class="app-navbar__logo-container"
     (click)="_onToMainPageClick()">
    <img class="app-navbar-logo"
         [src]="logoState.headerLogo$|async">
  </a>

  <div class="app-navbar__nav-item --tester b3-r"
       #navItemTester>
  </div>

  <nav class="app-navbar__nav"
       #nav>
    <div class="app-navbar__nav-body">
      <ng-container *ngFor="let navbarItem of navbarItems$|async">
        <a [routerLink]="[navbarItem.url]"
           routerLinkActive="app-navbar__nav-item--active"
           #rla="routerLinkActive"
           [class.app-text-color]="rla.isActive"
           [class.app-text-color-65]="!rla.isActive"
           class="app-navbar__nav-item b3-r">
          {{navbarItem.name|translate}}
        </a>
      </ng-container>
    </div>
  </nav>

  <nav class="app-navbar__nav-right"
       *ngLet="popupNavbarItems$|async as popupNavbarItems">
    <div *ngIf="popupNavbarItems.length"
         class="app-navbar__nav-item --icon">
      <app-svg [icon]="'MenuVertical_24'"
               [matMenuTriggerFor]="menu"
               class="app-navbar__nav__big-icon">
      </app-svg>

      <mat-menu #menu="matMenu">
        <ng-container *ngFor="let popupNavbarItem of popupNavbarItems">
          <a mat-menu-item
             [routerLink]="[popupNavbarItem.url]"
             routerLinkActive="app-navbar__nav-item--active"
             #rla="routerLinkActive">
            <span [routerLink]="popupNavbarItem.url">
              {{popupNavbarItem.name | translate | titlecase}}
            </span>

            <app-svg *ngIf="rla.isActive"
                     [icon]="'Done_24'"
                     style="margin-left: auto;">
            </app-svg>
          </a>
        </ng-container>
      </mat-menu>
    </div>

    <ng-container *ngLet="notificationsService.pushNotificationsCount$ | async as count">
      <a *ngIf="layoutState.showNotifications$|async"
         class="app-navbar__nav-item --icon"
         (click)="_onShowNotificationsClick()"
         [matTooltip]="count > 0 ? 'Unread notifications: ' + count : 'tooltip.notifications'|translate"
         matTooltipShowDelay="300">
        <app-svg class="app-navbar__nav__big-icon app-navbar__notification-icon"
                 [type]="'none'"
                 [icon]="count > 0 ? 'NotificationUnread' : 'Notification'">
        </app-svg>
      </a>
    </ng-container>

    <a [matTooltip]="'tooltip.user-settings'|translate"
       matTooltipShowDelay="300"
       class="app-navbar__nav-item app-navbar__nav-item--without-underline"
       (click)="_onProfileMenuClick(profileNavItemElement)"
       #profileNavItemElement>
      <app-svg [icon]="'User_24'"
               class="app-navbar__nav__big-icon"
               [class.app-bg-10]="profileMenuOpened$|async">
      </app-svg>
    </a>
  </nav>
</div>