<div class="app-dialog-notification">
  <div class="app-dialog-notification__header">
    <app-svg class="app-dialog-notification__icon"
             [icon]="'StatusAttentionRectangle_24'"
             [disabledHover]="true">
    </app-svg>

    <span class="h4-sp">
      {{'dialogs.updateDialog.title'|translate}}
    </span>

    <app-svg [icon]="'Close_24'"
             (click)="_onCloseClick()"
             class="app-dialog-notification__close">
    </app-svg>
  </div>

  <div class="app-dialog-notification__body">
    <span class="b3-r"
          style="padding-top: 5px;">
      {{'dialogs.updateDialog.message1'|translate}} {{ countdown$|async }}
      {{'dialogs.updateDialog.seconds'|translate}}.
    </span>
    <span class="b3-r"
          style="padding-top: 12px;">
      {{'dialogs.updateDialog.message2'|translate}}
    </span>
  </div>

  <div class="app-dialog-notification__footer">
    <app-button (click)="_onCloseClick()"
                style="margin-right: 10px;">
      {{ 'common.close'|translate }}
    </app-button>

    <app-button type="secondary"
                style="margin-left: 10px;"
                (click)="_onYesClick()">
      {{ 'common.yes'|translate }}
    </app-button>
  </div>
</div>