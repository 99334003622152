import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocalStorageConstants } from '@shared/constants/local-storage-constants';
import { JiraCreateIssueDTO } from '@shared/dto/gateway-secured/jira-create-issue-dto';
import {
  AttachmentTinyDTO,
  JiraCommentCreateDTO,
  JiraCommentDTO,
  JiraCommentUpdateDTO,
  JiraIssueDTO,
} from '@shared/dto/gateway-secured/models';
import { SettingsState } from '@shared/states/settings.state';
import { DataWithError } from '@shared/types/data-with-error';

@Injectable({
  providedIn: 'root',
})
export class IssuesService {
  constructor(
    private settingsState: SettingsState,
    private http: HttpClient,
  ) {}

  public createBug(data: JiraCreateIssueDTO): Observable<boolean> {
    return this.http
      .post(`${this.settingsState.apiPath}/issues`, data, {
        responseType: 'text',
      })
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  public getByEmail(
    email: string = localStorage.getItem(LocalStorageConstants.Email),
  ): Observable<JiraIssueDTO[]> {
    return this.http.get(`${this.settingsState.apiPath}/issues/email`, {
      params: {
        email,
        index: '0',
        size: '9999999',
      },
    }) as Observable<JiraIssueDTO[]>;
  }

  public getComments(issueId: string): Observable<JiraCommentDTO[]> {
    return this.http
      .get<JiraCommentDTO[]>(`${this.settingsState.apiPath}/issues/comments`, {
        params: { issueId },
      })
      .pipe(
        map((result) => result || []),
        catchError(() => of([])),
      );
  }

  public createComment(issueId: string, comment: JiraCommentCreateDTO): Observable<boolean> {
    return this.http
      .post(`${this.settingsState.apiPath}/issues/comments`, comment, {
        params: { issueId },
      })
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  public updateComment(issueId: string, comment: JiraCommentUpdateDTO): Observable<boolean> {
    return this.http
      .put(`${this.settingsState.apiPath}/issues/comments`, comment, {
        params: { issueId },
      })
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  public deleteComment(issueId: string, commentId: string): Observable<boolean> {
    return this.http
      .delete(`${this.settingsState.apiPath}/issues/comments`, {
        params: { issueId, commentId },
      })
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  public getAttachment(issueId: string): Observable<AttachmentTinyDTO[]> {
    return this.http
      .get<AttachmentTinyDTO[]>(`${this.settingsState.apiPath}/issues/attachment`, {
        params: { issueId },
      })
      .pipe(
        map((result) => result || []),
        catchError(() => of([])),
      );
  }

  public createAttachment(issueId: string, body: AttachmentTinyDTO): Observable<boolean> {
    return this.http
      .post(`${this.settingsState.apiPath}/issues/attachment`, body, {
        params: { issueId },
      })
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  public deleteAttachment(issueId: string, attachmentId: string): Observable<boolean> {
    return this.http
      .delete(`${this.settingsState.apiPath}/issues/attachment`, {
        params: { issueId, attachmentId },
      })
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }
}
