<div class="app-fixed-controls">
  <ng-container *ngIf="feedbackButtonState$|async as feedbackButtonState">
    <div *ngIf="feedbackButtonState === 'closed'"
         class="app-fixed-controls__button-feedback app-bg-inverse"
         (click)="_onClick()">
      <app-svg [icon]="'MessageAlert_24'"
               subType="inverse"
               [disabledHover]="true">
      </app-svg>
    </div>
  </ng-container>
</div>
