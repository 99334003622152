import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable, map } from 'rxjs';
import { isNotEmptyObject } from '@shared/base/core';

@Pipe({
  name: 'hasError',
})
export class HasErrorPipe implements PipeTransform {
  public transform<TControl extends AbstractControl>(
    formControl: TControl,
    errorName?: string | string[],
    condition: 'touched' | 'touched-or-dirty' | 'none' = 'touched',
  ): Observable<boolean> {
    return formControl.statusChanges.pipe(
      map((status) => {
        if (status === 'INVALID') {
          let conditionIsPassed = false;

          switch (condition) {
            case 'touched':
              conditionIsPassed = formControl.touched;
              break;

            case 'touched-or-dirty':
              conditionIsPassed = formControl.touched || formControl.dirty;
              break;

            case 'none':
              conditionIsPassed = true;
              break;
          }

          if (!conditionIsPassed) {
            return false;
          }

          if (errorName) {
            if (Array.isArray(errorName)) {
              return errorName.some((name) => formControl.errors?.[name]);
            } else {
              return formControl.errors?.[errorName];
            }
          } else {
            return isNotEmptyObject(formControl.errors);
          }
        } else {
          return false;
        }
      }),
    );
  }
}
