import { Directive, ElementRef, NgZone } from '@angular/core';

@Directive({
  selector: '[appScrollMove]',
  exportAs: 'appScrollMove',
})
export class ScrollMoveDirective {
  constructor(
    private zone: NgZone,
    private el: ElementRef<HTMLElement>,
  ) {}

  public scrollToTop(): void {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.el.nativeElement.scrollTop = 0;
      });
    });
  }

  public scrollToBottom(): void {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.el.nativeElement.scrollTop = this.el.nativeElement.scrollHeight;
      });
    });
  }
}
