import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { BaseObject } from '@shared/base/base-object';
import { UserState } from '@shared/states/user.state';
import { AuthoritiesCondition, Authority } from '@shared/types/authority';

@Directive({
  selector: '[appIfAuthorities]',
})
export class IfAuthoritiesDirective extends BaseObject implements OnInit {
  @Input()
  public set appIfAuthorities(value: Authority[]) {
    this.checkAuthorities$.next(value);
  }

  @Input() public appIfAuthoritiesCondition: AuthoritiesCondition = 'all';

  private checkAuthorities$ = new BehaviorSubject<Authority[]>([]);
  private contentIsShow: boolean = false;

  constructor(
    private template: TemplateRef<unknown>,
    private viewContainerRef: ViewContainerRef,
    private userState: UserState,
  ) {
    super();
  }

  public ngOnInit(): void {
    combineLatest([this.userState.authorities$, this.checkAuthorities$])
      .pipe(
        map(([_, checkAuthorities]) =>
          this.userState.hasAuthorities(checkAuthorities, this.appIfAuthoritiesCondition),
        ),
        startWith(false),
        pairwise(),
        takeUntil(this.destroy$),
      )
      .subscribe(([hasAuthoritiesOld, hasAuthorities]) => {
        if (hasAuthoritiesOld !== hasAuthorities || this.contentIsShow !== hasAuthorities) {
          this.contentIsShow = hasAuthorities;

          if (hasAuthorities) {
            this.viewContainerRef.createEmbeddedView(this.template);
          } else {
            this.viewContainerRef.clear();
          }
        }
      });
  }
}
